import { Icon } from "@iconify/react"
import { Input, message } from "antd"
import React, { useState } from "react"
import { MButton } from "../../Common/buttons"
import { makePostRequest, makePutRequest } from "../../../utils"

export const SocialStep = ({ onChangeStep }) => {
    const [loading, setLoading] = useState<boolean>(false)


    const [data, setData] = useState({
        twitter_username: undefined,
        ig_username: undefined,
        tiktok_username: undefined,
    });

    const onInputData = (type, text) => {
        switch (type) {
            case "twitter_username":
                data.twitter_username = text;
                break;
            case "ig_username":
                data.ig_username = text;
                break;
            case "tiktok_username":
                data.tiktok_username = text;
                break;
            default:
                break;
        }
    };

    const validated = () => {
        if (!data.twitter_username) {
            message.error('Enter Twitter username')
            return false
        } else if (!data.ig_username) {
            message.error('Enter Instagram username')
            return false
        } else if (!data.tiktok_username) {
            message.error('Enter TikTok username')
            return false
        }

        return true;
    }

    const onUpdateChannels = async () => {
        if (validated()) {
            setLoading(true);
            try {
                const response = await makePostRequest(`/api/ai/setChannelsOnboarding`, {
                    ...data,
                });
                if (response.status === "success") {
                    setLoading(false);
                    onChangeStep(2)
                    message.success('Social accounts name added')
                } else {
                    message.error(response.reason);
                    setLoading(false);
                }
            } catch (error) {
                message.error(error.message);
                setLoading(false);
            }
        }
    };

    return <div className="flex flex-col pt-6 px-2">

        <div className=" flex flex-col items-start my-3">
            <div className="flex gap-x-2 items-center -mb-0">
                <Icon icon={'logos:twitter'} className="text-md" />
                <span>Twitter username</span>
            </div>
            <Input
                name={"twitter"}
                type="text"
                onChange={(val) => onInputData("twitter_username", val.target.value)}
                placeholder="Enter twitter username"
                className={``}
            />
        </div>

        <div className=" flex flex-col items-start my-3">
            <div className="flex gap-x-2 items-center mb-1">
                <Icon icon={'skill-icons:instagram'} className="text-md" />
                <span>Instagram username</span>
            </div>
            <Input
                name={"twitter"}
                type="text"
                onChange={(val) => onInputData("ig_username", val.target.value)}
                placeholder="Enter Instagram username"
                className={``}
            />
        </div>

        <div className=" flex flex-col items-start my-3">
            <div className="flex gap-x-2 items-center mb-1">
                <Icon icon={'logos:tiktok-icon'} className="text-md" />
                <span>TikTok username</span>
            </div>
            <Input
                name={"twitter"}
                type="text"
                onChange={(val) => onInputData("tiktok_username", val.target.value)}
                placeholder="Enter TikTok username"
                className={``}
            />
        </div>

        <div className="flex ite justify-center w-full mt-4">
            <MButton
                onClick={() => onUpdateChannels()}
                loading={loading}
                label={'Connect social accounts'}
                icon={undefined}
                iconColor={undefined}
                className={undefined}
                disabled={undefined}
                ref={undefined}
            />
        </div>
    </div>
}