import React, { useState } from "react"
import useAuth from "../Auth/UseAuthHook.tsx";
import { Avatar, Button, Drawer, Dropdown, Space } from "antd";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';


export const MobileSubNav = (props: { items: Array<any>, title: string, currentPath: string, actionBtn, openDrawer, closeDrawer }) => {
    const authContext = useAuth();
    const constantItems = [
        {
            label: (
                <span onClick={() => authContext?.signOut(() => { })} className="cursor-pointer" >
                    Logout
                </span >
            ),
            key: '0',
            location: 'login',
            icon: 'ion:exit'
        },
    ];


    return <Drawer
        placement={'top'}
        onClose={() => props.closeDrawer()}
        closeIcon={null}
        height={400}
        open={props.openDrawer}
    >
        {props.items?.filter((it1) => it1.bottom === false).map((it) => <MobileSubNavItem icon={it.icon} onClick={() => props.actionBtn(it.to)} location={it.to} title={it.title} />)}
        <div className="mt-8 border-b border-b-gray-100"></div>
        {constantItems?.map((it) => <MobileSubNavItem icon={it.icon} onClick={() => props.actionBtn(it.location)} location={it.location} title={it.label} />)}
    </Drawer>
}

const MobileSubNavItem = (props: { icon: string, onClick: () => void, location: string, title: string }) => {
    const location = useLocation()
    const isCurrent = location.pathname.includes(props.location)

    return <div onClick={props.onClick} className={`h-12 cursor-pointer  hover:bg-gray-100 hover:text-primary flex flex-col justify-center items-start ${isCurrent ? ' bg-blue-100' : 'bg-gray-10'}  transition-all duration-200 px-2 py-2 my-2 rounded`}>
        <div className="flex items-center gap-x-2">
            <Icon className="inline mt-1" icon={props.icon} />
            <span className="hover:text-primary font-bold text-sm  block mt-[4px] text-start">{props.title}</span>
        </div>
    </div>
}