import { Icon } from "@iconify/react"
import { Spin, Tooltip, message } from "antd"
import React from "react"
import { ngnMoneyFormatter, formatNum, kFormatter } from "../../../utils"

export const AnalyticsCard = ({ icon, extraContent = undefined, description, extraValue = undefined, loading, title, mobileTitle, value = 0, isMoney = false, width = 'lg:w-3/12 w-6/12' }) => {
    return <div className={` ${width} flex-shrink-0 `}>
        <Spin size="small" style={{ width: '100%' }} spinning={loading}>
            <div className="lg:mx-5 mx-2 bg-gray-100  h-36 rounded-lg py-4 flex flex-col gap-y-3 lg:px-4 px-2" >
                <div className="h-8">
                    <div className="flex gap-x-0 items-start">
                        <Icon icon={icon} className="text-md" />
                        <div className="flex flex-col items-start justify-start pl-1">
                            <span className="text-xs hidden lg:inline-block" >{title}</span>
                            <span className="text-xs inline-block lg:hidden" >{mobileTitle}</span>
                            <span className="text-[11px] pl-0 text-gray-500 inline-block" >{description}</span>
                        </div>

                    </div>
                </div>

                <div className="justify-between lg:justify-between flex items-center ml-4">
                    <span className="lg:text-lg text-md block lg:mt-0 mt-2 font-bold">{isMoney ? ngnMoneyFormatter.format(value) : formatNum(value)} {extraValue ? extraValue : ''}</span>

                    {extraContent}
                </div>
            </div>
        </Spin>
    </div>
}

export const CampaignStats = ({ totalConversion, totalEngagement, totalClicks, loading, width = 'lg:w-3/12 w-6/12' }) => {
    return <div className={` ${width} flex-shrink-0 `}>
        <Tooltip title={'Current campaign statistics'}>
            <Spin size="small" style={{ width: '100%' }} spinning={loading}>
                <div className="lg:mx-5 mx-2 bg-gray-100  h-36 rounded-lg py-4 flex flex-col gap-y-3 lg:p-4 px-2" >
                    <div className="h-2 lg:block">
                        <div className="flex gap-x-2 items-center">
                            <Icon icon={'majesticons:analytics-line'} className="text-md" />
                            <span className="text-xs hidden lg:inline-block" >{'Campaigns Stats'}</span>
                            <span className="text-xs inline-block lg:hidden" >{'Campaigns Stats'}</span>
                        </div>
                    </div>

                    <div className="justify-between lg:justify-between flex flex-wrap-reverse items-center flex-wrap gap-y-2 mt-2">
                        <SocialStatItem title="Clicks" icon={'tabler:click'} count={totalClicks} />
                        <SocialStatItem title="Conversions" icon={'mdi:cart'} count={totalConversion} />
                        <SocialStatItem title="Engagements" icon={'icon-park-solid:like'} count={totalEngagement} />
                    </div>
                </div>
            </Spin>
        </Tooltip>
    </div>
}


export const SocialsCard = ({ accounts, icon, isVerified, extraValue = undefined, loading, title, mobileTitle, value = 0, isMoney = false, width = 'lg:w-3/12 w-6/12' }) => {
    return <div className={` ${width} flex-shrink-0 `}>
        <Tooltip title={isVerified ? 'Account verified' : 'Verify your account to fetch stats'}>
            <Spin size="small" style={{ width: '100%' }} spinning={loading}>
                <div className="lg:mx-5 mx-2 bg-gray-100  h-36 rounded-lg py-4 flex flex-col gap-y-3 lg:p-4 px-2" >
                    <div className="h-8 hidden lg:block">
                        <div className="flex gap-x-2 items-center">
                            <Icon icon={icon} className="text-md" />
                            <span className="text-xs hidden lg:inline-block" >{title}</span>
                        </div>
                        <span className="text-xs inline-block lg:hidden" >{mobileTitle}</span>
                    </div>

                    <div className="justify-between lg:justify-between flex items-center flex-wrap gap-y-4">
                        <WarningItem isVerified={isVerified} />
                        {accounts?.map((acc) => <SocialStatItem title="followers" icon={acc.logo} count={kFormatter(acc.followers ?? 0)} />)}
                        {/*                         <SocialStatItem title="followers" icon={'logos:twitter'} count={0} />
                        <SocialStatItem title="followers" icon={'skill-icons:instagram'} count={3} />
                        <SocialStatItem title="followers" icon={'logos:tiktok-icon'} count={3} /> */}
                    </div>
                </div>
            </Spin>
        </Tooltip>
    </div>
}


const ProductItem = ({ onClick, title, icon, count = 0 }) => {
    return <div onClick={onClick} className="flex  items-start gap-x-2 w-full">
        <div className="flex items-start justify-start gap-x-2">
            <Icon icon={icon} />
            <span className="text-xs text-gray-500">{title}</span>
        </div>
    </div>
}

const SocialStatItem = ({ title, icon, count = 0 }) => {
    return <div className="flex  flex-col items-start gap-x-2 w-6/12">
        <div className="flex items-start justify-start gap-x-2">
            <Icon icon={icon} />
            <span className="text-xs">{count}</span>
        </div>
        <div>
            <span className="text-xs text-gray-500">{title}</span>
        </div>
    </div>
}


const WarningItem = ({ isVerified }) => {
    return <div className="flex  flex-col items-start gap-x-2 w-6/12">
        <div className="flex items-start justify-start gap-x-2">
            <Icon icon={isVerified ? 'material-symbols-light:verified' : 'fluent:warning-24-filled'} className={isVerified ? "text-green-500 text-lg" : "text-red-500 text-lg"} />
        </div>
        <div>
            <span className="text-xs">{isVerified ? 'verified' : 'unverified'}</span>
        </div>
    </div>
}


export const ProductsCard = ({ icon, isVerified, extraValue = undefined, loading, title, mobileTitle, value = 0, isMoney = false, width = 'lg:w-3/12 w-6/12' }) => {
    return <div className={` ${width} flex-shrink-0 `}>
        <Tooltip title={'Enhance your creator journey with our products'}>
            <Spin size="small" style={{ width: '100%' }} spinning={loading}>
                <div className="lg:mx-5 mx-2 bg-gray-100  h-36 rounded-lg py-4 flex flex-col gap-y-3 lg:p-4 px-2" >
                    <div className="h-8  lg:block">
                        <div className="flex gap-x-2 items-center">
                            <Icon icon={'dashicons:products'} className="text-md" />
                            <span className="text-xs hidden lg:inline-block" >{'Quick Products'}</span>
                            <span className="text-xs inline-block lg:hidden" >{'Products'}</span>
                        </div>

                    </div>

                    <div className="justify-between lg:justify-between flex flex-col items-start flex-wrap gap-y-4">
                        {/*                         <ProductItem onClick={() => message.warning('Coming soon..')} title="SMM services" icon={'ic:baseline-design-services'} />
 */}                        <ProductItem onClick={() => message.success('Contact support@creatorbase.co to get started')} title="Buy IPhone installment" icon={'icon-park-outline:iphone'} />
                    </div>
                </div>
            </Spin>
        </Tooltip>
    </div>
}
