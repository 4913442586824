import React, { useEffect, useState } from "react"

import { Icon } from "@iconify/react";
import dayjs from "dayjs";
import { IPostBatch } from "../../interface/ipostbatch.tsx";
import useAuth from "../Auth/UseAuthHook.tsx";
import { formatNum, makeClientSideApiRequest, ngnMoneyFormatter } from "../../utils";
import MainLayout from "../Common/Layout.tsx";
import { Line } from "react-chartjs-2";
import { LineChartOutlined } from '@ant-design/icons';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import useBrandDashboardContext from "../../contexts/BrandContext/BrandHook.tsx";
import { Spin, message } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { AnalyticsCard, CampaignStats, ProductsCard, SocialsCard } from "../Common/Campaign/AnalyticsCard.tsx";
import { CampaignAnalytics } from "../Common/CampaignChart/CampaignAnalytics.tsx";
import { PostAnalyticsDrawer } from "../PostAnalyticsDrawer/PostAnalyticsDrawer.tsx";
import { PostViewDrawer } from "../PostAnalyticsDrawer/PostViewDrawer.tsx";
ChartJS.register(CategoryScale, LinearScale, Filler, PointElement, LineElement, Tooltip, Title, Legend);

const CreatorOverview = () => {
    const [selectedCampaignAnalytics, setSelectedCampaignAnalytics] = useState()
    const [selectedChartValue, setSelectedChartValue] = useState()
    const [chartValues, setChartValues] = useState([1050, 4022, 1101, 3034, 6000, 5000, 9000])
    const [chartLabels, setChartLabels] = useState(['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'])

    const brandDashboardContext = useBrandDashboardContext()
    const authContext = useAuth()
    const [searchParams] = useSearchParams();

    const [openDrawer, setOpenPostActiveDrawer] = useState(false)
    const [openPostViewDrawer, setOpenPostViewDrawer] = useState(false)


    const { loadingDashboard, creatorDashboardInfo, } = brandDashboardContext;
    const { recent_campaign_analytics, total_connections, all_time_reach, total_campaigns, average_campaign_engagement_rate } = {} ?? {}
    const [selectedPost, setSelectedPost] = useState<IPostBatch | undefined>()


    const socialAccounts = creatorDashboardInfo?.channels.map((ch) => {
        return {
            logo: ch.platform.platform_logo,
            followers: ch.followers,
        }
    })

    const { active_campaigns, matched_campaigns } = creatorDashboardInfo ?? {};

    useEffect(() => {
        if (recent_campaign_analytics && recent_campaign_analytics.length > 0) {
            const mostRecent = recent_campaign_analytics[0]

            const values = Object.keys(mostRecent.chartValues)
            const selectedValue = values[0];
            const selectedData = mostRecent.chartValues[selectedValue]

            setSelectedCampaignAnalytics(mostRecent)
            setSelectedChartValue({ title: values[0], data: selectedData })
        }
    }, [recent_campaign_analytics])

    useEffect(() => {
        if (selectedCampaignAnalytics) {
            const values = Object.keys(selectedCampaignAnalytics.chartValues)
            const selectedValue = values[0];
            const selectedData = selectedCampaignAnalytics.chartValues[selectedValue]


            setSelectedChartValue({ title: selectedValue, data: selectedData })
        }
    }, [selectedCampaignAnalytics])


    useEffect(() => {
        if (selectedChartValue && selectedChartValue.data) {
            console.log(Object.keys(selectedChartValue.data));

            setChartLabels(Object.keys(selectedChartValue.data))
            setChartValues(Object.values(selectedChartValue.data))
        } else {
            setChartLabels(['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'])
            setChartValues([1050, 4022, 1101, 3034, 6000, 5000, 9000])
        }
    }, [selectedChartValue])


    useEffect(() => {
        const campaignInviteId = searchParams.get('invited')
        if (campaignInviteId) {
            loadInvitedCampaign(campaignInviteId)
        }

    }, [searchParams])

    const loadInvitedCampaign = async (campaignInviteId) => {
        try {
            const response = await makeClientSideApiRequest(`/api/ai/campaign/${campaignInviteId}`);
            if (response && response.status === "success" && response.data != null) {
                const post = response.data;
                setSelectedPost(post)
                setOpenPostViewDrawer(true)
            }
        } catch (error) {
            message.error(error.message);
        }
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: true
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                display: false
            },
            title: {
                display: false,
                text: 'no title',
            },
        },
    };
    //selectedCampaign?.data ?? dummyChartData
    const getChartData = () => {
        return {
            labels: chartLabels,
            datasets: [
                {
                    fill: true,
                    label: 'Campaign Impression',
                    data: chartValues,
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                        gradient.addColorStop(0, "#2C4BFF");
                        gradient.addColorStop(1, "rgba(13,65,225,0.6)");
                        return gradient;
                    },
                    borderColor: "transparent",
                    lineTension: 0.2,
                },
            ],
        }
    };



    const onUpdateChartDisplay = (type, data) => {
        if (selectedCampaignAnalytics) {
            console.log(type, data);
            const key = data.key
            if (type === 'chartValue') {
                setSelectedChartValue({ title: key, data: selectedCampaignAnalytics.chartValues[key] })
            } else if (type === 'campaign') {
                const selected = recent_campaign_analytics.find((cm) => cm.campaignName === key);
                setSelectedCampaignAnalytics(selected)
            }
        }

    }

    console.log(selectedCampaignAnalytics);


    const handleCloseModal = () => {
        setSelectedPost(undefined)
        setOpenPostActiveDrawer(false)
        setOpenPostViewDrawer(false)
    };

    const handleActiveOpenModal = (post) => {
        setOpenPostActiveDrawer(true)
        setSelectedPost(post)
    };

    const handleViewOpenModal = (post) => {
        setOpenPostViewDrawer(true)
        setSelectedPost(post)
    };

    const onCloseCampaignDrawer = () => {
        handleCloseModal()
        setOpenPostActiveDrawer(false)
    }


    return <>
        {/*     <ManagePostModal
            onCloseModal={handleCloseModal}
            openModal={selectedPost}
            existingPost={selectedPost}
        /> */}

        <PostAnalyticsDrawer
            onCloseDrawer={onCloseCampaignDrawer}
            post={selectedPost}
            openDrawer={selectedPost && openDrawer}
        />

        <PostViewDrawer
            onCloseDrawer={onCloseCampaignDrawer}
            post={selectedPost}
            openDrawer={selectedPost && openPostViewDrawer}
        />

        <MainLayout title="Overview" >
            <div className="h-full overflow-y-scroll">
                <div className="my-10  w-full overflow-x-scroll flex flex-nowrap">
                    <SocialsCard icon={'material-symbols:campaign'}
                        loading={loadingDashboard}
                        accounts={socialAccounts}
                        mobileTitle={'Campaigns'}
                        title={'Social Stats'}
                        isVerified={authContext.user?.is_account_verified}
                    />

                    <AnalyticsCard
                        extraContent={<LineChartOutlined className="text-2xl hidden lg:inline-block text-orange-300" />}
                        icon={'tdesign:money'}
                        description={'active campaigns'}
                        value={creatorDashboardInfo?.total_active_earning}
                        isMoney
                        loading={loadingDashboard}
                        mobileTitle={'Total Earning'}
                        title={'Current Campaigns Earning'}
                    />

                    <CampaignStats
                        loading={loadingDashboard}
                        totalClicks={creatorDashboardInfo?.total_campaign_clicks}
                        totalConversion={creatorDashboardInfo?.total_campaign_conversion}
                        totalEngagement={creatorDashboardInfo?.total_campaign_engagement}
                    />

                    <ProductsCard
                        icon={undefined}
                        isVerified={undefined}
                        loading={loadingDashboard}
                        title={undefined}
                        mobileTitle={undefined}
                    />

                    {/*    <AnalyticsCard icon={'icon-park-solid:circular-connection'}
                        value={total_connections}
                        loading={loadingDashboard}
                        mobileTitle={'Influencer Connections'}
                        title={'Influencer Connections'}
                    /> */}
                </div>

                <div className="flex lg:flex-row flex-col-reverse gap-y-8  lg:h-[31rem] lg:mx-3 lg:px-0 px-3 mt-10 lg:mt-0 w-full">

                    {/*              <div className="lg:w-8/12 w-full bg-gray-50 rounded-lg h-full lg:mx-2 py-1 px-6">
                        <Spin size="small" tip={!selectedCampaign ? 'No data to display' : ''} spinning={loadingDashboard || !selectedCampaign} style={{ width: '100%' }}>
                            <CampaignAnalyticsNav
                                campaignList={recent_campaign_analytics}
                                selectedCampaign={selectedCampaign}
                                selectedChartValue={{
                                    title: selectedChartValue?.title,
                                    chartLabels,
                                    chartValues
                                }}
                                updateChartDisplay={onUpdateChartDisplay}
                            />

                            <div className="my-4">
                                <Line options={options} data={getChartData()} />
                            </div>
                        </Spin>
                    </div> */}

                    <div className="lg:w-6/12  md:5/12 w-full bg-gray-50 rounded-lg h-full  lg:mx-2 py-1 px-6">
                        <Spin size="small" spinning={loadingDashboard}>
                            <div className="mt-4 mb-4">
                                <span>Matched Campaigns</span>
                            </div>
                            <div className="flex flex-col gap-y-6">
                                {matched_campaigns?.slice(0, 3).map((campaign) => <RecentCampaignCard onOpen={handleViewOpenModal} campaign={campaign} />)}
                            </div>
                            <div className="flex justify-center mt-10">
                                <Link to={'/campaigns'}>
                                    <div className="flex items-center justify-center cursor-pointer hover:bg-slate-100 p-2 w-fit">
                                        <span className="text-sm text-orange-400">{'View all campaigns'}</span>
                                        <Icon icon={'iconamoon:arrow-right-2-light'} className="text-sm" />
                                    </div>
                                </Link>
                            </div>
                        </Spin>
                    </div>

                    <div className="lg:w-6/12 md:5/12 w-full bg-gray-50 rounded-lg h-full  lg:mx-2 py-1 px-6">
                        <Spin size="small" spinning={loadingDashboard}>
                            <div className="mt-4 mb-4">
                                <span>Active Campaigns</span>
                            </div>
                            <div className="flex flex-col gap-y-6">
                                {active_campaigns?.slice(0, 3).map((campaign) => <RecentCampaignCard onOpen={handleActiveOpenModal} campaign={campaign} />)}
                            </div>
                            <div className="flex justify-center mt-10">
                                <Link to={'/campaigns'}>
                                    <div className="flex items-center justify-center cursor-pointer hover:bg-slate-100 p-2 w-fit">
                                        <span className="text-sm text-orange-400">{active_campaigns && active_campaigns.length > 0 ? 'View all campaigns' : 'Select a campaign to start'}</span>
                                        <Icon icon={'iconamoon:arrow-right-2-light'} className="text-sm" />
                                    </div>
                                </Link>
                            </div>
                        </Spin>
                    </div>
                </div>
                <div className="w-full mt-10">
                    <CampaignAnalytics
                        selectedCampaign={selectedCampaignAnalytics}
                        chartLabels={chartLabels}
                        chartValues={chartValues}
                        selectedChartValue={selectedChartValue}
                        loadingDashboard={loadingDashboard}
                        recent_campaign_analytics={recent_campaign_analytics}
                        getChartData={getChartData}
                        options={options}
                        onUpdateChartDisplay={onUpdateChartDisplay}
                    />
                </div>
            </div>
        </MainLayout>

    </>
}

export default CreatorOverview

const RecentCampaignCard = (props: { campaign: IPostBatch, onOpen: (item: IPostBatch) => void }) => {
    const { campaign, onOpen } = props
    return <div onClick={() => onOpen(campaign)} className="w-full flex gap-x-1 items-center h-24 bg-white p-2 rounded-lg cursor-pointer ">
        <div className="h-full bg-gray-0 rounded-lg w-2/12 flex items-center justify-center">
            <Icon icon={'material-symbols:campaign-outline'} className="text-2xl" />
        </div>
        <div className=" flex-1 h-full pt-2 flex flex-col gap-y-2 p-2" >
            <div className="flex justify-between items-center">
                <span className="text-xs" >{campaign.name}</span>
                <Icon icon={'iconamoon:arrow-right-2-light'} className="text-sm" />
            </div>
            <div className="flex gap-x-2 flex-wrap gap-y-2">
                <span className="text-xs px-2 rounded-3xl bg-purple-200">{campaign?.status}</span>
            </div>
            <div className="flex justify-between w-full items-center">
                <div className="flex gap-x-2 items-center">
                    <Icon icon={'carbon:calendar'} className="text-lg" />
                    <span className="text-xs">{dayjs(campaign.schedule).format('YYYY MMM DD')}</span>
                </div>
                <div className="flex gap-x-2 items-center">
                    <img src="/avatars.svg" />
                    <span className="text-xs">+1</span>
                </div>

            </div>

        </div>

    </div>
}



