import { Icon } from "@iconify/react"
import { Modal, Input, message } from "antd"
import { MButton } from "../Common/buttons"
import React, { useState } from "react"

export const YoutubeOpenModal = ({ videoLink, setVideoLink }) => {

    return <Modal
        open={videoLink}
        onCancel={() => setVideoLink(undefined)}
        footer={null}
        closeIcon={null}
        className="shadow-none border-none"
        destroyOnClose={true}
    >
        <YoutubeComponent videoLink={videoLink} />
    </Modal>
}


export const YoutubeComponent = ({ videoLink }) => {
    return <div className="w-full rounded bg-gray-50 h-[340px]  border py-3 border-gray-100">
        <div className="h-10 border-b border-gray-100 p-3 ">
            <div className="flex items-center  gap-x-3">
                <Icon icon={'openmoji:youtube'} className="text-gray-700 text-xl" />
                <span className="text-gray-600 font-semibold">How to get started</span>
            </div>
            <div className="w-full mt-8">
                <iframe className="w-full" height={'240'} src={videoLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            </div>

        </div>
    </div>
}