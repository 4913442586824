import React, { useEffect, useState } from "react"
import { MButton } from "../Common/buttons"
import { Button, Drawer, Input, List, Modal, Select, Spin, Tabs, Tag, Tooltip, message } from "antd"
import { Avatar } from "antd";
import { Icon } from "@iconify/react";
import usePostsContext from "../../contexts/PostContext/UsePostHook.tsx";
import { ManagePostModal } from "../ManagePostModal/ManagePostModal.tsx";
import MainLayout from "../Common/Layout.tsx";
import dayjs from "dayjs";
import { IPostBatch } from "../../interface/ipostbatch";
import { isMobile } from "react-device-detect";
import { CampaignItem } from "../Common/CampaignItem.tsx";
import { YoutubeComponent, YoutubeOpenModal } from "../Common/YoutubeModal.tsx";
import { PostAnalyticsDrawer } from "../PostAnalyticsDrawer/PostAnalyticsDrawer.tsx";
import { PostViewDrawer } from "../PostAnalyticsDrawer/PostViewDrawer.tsx";

const youtubeList = [
    {
        thumbnail: '/thumb.png',
        url: 'https://www.youtube.com/embed/zJ6WbK9zFpI?si=luGRPx9sBs75kmFB',
        name: 'How to create campaigns',
        time: '5:00'
    },
    {
        thumbnail: '/thumb.png',
        url: 'https://www.youtube.com/embed/zJ6WbK9zFpI?si=luGRPx9sBs75kmFB',
        name: 'How to view campaigns analytics',
        time: '5:00'
    }
]

const BrandCampaigns = () => {
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [openDrawer, setOpenDrawer] = useState<boolean>(false)


    const [selectedPost, setSelectedPost] = useState<IPostBatch | undefined>()
    const [displayedPosts, setDisplayedPosts] = useState<Array<IPostBatch> | undefined>()
    const [videoLink, setVideoLink] = useState<string | undefined>(undefined)

    const postsContext = usePostsContext()


    const onCloseCampaignDrawer = () => {
        setOpenDrawer(false)
    }

    useEffect(() => {
        if (postsContext.posts && postsContext.posts?.length > 0) {
            console.log(postsContext.posts);

            setDisplayedPosts(postsContext.posts)
        }

    }, [postsContext.loadingPosts, postsContext.posts])


    useEffect(() => {
        if (!openDrawer) {
            setSelectedPost(undefined)
        }
    }, [openDrawer])

    const handleCreatePost = (e: React.MouseEvent<HTMLButtonElement>) => {
        setOpenModal(true)
    };

    const handleCloseModal = (needsRefresh) => {
        setOpenModal(false)
        setSelectedPost(undefined)
        if (needsRefresh) {
            postsContext.getPosts(() => { }, () => { })
        }
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false)
        setSelectedPost(undefined)
    };

    const onSelectPost = (post) => {
        if (post?.queue_status != 'pending') {
            setOpenDrawer(true)
        }

        setSelectedPost(post)
    }


    return <div className="w-full">
        {/*   <PostViewDrawer
            onCloseDrawer={onCloseCampaignDrawer}
            post={selectedPost}
            openDrawer={selectedPost && openDrawer}
        /> */}

        {/*         <PostAnalyticsDrawer
            onCloseDrawer={handleCloseDrawer}
            post={selectedPost}
            openDrawer={selectedPost && openDrawer}
        /> */}

        <ManagePostModal
            onCloseModal={handleCloseModal}
            openModal={openModal || (selectedPost && !openDrawer)}
            existingPost={selectedPost}
        />

        <MainLayout customHeader={
            <div className="w-full lg:w-9/12 lg:py-0 py-4">
                <MobileHead setVideoLink={setVideoLink} />

                <div className="flex w-full justify-between items-center px-3">
                    <span className="lg:text-2xl text-lg font-bold">Campaigns</span>
                    <div>
                        <MButton
                            onClick={handleCreatePost}
                            loading={false}
                            label={'Create campaign'}
                            icon={undefined}
                            iconColor={undefined}
                            className={undefined}
                            disabled={undefined}
                            ref={undefined}
                        />
                    </div>
                </div>
            </div>
        } title="Campaigns" >
            <YoutubeOpenModal videoLink={videoLink} setVideoLink={() => setVideoLink(undefined)} />
            <div className="w-full h-full flex items-start justify-start lg:py-10 py-6 gap-x-14 ">
                <div className="lg:w-9/12 w-full h-full overflow-scroll bg-gray-50 p-2 px-3 rounded-lg">
                    <Spin className="w-full h-full " style={{ width: '100%' }} spinning={postsContext.loadingPosts}>
                        <List
                            grid={{ column: isMobile ? 1 : 2, gutter: 16 }}
                            pagination={{ pageSize: isMobile ? 4 : 8 }}
                            dataSource={displayedPosts ?? []}
                            className="w-full lg:h-[600px] lg:pb-0 pb-14"
                            renderItem={(item: IPostBatch, index) => (
                                <CampaignItem
                                    onSelect={() => onSelectPost(item)}
                                    campaign={item}
                                    index={index}
                                />

                            )}
                        />
                    </Spin>
                </div>
                <div className="fixed right-5 w-[300px] h-full overflow-y-scroll lg:block hidden">
                    {youtubeList.map((vid) => {
                        return <SideVideoListItem vid={vid} setVideoLink={setVideoLink} />
                    })}

                </div>
            </div>
        </MainLayout>
    </div>
}
export default BrandCampaigns



const MobileHead = ({ setVideoLink }) => {
    return <div className="flex overflow-x-auto space-x-2 w-full  gap-x-1 mb-2 items-center px-2 pb-2 lg:hidden">
        {youtubeList.map((vid) => {
            return <SideVideoListItem vid={vid} setVideoLink={setVideoLink} />
        })}
    </div>
}

const SideVideoListItem = ({ setVideoLink, vid }) => {
    return <div className="flex flex-shrink-0 cursor-pointer   overflow-x-auto space-x-2 lg:w-full w-9/12  gap-x-1 mb-5 items-center p-2 pb-2 bg-gray-50">
        <div onClick={() => setVideoLink(vid.url)} className="flex-shrink-0 w-full rounded bg-white h-20  items-center flex flex-row justify-between px-3 gap-x-2 p-3">
            <div className="w-3/12">
                <img src={vid.thumbnail} className="w-14 h-14" />
            </div>

            <div className="flex-1">
                <span className="flex items-center gap-x-3">
                    <Icon icon={'openmoji:youtube'} className="text-gray-700 text-xl" />
                    <span className="text-xs">{vid.time}</span>
                </span>
                <span className="text-sm font-semibold">{vid.name}</span>
            </div>

        </div>
    </div>
}