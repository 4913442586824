import { Icon } from "@iconify/react";
import { Avatar, Tour, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { MobileSubNav } from "../Nav/MobileSubNav.tsx";
import { SideNav } from "../Nav/SideNav.tsx";
import { logEvent } from "firebase/analytics";
import usePostsContext from "../../contexts/PostContext/UsePostHook.tsx";
import { analytics } from "../../utils/firebaseutil.ts";
import useTourContext from "../../contexts/TourContext/TourContextHook.tsx";
import useAuth from "../Auth/UseAuthHook.tsx";
import { CreatorOnboardingDrawer } from "../Onboarding/CreatorOnboardingDrawer/CreatorOnboardingDrawer.tsx";
import { ProfileVerificationProductDrawer } from "../Products/ProfileVerificationProduct/ProfileVerificationProductDrawer.tsx";

export const CreatorApp = ({ navItems }) => {
    const location = useLocation();

    const tourContext = useTourContext();
    const postsContext = usePostsContext()
    const authContext = useAuth()
    // const postsQueueContext = usePostsQueueStatusContext();
    const [isMobileNavOpen, setIsMobileNavOpened] = useState(false);
    const [isOnboardingDrawerOpen, setIsOnboardingDrawerOpen] = useState(false);
    const [isOpenVerificationDrawer, setIsOpenVerificationDrawer] = useState(false);
    const navigate = useNavigate()


    useEffect(() => {
        if (authContext.user) {
            checkOnboarding()
        }

    }, [authContext.user])


    const onClickVerifyAccount = () => {
        setIsOpenVerificationDrawer(true)
    }

    const onClickSubscribeDevice = () => {

    }

    const onClickBanner = (bannerType) => {
        switch (bannerType) {
            case "verify":
                onClickVerifyAccount()
                break;
            case "subscribe":
                message.success("Please contact support@creatorbase.co to get started")
                break;
            default:
                break;
        }
    }

    const getBannerMessage = () => {
        const { is_account_verified } = authContext.user
        switch (is_account_verified) {
            case 1:
                return {
                    message: "Get latest IPhone devices in installment.",
                    type: "optional",
                    name: 'subscribe',
                }
            case undefined:
            case 0:
                return {
                    message: "Verify your account to stay active",
                    type: "required",
                    name: 'verify',
                    onClick: () => onClickSubscribeDevice()
                }
            default:
                break;
        }
    }


    let checkOnboarding = async () => {
        if (authContext.user) {
            const { onboarding_status = undefined, is_account_verified } = authContext.user
            if (onboarding_status === 'connect_social' ||
                onboarding_status === 'set_niche' ||
                onboarding_status === 'set_payment_info'
            ) {
                setIsOnboardingDrawerOpen(true)
            } else {
                setIsOnboardingDrawerOpen(false)
            }
        }
    }

    useEffect(() => {
        setupAppData()
        tourContext.triggerFirsOpenTour()
    }, [authContext.user]);

    const setupAppData = async () => {
        logEvent(analytics!, "page_visited", {
            content_type: "app_page",
            content_id: "app_home",
        });

        /*         personaContext.getPersonas((data) => {
                    // console.log(data);
                }, () => { });
                postsContext.getPosts((data) => { }, () => { }); */

        // this needs to be pooled, more logic coming
        //   postsQueueContext.getQueueStatuses((data) => { }, () => { });
    }

    return (
        <main className="flex flex-1 items-start justify-start h-screen lg:w-full w-full mx-auto relative">
            {/*  <Tour open={tourContext.open}
                onClose={() => tourContext.setOpen(false)}
                steps={tourContext.steps}
            /> */}
            {/*    <TopNav currentPath={location.pathname} />
            <MobileSubNav items={navItems} currentPath={""} title={"Products"} actionBtn={{
                onClick: function (): void {
                    message.info("Function not implemented.");
                },
                label: "New product",
                icon: "mdi:plus-circle"
            }} /> */}
            {/*    <BottomNav currentPath={location.pathname} /> */}

            <SideNav
                navItems={navItems}
            />

            <MobileSubNav
                items={navItems}
                title={""}
                actionBtn={(to) => {
                    navigate(to);
                    setIsMobileNavOpened(false)
                }}
                currentPath={""}
                openDrawer={isMobileNavOpen}
                closeDrawer={() => setIsMobileNavOpened(false)}
            />

            <CreatorOnboardingDrawer
                openDrawer={isOnboardingDrawerOpen}
                closeDrawer={setIsOnboardingDrawerOpen}
            >
            </CreatorOnboardingDrawer >

            <ProfileVerificationProductDrawer
                closeDrawer={setIsOpenVerificationDrawer}
                openDrawer={isOpenVerificationDrawer}
            />

            <section className="flex-1 flex flex-col bg-white h-full w-full ">
                <div className={`w-full relative  flex flex-col items-center justify-center ${postsContext.loadingPosts ? 'pt-0' : 'pt-0'} transition-all duration-200 fixed left-0 right-0 top-0 app-head `}>
                    <LargeScreenHeader authContext={authContext} />
                    <SmallScreenHeader onOpen={() => setIsMobileNavOpened(true)} />
                    <TopBanner
                        type={getBannerMessage()?.type}
                        message={getBannerMessage()?.message}
                        ctaText={'Click here'}
                        onClick={() => onClickBanner(getBannerMessage()?.name)}
                    />

                </div>
                <div className="flex-1 h-full w-full overflow-y-scroll ">
                    <Outlet />
                </div>

            </section>
        </main>
    );
};



const LargeScreenHeader = ({ authContext }) => {
    return <div className="hidden lg:flex h-14 border-b border-b-gray-100 w-full  justify-end items-center px-16 gap-x-10">
        <Icon icon={'basil:notification-outline'} />
        <div className="flex items-center gap-x-2 cursor-pointer hover:opacity-75 transition-all duration-100">
            <Avatar className="bg-primary" size={'small'}> {authContext?.user?.fullname?.substring(0, 2)}</Avatar>
            <div className="flex flex-col items-start text-start">
                <span className="text-gray-600 text-xs">{authContext?.user?.fullname} </span>
                <span className="text-gray-600 text-xs">{authContext?.user?.email}</span>
            </div>
        </div>
        {/* <Icon icon={'iconamoon:arrow-down-2-thin'} /> */}
    </div>
}

const SmallScreenHeader = ({ onOpen }) => {
    return <>
        <div className="flex items-center justify-between lg:hidden w-full pr-3 py-2 border-b border-gray-100 ">
            <img src="/logo.svg" />

            <Icon onClick={onOpen} icon={'pajamas:hamburger'} className="text-2xl text-gray-600" />
        </div>
    </>
}

const TopBanner = ({ type, message, ctaText, onClick }) => {
    return <>
        {message && <div onClick={() => onClick()} className={`h-6 ${type === 'required' ? 'bg-red-500' : 'bg-blue-500'}  w-full flex items-center justify-center gap-x-2`}>
            <span className="lg:text-lg text-sm block text-center text-white">{message} </span>
            <span className="text-white text-sm   underline">{ctaText}</span>
        </div>
        }
    </>
}


