import { Icon } from "@iconify/react"
import { Drawer, Dropdown, Form, Input, Spin, Tag, Tooltip, message } from "antd"
import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { StatusChip } from "../Common/Campaign/StatusChip.tsx"
import { AnalyticsCard } from "../Common/Campaign/AnalyticsCard.tsx"
import { CampaignAnalytics } from "../Common/CampaignChart/CampaignAnalytics.tsx"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend,
    Filler,
} from 'chart.js';
import { Str } from "../../utils/constants.js"
import { MButton } from "../Common/buttons.js"
import { clipText, makePostRequest, ngnMoneyFormatter } from "../../utils/index.js"
import copy from 'copy-to-clipboard';
import { RewardItem } from "./Common.tsx"
import usePostsContext from "../../contexts/PostContext/UsePostHook.tsx"
import useBrandDashboardContext from "../../contexts/BrandContext/BrandHook.tsx"


export const PostViewDrawer = ({ post, openDrawer, onCloseDrawer }) => {

    const [loading, setLoading] = useState(false)

    const postAttachments = post?.post?.attachments ?? [];
    const postContext = usePostsContext()
    const dashContext = useBrandDashboardContext()
    console.log(post?.conversion_reward, post?.engagement_reward, post?.click_reward, openDrawer);

    const onCopyHashTag = () => {
        clipText(post?.hashtag)
        message.success('Hashtag copied')
    }


    async function joinCampaign() {

        try {
            setLoading(true)
            const response = await makePostRequest('/api/ai/joinCampaign', { campaign_id: post?.id })
            if (response.status === "success") {
                message.success('Campaign joined successfully')
            } else {
                message.error(response.reason);
                setLoading(false);
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            message.error(`${error}`)
        }

    }

    const onAcceptInvite = async () => {
        await joinCampaign()
        await postContext.getActivePosts(undefined, undefined)
        await dashContext.getDashboard()
        onCloseDrawer(false)
    }

    return <Drawer
        placement={'right'}
        closable={false}
        onClose={onCloseDrawer}
        open={openDrawer}
        width={isMobile ? window.screen.width : 800}
        height={700}
        className="w-full p-0"
        style={{ padding: '0 !important' }}
    >
        <div className="flex  flex-col  gap-y-4 items-center  mx-auto w-full mb-36">
            <div onClick={() => onCloseDrawer()} className="absolute right-4 top-8 lg:top-4 lg:right-6">
                <Icon icon={'zondicons:close-solid'} className="text-lg" />
            </div>


            <div className="flex justify-center items-center w-full text-center px-2">
                <span className="text-2xl font-medium block text-center w-full">{post?.name}</span>
            </div>

            <div className="flex justify-center lg:gap-x-6 gap-x-4 mt-4 items-center lg:w-10/12 w-full">
                {post?.niches?.map((niche) => <StatusChip text={niche.niche} />)}
            </div>

            <div className="flex justify-start w-full px-3 mt-4">
                <span className="text-gray-700">Campaign instructions</span>
            </div>
            <div className="flex items-start px-3 justify-start w-full">
                <span className="text-gray-600 text-left ">{post?.post?.content}</span>
            </div>

            {postAttachments?.length > 0 &&
                <div className="flex justify-start w-full px-3 mt-4">
                    <span className="text-gray-700">Campaign assets</span>
                </div>
            }
            <div className="w-full gap-x-4 flex items-center justify-start px-3  flex-nowrap overflow-x-scroll">
                {
                    postAttachments.map((att) => {
                        return <div className="w-4/12 bg-gray-100 flex-shrink-0 rounded">
                            <img src={`${Str.spacesBaseUrl}/${att.resource.path}`} className="w-full rounded" />
                        </div>
                    })

                }
            </div>

            <div className="flex flex-col justify-start w-full px-3 mt-4">
                <span className="text-gray-700 text-sm">HashTags <span className="text-red-400"> (*Important*) </span></span>
                <span className="text-gray-400 text-xs">Use this hashtag in your posts to ensure that we can record your generated engagements</span>
                <div className="mt-2 flex items-center gap-x-6">
                    <span className="text-orange-400 font-bold">#{post?.hashtag}</span>
                    <div onClick={onCopyHashTag} className="flex gap-x-1 items-center cursor-pointer">
                        <Icon icon={'iconamoon:copy-bold'} className="text-gray-700" />
                        Copy
                    </div>

                </div>
            </div>

            <div className="w-full">
                <div className="flex justify-start items-start w-full px-3 mt-4">
                    <span className="text-gray-700">Rewards</span>
                </div>

                <div className="flex justify-between items-center w-full px-3 mt-4 gap-x-4">
                    <RewardItem title={'Reward per engagement'} icon={'icon-park-solid:like'} reward={post?.engagement_reward} />
                    <RewardItem title={'Reward per click'} icon={'tabler:click'} reward={post?.click_reward} />
                    <RewardItem title={'Reward per conversion'} icon={'mdi:cart'} reward={post?.conversion_reward} />
                </div>
            </div>

            <div className="my-3 w-full px-3 absolute bottom-1 right-0 left-0">
                <MButton
                    onClick={onAcceptInvite}
                    loading={loading}
                    label={'Join This Campaign'}
                    icon={undefined}
                    iconColor={undefined}
                    className={"w-full"}
                    disabled={undefined}
                    ref={undefined}
                />
            </div>

        </div>
    </Drawer>
}
