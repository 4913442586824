import { Icon } from "@iconify/react"
import { Avatar } from "antd"
import React from "react"

const AuthHalf = () => {
    return <div className="lg:h-full h-full bg-[#4131F7] lg:flex lg:w-6/12 lg:px-10 relative">
        <div className="lg:px-20 px-4 text-center mt-4 flex h-10  gap-x-3 items-center">
            <img className="w-6 h-6" src="/logo.png" />
            <span className="font-bold text-2xl text-white">CreatorBase</span>
        </div>
        <div className="absolute  lg:bottom-0 right-0 left-0 flex flex-col px-4 lg:px-24 w-full">
            <div className="flex gap-x-2 items-center justify-start my-4">
                <Icon className="text-orange-500 text-2xl" icon={'material-symbols:star'} />
                <Icon className="text-orange-500 text-2xl" icon={'material-symbols:star'} />
                <Icon className="text-orange-500 text-2xl" icon={'material-symbols:star'} />
                <Icon className="text-orange-500 text-2xl" icon={'material-symbols:star'} />
                <Icon className="text-orange-500 text-2xl" icon={'material-symbols:star'} />
            </div>

            <div className="text-white text-sm lg:text-lg  block	w-full">
                Where  brands and creators connect, to engage with millions. " Because  Great Ideas  Deserve To Go Viral & Passion and deserve to be rewarded."
            </div>

            <div className="text-white text-lg  block w-72	h-20 mt-10">
                <div className="flex gap-x-2 items-start">
                    <div>
                        <Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel`} />
                    </div>
                    <div className="flex flex-col gap-y-2">
                        <span className="text-sm lg:text-lg text-gray-300">Made with love </span>
                        <span className="text-sm lg:text-lg text-gray-300">by CreatorBase Team</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default AuthHalf