import { Icon } from "@iconify/react"
import { Drawer, Dropdown, Form, Input, Spin, Tag, message } from "antd"
import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { StatusChip } from "../Common/Campaign/StatusChip.tsx"
import { AnalyticsCard, CampaignStats } from "../Common/Campaign/AnalyticsCard.tsx"
import { LineChartOutlined } from '@ant-design/icons';

import { Str } from "../../utils/constants.js"
import { onLoadPageInfo } from "../../services/load_page_data.ts"
import { clipText } from "../../utils/index.js"
import { RewardItem } from "./Common.tsx"


export const PostAnalyticsDrawer = ({ post, openDrawer, onCloseDrawer }) => {
    const [loading, setLoading] = useState(false);
    const [stats, setStats] = useState({});

    useEffect(() => {
        if (openDrawer) {
            loadPageData()
        }
    }, [openDrawer])


    const onCopyHashTag = () => {
        clipText(post?.hashtag)
        message.success('Hashtag copied')
    }


    const loadPageData = () => {
        onLoadPageInfo({
            path: `/api/ai/posts/${post?.id}/creatorCampaignStats`,
            onStartLoad: function (): void {
                setLoading(true);
            },
            onSuccess: function (data: any): void {
                setLoading(false);
                setStats(data)
            },
            onFail: function (msg: string): void {
                setLoading(false);
                message.error(msg);
            }
        })
    }

    return <Drawer
        placement={ 'right'}
        closable={false}
        onClose={onCloseDrawer}
        open={openDrawer}
        width={isMobile ? window.screen.width : 800}
        height={700}
        className="w-full p-0"
        style={{ padding: '0 !important' }}
    >
        <div className="flex  flex-col  gap-y-4 items-center w-full  mx-auto mb-36">
            <div onClick={() => onCloseDrawer()} className="absolute right-4 top-8 lg:top-4 lg:right-6">
                <Icon icon={'zondicons:close-solid'} className="text-lg" />
            </div>

            <div className="flex justify-center items-center w-full text-center px-2">
                <span className="text-2xl font-medium block text-center w-full">{post?.name}</span>
            </div>

            <div className="flex justify-center lg:gap-x-6 gap-x-4 mt-2 items-center lg:w-10/12 w-full">
                {post?.niches?.map((niche) => <StatusChip text={niche.niche} />)}
            </div>

            <div className="flex justify-start w-full px-3 mt-4">
                <span className="text-gray-700">Campaign instructions</span>
            </div>
            <div className="flex items-start px-3 justify-start w-full">
                <span className="text-gray-600 text-left ">{post?.post?.content}</span>
            </div>

            <div className="flex justify-start w-full px-3 mt-4">
                <span className="text-gray-700">Campaign assets</span>
            </div>
            <div className="w-full gap-x-4 flex items-center justify-start px-3  flex-nowrap overflow-x-scroll">
                {
                    post?.post?.attachments.map((att) => {
                        return <div className="lg:w-40 w-4/12 bg-gray-100 flex-shrink-0 rounded" >
                            <img src={`${Str.spacesBaseUrl}/${att.resource.path}`} className="w-full rounded" />
                        </div>
                    })

                }
            </div>

            <div className="flex flex-col justify-start w-full px-3 mt-8">
                <span className="text-gray-700 text-sm">HashTags <span className="text-red-400"> (*Important*) </span></span>
                <span className="text-gray-400 text-xs">Use this hashtag in your posts to ensure that we can record your generated engagements</span>
                <div className="mt-2 flex items-center gap-x-6">
                    <span className="text-orange-400 font-bold">#{post?.hashtag}</span>
                    <div onClick={onCopyHashTag} className="flex gap-x-1 items-center cursor-pointer">
                        <Icon icon={'iconamoon:copy-bold'} className="text-gray-700" />
                        Copy
                    </div>

                </div>
            </div>

            <div className="flex justify-start items-start w-full px-3 mt-1">
                <span className="text-gray-700">Rewards</span>
            </div>
            <div className="flex justify-between items-center w-full px-3 mt-0 gap-x-4">
                <RewardItem title={'Reward per engagement'} icon={'icon-park-solid:like'} reward={post?.engagement_reward} />
                <RewardItem title={'Reward per click'} icon={'tabler:click'} reward={post?.click_reward} />
                <RewardItem title={'Reward per conversion'} icon={'mdi:cart'} reward={post?.conversion_reward} />
            </div>

            <div className="w-full flex items-center justify-start mt-4 flex-nowrap overflow-x-scroll">

                <AnalyticsCard
                    icon={'tdesign:money'}
                    value={stats?.total_earning}
                    isMoney
                    loading={loading}
                    mobileTitle={'Campaign Earning'}
                    title={'Current campaign earning'}
                    width="lg:w-4/12 w-5/12"
                />

                <AnalyticsCard
                    icon={'icon-park-solid:like'}
                    value={stats?.engagements}
                    loading={loading}
                    mobileTitle={'Engagements'}
                    title={'Engagements for this campaign'}
                    width="lg:w-4/12 w-5/12"
                />

                <AnalyticsCard
                    icon={'mdi:cart'}
                    value={stats?.conversions}
                    loading={loading}
                    mobileTitle={'Conversions'}
                    title={'Conversions for this campaigns'}
                    width="lg:w-4/12 w-5/12 "
                />

                {/*                 <CampaignStats
                    loading={loading}
                    totalClicks={stats?.clicks}
                    totalConversion={stats?.conversions}
                    totalEngagement={stats?.engagements}
                /> */}
            </div>
        </div>
    </Drawer>
}

