import { makeClientSideApiRequest } from "../utils";

export const onLoadPageInfo = async (props: { path: string, onStartLoad: () => void, onSuccess: (data: any) => void, onFail: (message: string) => void }) => {
    props.onStartLoad();
    try {
        const response = await makeClientSideApiRequest(props.path);

        if (response.status === "success") {
            props.onSuccess(response.data)
        } else {
            props.onFail(response.reason)
        }
    } catch (error) {
        props.onFail(error.message)
    }
};