import { Icon } from "@iconify/react";
import { Dropdown } from "antd";
import React from "react";

export const CampaignAnalyticsNav = ({ updateChartDisplay, campaignList, selectedCampaign, selectedChartValue }) => {
    const { totalInfluencers = 0, chartValues = {} } = selectedCampaign ?? {}

    const campaignNames = (campaignList ?? []).map((camp) => { return { key: camp.campaignName, label: camp.campaignName } })
    const chartValueList = Object.keys(chartValues).map((chrt, index) => { return { key: chrt, label: chrt } });

    return <div className=" lg:flex justify-between h-14 items-center hidden">
        <Dropdown menu={{ items: campaignNames, onClick: (key) => updateChartDisplay('campaign', key) }}>
            <div className="flex gap-x-2 items-center  rounded-2xl cursor-pointer w-48">
                <span className="font-semibold">{selectedCampaign?.campaignName}</span>
                <Icon icon={'iconamoon:arrow-down-2-bold'} className="text-gray-900" />
            </div>
        </Dropdown>
        <Dropdown menu={{ items: chartValueList, onClick: (key) => updateChartDisplay('chartValue', key) }}>
            <div className="flex gap-x-2 items-center bg-gray-200 rounded-2xl px-4 p-1 cursor-pointer">
                <Icon icon={'uis:analytics'} className="text-gray-400" />
                <span className="text-xs">{selectedChartValue?.title}</span>
                <Icon icon={'iconamoon:arrow-down-2-bold'} className="text-gray-700" />
            </div>
        </Dropdown>
        <div className="flex gap-x-2 items-center">
            <img src="/avatars.svg" />
            <span className="text-xs">+{totalInfluencers} influencers</span>
        </div>
        <Dropdown menu={{ items: [] }}>
            <div className="flex gap-x-2 items-center bg-gray-200 rounded-2xl px-4 p-1 cursor-pointer">
                <Icon icon={'bx:calendar'} className="text-gray-400" />
                <span className="text-xs">7 Days Period</span>
                <Icon icon={'iconamoon:arrow-down-2-bold'} className="text-gray-700" />
            </div>
        </Dropdown>

    </div>
}