import { Icon } from "@iconify/react"
import { Input, message } from "antd"
import React, { useState } from "react"
import { MButton } from "../../Common/buttons"
import { makePostRequest } from "../../../utils"

export const SetPaymentInfo = ({ onChangeStep }) => {
    const [loading, setLoading] = useState<boolean>(false)


    const [data, setData] = useState({
        bank_name: undefined,
        account_name: undefined,
        account_number: undefined,
    });

    const onInputData = (type, text) => {
        switch (type) {
            case "bank_name":
                data.bank_name = text;
                break;
            case "account_name":
                data.account_name = text;
                break;
            case "account_number":
                data.account_number = text;
                break;
            default:
                break;
        }
    };

    const validated = () => {
        if (!data.bank_name) {
            message.error('Enter Bank name')
            return false
        } else if (!data.account_number) {
            message.error('Enter Account number')
            return false
        } else if (!data.account_name) {
            message.error('Enter Account name')
            return false
        }

        return true;
    }

    const onUpdateChannels = async () => {
        if (validated()) {
            setLoading(true);
            try {
                const response = await makePostRequest(`/api/ai/setPaymentDetails`, {
                    ...data,
                });
                if (response.status === "success") {
                    setLoading(false);
                    onChangeStep(4)
                    message.success('Payment details saved')
                } else {
                    message.error(response.reason);
                    setLoading(false);
                }
            } catch (error) {
                message.error(error.message);
                setLoading(false);
            }
        }
    };

    return <div className="flex flex-col pt-6 px-2">

        <div className=" flex flex-col items-start my-3">
            <div className="flex gap-x-2 items-center -mb-0">
                <span>Bank name </span>
            </div>
            <Input
                name={"bank_name"}
                type="text"
                onChange={(val) => onInputData("bank_name", val.target.value)}
                placeholder="Bank name"
                className={``}
            />
        </div>

        <div className=" flex flex-col items-start my-3">
            <div className="flex gap-x-2 items-center mb-1">
                <span>Account number</span>
            </div>
            <Input
                name={"account_number"}
                type="text"
                onChange={(val) => onInputData("account_number", val.target.value)}
                placeholder="Enter account number"
                className={``}
            />
        </div>

        <div className=" flex flex-col items-start my-3">
            <div className="flex gap-x-2 items-center mb-1">
                <span>Account name</span>
            </div>
            <Input
                name={"account_name"}
                type="text"
                onChange={(val) => onInputData("account_name", val.target.value)}
                placeholder="Enter Account name"
                className={``}
            />
        </div>

        <div className="flex ite justify-center w-full mt-4">
            <MButton
                onClick={() => onUpdateChannels()}
                loading={loading}
                label={'Connect bank account'}
                icon={undefined}
                iconColor={undefined}
                className={undefined}
                disabled={undefined}
                ref={undefined}
            />
        </div>
    </div>
}