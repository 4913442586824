import { Icon } from "@iconify/react"
import { Dropdown, Form, Input, Spin, Tag, message } from "antd"
import React, { useEffect, useState } from "react"
import { PostImage } from "./PostImage.tsx"
import { clipText } from "../../utils/index.js"

export const PostContent = ({ post, campaignName, hashTag, updatePost }) => {
    const { content } = post || {}
    const [form] = Form.useForm();
    useEffect(() => {
        console.log(content, campaignName);

        form.setFieldValue('content', content)
        form.setFieldValue('campaign_name', campaignName)

    }, [content, form, post, campaignName])


    const onChangeContent = async (e) => {
        updatePost('content', e.target.value)
    }


    const onCampaignName = async (e) => {
        updatePost('campaign_name', e.target.value)
    }


    const onCopyHashTag = () => {
        clipText(hashTag)
        message.success('Hashtag copied')
    }


    return <Form form={form} className="flex-col flex relative  h-[80vh] lg:h-[600px] items-center justify-center px-4 py-2">
        <div className="lg:mt-0 lg:mb-0 w-full  h-full relative ">


            <div className="pb-5 flex lg:flex-row flex-col justify-between">
                <span className="font-semibold text-lg">Campaign content</span>
                <div className="flex gap-x-2 items-center cursor-pointer  rounded-lg">
                    <Icon icon={'bxl:twitter'} />
                    <span>Twitter Post</span>
                    <Icon className="text-2xl " icon={'iconamoon:arrow-down-2-thin'} />
                </div>
            </div>

            <Form.Item className=" mt-4" name={'campaign_name'}>
                <div className="flex flex-col gap-y-2">
                    <span className="text-sm"> Campaign name </span>
                    <Input
                        defaultValue={campaignName}
                        autoFocus={true}
                        onChange={onCampaignName}
                        placeholder={'Enter campaign name...'}
                        className={` placeholder:font-bold shadow-sm h-12`}
                    />
                </div>
            </Form.Item>

            <Form.Item className=" " name={'content'}>
                <div className="flex flex-col gap-y-2">
                    <span className="text-sm"> Campaign content </span>
                    <Input.TextArea
                        value={content}
                        rows={4}
                        autoFocus={true}
                        onChange={onChangeContent}
                        placeholder={'Enter post content...'}
                        className={`placeholder:font-bold `}
                    />
                </div>
            </Form.Item>

            {hashTag &&
                <div className="flex flex-col my-7 gap-y-3">
                    <span className="text-sm"> Campaign hashtag </span>
                    <div className="flex items-center justify-between">
                        <span className="text-orange-700 font-bold">#{hashTag}</span>
                        <div onClick={onCopyHashTag} className="flex gap-x-1 items-center cursor-pointer">
                            <Icon icon={'iconamoon:copy-bold'} className="text-gray-700" />
                            Copy
                        </div>
                    </div>
                </div>
            }

            <div className="lg:-mt-4  -mt-14">
                <PostImage post={post} updatePost={updatePost} />
            </div>

        </div>
    </Form>
}