import React, { useEffect, useState } from "react"

import { Icon } from "@iconify/react";
import dayjs from "dayjs";
import { IPostBatch } from "../../interface/ipostbatch.tsx";
import useAuth from "../Auth/UseAuthHook.tsx";
import { formatNum, ngnMoneyFormatter } from "../../utils";
import MainLayout from "../Common/Layout.tsx";
import { Line } from "react-chartjs-2";
import { LineChartOutlined } from '@ant-design/icons';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import useBrandDashboardContext from "../../contexts/BrandContext/BrandHook.tsx";
import { Dropdown, Spin } from "antd";
import { Link } from "react-router-dom";
import { ManagePostModal } from "../ManagePostModal/ManagePostModal.tsx";
import { AnalyticsCard } from "../Common/Campaign/AnalyticsCard.tsx";
import { CampaignAnalyticsNav } from "../Common/CampaignChart/CampaignAnalyticsNav.tsx";
import { CampaignAnalytics } from "../Common/CampaignChart/CampaignAnalytics.tsx";
ChartJS.register(CategoryScale, LinearScale, Filler, PointElement, LineElement, Tooltip, Title, Legend);

const postViews = [
    { key: 'list', label: 'List view', icon: <Icon icon={'ic:round-list'} /> },
    { key: 'calendar', label: 'Calendar view', icon: <Icon icon={'solar:calendar-bold'} /> },
]

const BrandOverview = () => {
    const [selectedCampaign, setSelectedCampaign] = useState()
    const [selectedChartValue, setSelectedChartValue] = useState()
    const [chartValues, setChartValues] = useState([1050, 4022, 1101, 3034, 6000, 5000, 9000])
    const [chartLabels, setChartLabels] = useState(['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'])

    const brandDashboardContext = useBrandDashboardContext()
    const authContext = useAuth()

    const { loadingDashboard, dashboardInfo, } = brandDashboardContext;
    const { recent_campaign_analytics, recent_campaigns, total_connections, all_time_reach, total_campaigns, average_campaign_engagement_rate } = dashboardInfo ?? {}
    const [selectedPost, setSelectedPost] = useState<IPostBatch | undefined>()


    useEffect(() => {
        if (recent_campaign_analytics && recent_campaign_analytics.length > 0) {
            const mostRecent = recent_campaign_analytics[0]

            const values = Object.keys(mostRecent.chartValues)
            const selectedValue = values[0];
            const selectedData = mostRecent.chartValues[selectedValue]

            setSelectedCampaign(mostRecent)
            setSelectedChartValue({ title: values[0], data: selectedData })
        }
    }, [recent_campaign_analytics])

    useEffect(() => {
        if (selectedCampaign) {
            const values = Object.keys(selectedCampaign.chartValues)
            const selectedValue = values[0];
            const selectedData = selectedCampaign.chartValues[selectedValue]


            setSelectedChartValue({ title: selectedValue, data: selectedData })
        }
    }, [selectedCampaign])


    useEffect(() => {
        if (selectedChartValue && selectedChartValue.data) {
            console.log(Object.keys(selectedChartValue.data));

            setChartLabels(Object.keys(selectedChartValue.data))
            setChartValues(Object.values(selectedChartValue.data))
        } else {
            setChartLabels(['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'])
            setChartValues([1050, 4022, 1101, 3034, 6000, 5000, 9000])
        }
    }, [selectedChartValue])



    const options = {
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: true
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                display: false
            },
            title: {
                display: false,
                text: 'no title',
            },
        },
    };
    //selectedCampaign?.data ?? dummyChartData
    const getChartData = () => {
        return {
            labels: chartLabels,
            datasets: [
                {
                    fill: true,
                    label: 'Campaign Impression',
                    data: chartValues,
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                        gradient.addColorStop(0, "#2C4BFF");
                        gradient.addColorStop(1, "rgba(13,65,225,0.6)");
                        return gradient;
                    },
                    borderColor: "transparent",
                    lineTension: 0.2,
                },
            ],
        }
    };



    const onUpdateChartDisplay = (type, data) => {
        if (selectedCampaign) {
            console.log(type, data);
            const key = data.key
            if (type === 'chartValue') {
                setSelectedChartValue({ title: key, data: selectedCampaign.chartValues[key] })
            } else if (type === 'campaign') {
                const selected = recent_campaign_analytics.find((cm) => cm.campaignName === key);
                setSelectedCampaign(selected)
            }
        }

    }

    console.log(selectedCampaign);


    const handleCloseModal = () => {
        setSelectedPost(undefined)
    };

    const handleOpenModal = (post) => {
        setSelectedPost(post)
    };

    return <>
        <ManagePostModal
            onCloseModal={handleCloseModal}
            openModal={selectedPost}
            existingPost={selectedPost}
        />

        <MainLayout title="Overview" >
            <div className="h-full">
                <div className="my-10  w-full overflow-x-scroll flex flex-nowrap">
                    <AnalyticsCard icon={'material-symbols:campaign'}
                        value={total_campaigns}
                        loading={loadingDashboard}
                        mobileTitle={'Campaigns'}
                        title={'Total Campaign'}
                    />

                    <AnalyticsCard
                        extraContent={<LineChartOutlined className="text-2xl hidden lg:inline-block text-orange-300" />}
                        icon={'fluent:people-audience-20-filled'}
                        value={all_time_reach}
                        loading={loadingDashboard}
                        mobileTitle={'Audience Reach'}
                        title={'All-Time Audience Reach'}
                    />

                    <AnalyticsCard
                        extraContent={<LineChartOutlined className="text-2xl hidden lg:inline-block text-green-300" />}
                        icon={'mdi:interaction-tap'} extraValue={'%'}
                        value={average_campaign_engagement_rate} loading={loadingDashboard}
                        mobileTitle={'Engagement'} title={'Average Engagement Rate '}
                    />

                    <AnalyticsCard icon={'icon-park-solid:circular-connection'}
                        value={total_connections}
                        loading={loadingDashboard}
                        mobileTitle={'Influencer Connections'}
                        title={'Influencer Connections'}
                    />
                </div>

                <div className="flex lg:flex-row flex-col-reverse gap-y-4  lg:h-[31rem] lg:mx-3 lg:px-0 px-3 mt-10 lg:mt-0">
                    <CampaignAnalytics
                        selectedCampaign={selectedCampaign}
                        chartLabels={chartLabels}
                        chartValues={chartValues}
                        selectedChartValue={selectedChartValue}
                        loadingDashboard={loadingDashboard}
                        recent_campaign_analytics={recent_campaign_analytics}
                        getChartData={getChartData}
                        options={options}
                        onUpdateChartDisplay={onUpdateChartDisplay}
                    />

                    {/*              <div className="lg:w-8/12 w-full bg-gray-50 rounded-lg h-full lg:mx-2 py-1 px-6">
                        <Spin size="small" tip={!selectedCampaign ? 'No data to display' : ''} spinning={loadingDashboard || !selectedCampaign} style={{ width: '100%' }}>
                            <CampaignAnalyticsNav
                                campaignList={recent_campaign_analytics}
                                selectedCampaign={selectedCampaign}
                                selectedChartValue={{
                                    title: selectedChartValue?.title,
                                    chartLabels,
                                    chartValues
                                }}
                                updateChartDisplay={onUpdateChartDisplay}
                            />

                            <div className="my-4">
                                <Line options={options} data={getChartData()} />
                            </div>
                        </Spin>
                    </div> */}

                    <div className="lg:w-4/12 w-full bg-gray-50 rounded-lg h-full  lg:mx-2 py-1 px-6">
                        <Spin size="small" spinning={loadingDashboard}>
                            <div className="mt-4 mb-8">
                                <span>Campaigns</span>
                            </div>
                            <div className="flex flex-col gap-y-6">
                                {recent_campaigns?.map((campaign) => <RecentCampaignCard onOpen={handleOpenModal} campaign={campaign} />)}
                            </div>
                            <div className="flex justify-center mt-10">
                                <Link to={'/campaigns'}>
                                    <div className="flex items-center justify-center cursor-pointer hover:bg-slate-100 p-2 w-fit">
                                        <span className="text-xs">View all</span>
                                        <Icon icon={'iconamoon:arrow-right-2-light'} className="text-sm" />
                                    </div>
                                </Link>

                            </div>
                        </Spin>
                    </div>
                </div>
            </div>
        </MainLayout>

    </>
}

export default BrandOverview

const RecentCampaignCard = (props: { campaign: IPostBatch, onOpen: (item: IPostBatch) => void }) => {
    const { campaign, onOpen } = props
    return <div onClick={() => onOpen(campaign)} className="w-full flex gap-x-1 items-center h-24 bg-white p-2 rounded-lg ">
        <div className="h-full bg-gray-0 rounded-lg w-2/12 flex items-center justify-center">
            <Icon icon={'bxl:twitter'} className="text-2xl" />
        </div>
        <div className=" flex-1 h-full pt-2 flex flex-col gap-y-2 p-2" >
            <div className="flex justify-between items-center">
                <span className="text-xs" >{campaign.name}</span>
                <Icon icon={'iconamoon:arrow-right-2-light'} className="text-sm" />
            </div>
            <div className="flex gap-x-2 flex-wrap gap-y-2">
                <span className="text-xs px-2 rounded-3xl bg-purple-200">{campaign?.queue_status}</span>
            </div>
            <div className="flex justify-between w-full items-center">
                <div className="flex gap-x-2 items-center">
                    <Icon icon={'carbon:calendar'} className="text-lg" />
                    <span className="text-xs">{dayjs(campaign.schedule).format('YYYY MMM DD')}</span>
                </div>
                <div className="flex gap-x-2 items-center">
                    <img src="/avatars.svg" />
                    <span className="text-xs">+1</span>
                </div>

            </div>

        </div>

    </div>
}



