
import React, { useEffect } from "react";
import { message } from "antd";
import { onLoadPageInfo } from "../../services/load_page_data.ts";
import { IPostChannel } from "../../interface/ipostchannel.ts";


export interface PostChannelContextType {
  channels: Array<IPostChannel> | undefined;
  loadingChannels: boolean,
  getChannels: () => void
}


export let PostChannelsContext = React.createContext<PostChannelContextType>(null!);

export function PostChannelsProvider({ children }: { children: React.ReactNode }) {

  let [channels, setChannels] = React.useState<Array<IPostChannel> | undefined>(undefined);
  let [loadingChannels, setLoadingChannels] = React.useState(false);


  useEffect(() => {
    getChannels()
  }, [])


  const getChannels = async () => {
    onLoadPageInfo({
      path: `/api/ai/channels`,
      onStartLoad: function (): void {
        setLoadingChannels(true);
      },
      onSuccess: function (data: any): void {
        setLoadingChannels(false);
        setChannels(data);
      },
      onFail: function (msg: string): void {
        setLoadingChannels(false);
        message.error(msg);
      }
    })
  }

  const value = { channels, getChannels, loadingChannels }

  return <PostChannelsContext.Provider value={value}>{children}</PostChannelsContext.Provider>;
}
