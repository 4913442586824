
import React, { useEffect } from "react";
import { IPersona } from "../../interface/ipersona.js";
import { message } from "antd";
import { onLoadPageInfo } from "../../services/load_page_data.ts";


export interface PersonaContextType {
  personas: Array<IPersona> | undefined;
  loadingPersonas: boolean,
  getPersonas: (isSuccess: (data: any) => void | undefined, isFail: (msg: any) => void | undefined) => void
}

export let PersonaContext = React.createContext<PersonaContextType>(null!);

export function PersonaProvider({ children }: { children: React.ReactNode }) {

  let [personas, setPersonas] = React.useState<Array<IPersona> | undefined>(undefined);
  let [loadingPersonas, setLoadingPersona] = React.useState(false);


  useEffect(() => {
    getPersonas(undefined, undefined)
  }, [])

  const getPersonas = async (isSuccess:  (data: any) => void, isFail: (msg: any) => void ) => {
    onLoadPageInfo({
      path: `/api/ai/personas`,
      onStartLoad: function (): void {
        setLoadingPersona(true);
      },
      onSuccess: function (data: any): void {
        setLoadingPersona(false);
        setPersonas(data.data);
        if (isSuccess) isSuccess(data.data)
      },
      onFail: function (msg: string): void {
        setLoadingPersona(false);
        if (isFail) isFail(msg)
        message.error(msg);
      }
    })
  }

  const value = { personas, getPersonas, loadingPersonas }

  return <PersonaContext.Provider value={value}>{children}</PersonaContext.Provider>;
}
