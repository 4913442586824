import React, { useEffect, useState } from "react"
import usePlatformsContext from "../../contexts/PlatformContext/usePlatformsHook.tsx"
import usePostChannelContext from "../../contexts/PostChannelsContext/usePostChannelHook.tsx";
import { Icon } from "@iconify/react";
import { IPlatform } from "../../interface/iplatform.ts";
import { Tooltip } from "antd";


const PlatformsToggle = (props: { type: 'list' | 'toggle' | 'active' | 'inactive' }) => {
    const platformsContext = usePlatformsContext();
    const postChannelsContext = usePostChannelContext()
    const [activePlatforms, setActivePlatforms] = useState<Array<IPlatform>>([])
    const [inActivePlatforms, setInActivePlatforms] = useState<Array<IPlatform>>([])



    useEffect(() => {
        const platforms = postChannelsContext.channels?.map((ch) => ch.platform) ?? []

        const uniquePlatforms = platforms.reduce((activePlatforms: Array<IPlatform>, current) => {
            if (!activePlatforms.find((item: IPlatform) => item.id === current?.id)) {
                activePlatforms.push(current!);
            }
            return activePlatforms;
        }, []);



        setActivePlatforms(uniquePlatforms)
    }, [platformsContext.platforms, postChannelsContext.channels])


    useEffect(() => {
        const inActivePlatforms = platformsContext.platforms?.filter((pl) => !activePlatforms.find((apl) => apl.id === pl.id)) ?? []
        setInActivePlatforms(inActivePlatforms)
        // console.log(inActivePlatforms);
    }, [platformsContext.platforms, activePlatforms])

    switch (props.type) {
        case 'list':
            return <div className="flex gap-x-3 items-center">
                {activePlatforms?.map((pl) => <Tooltip title={pl.platform_name}> <Icon className="text-xl cursor-pointer" icon={pl?.platform_logo} /> </Tooltip>)}
                {inActivePlatforms?.map((pl) => <Tooltip title={pl.platform_name}> <Icon className={`text-xl cursor-pointer ${pl.is_active === 1? '': 'text-gray-300'}`} icon={pl?.platform_logo} /> </Tooltip>)}
            </div>

        default:
            <div></div>;
    }

}

export default PlatformsToggle