import { Modal, Steps, message } from "antd"
import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { Icon } from "@iconify/react"
import { PersonaBackground } from "./PersonaBackground.tsx"
import { PersonaAudience } from "./PersonaAudience.tsx"
import { PersonaObjectives } from "./PersonaObjectives.tsx"
import { PersonaTone } from "./PersonaTone.tsx"
import { makePostRequest, makePutRequest } from "../../utils/index.js"

export const ManagePersonaModal = ({ onCloseModal, openModal, existingPersona }) => {
    const [current, setCurrent] = useState(0);
    const [persona, setPersona] = useState({});
    const [loading, setLoading] = useState(false)

    const resetModal = () => {
        setCurrent(0)
        setPersona({})
        onCloseModal(true)
    }


    useEffect(() => {
        if (existingPersona) setPersona(existingPersona)
    }, [existingPersona])

    useEffect(() => {
        // console.log(persona);
    }, [persona])

    const onChangeStep = (step: number) => {
        if (existingPersona) {
            setCurrent(step);
        }
    }

    const onAfterSave = async (personaUpdate) => {
        const response = await onSubmitInfo(personaUpdate)
        if (response) {
            setPersona(response)
            if (current === 0) {
                setCurrent(current + 1)
            }
            else if (current < 3) {
                setCurrent(current + 1)
            } else if (current === 3) {
                resetModal(true)
            }
        }

    }

    const onSubmitInfo = async (data) => {
        setLoading(true);
        try {
            let response
            if (persona?.id) {
                response = await makePutRequest(`/api/ai/personas/${persona?.id}`, {
                    ...persona,
                    ...data,
                    is_generate_new_rec: false,
                });
            } else {
                response = await makePostRequest("/api/ai/persona", {
                    ...persona,
                    ...data,
                    is_generate_new_rec: false,
                });
            }
            if (response.status === "success") {
                setLoading(false);
                message.success('Persona updated')
                
                return response.data
            } else {
                message.error(response.reason);
                setLoading(false);
                return false
            }
        } catch (error) {
            message.error(error.message);
            setLoading(false);
            return false
        }
    };

    return <Modal
        open={openModal}
        onCancel={() => resetModal(true)}
        footer={null}
        width={isMobile ? undefined : 1100}
        closeIcon={null}
        className="shadow-none border-none"
        destroyOnClose={true}
    >
        <div className="absolute top-2 right-1 z-10 cursor-pointer bg-white rounded-full p-3" onClick={() => resetModal(true)}>
            <Icon className="text-lg text-gray-800" icon={'zondicons:close-solid'} />
        </div>
        <div className="flex justify-between gap-x-3">
            <div className="lg:w-4/12 hidden lg:flex flex-col gap-y-6 bg-white rounded p-3 py-14">
                <Steps
                    direction="vertical"
                    current={current}
                    className="text-sm"
                    size="small"
                    onChange={onChangeStep}
                    items={[
                        {
                            title: 'Background ',
                            description: 'A little bit about yourself',
                        },
                        {
                            title: 'Audience',
                            description: 'Define your audience',
                        },
                        {
                            title: 'Objectives',
                            description: 'Define your writing objectives',
                        },
                        {
                            title: 'Tone',
                            description: "Define your preferred tone",
                        },
                    ]}
                />
            </div>
            <div className="lg:w-8/12 w-full bg-white rounded px-3">
                {current === 0 && <PersonaBackground
                    onAfterSave={(persona) => onAfterSave(persona)}
                    loading={loading}
                    persona={persona}
                />}
                {current === 1 && <PersonaAudience
                    onAfterSave={(persona) => onAfterSave(persona)}
                    persona={persona}
                    loading={loading}
                />}
                {current === 2 && <PersonaObjectives
                    onAfterSave={(persona) => onAfterSave(persona)}
                    persona={persona}
                    loading={loading}
                />}
                {current === 3 && <PersonaTone
                    onAfterSave={(persona) => onAfterSave(persona)}
                    persona={persona}
                    loading={loading}
                />}
            </div>
        </div>
    </Modal>
}

