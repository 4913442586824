import { Checkbox, Input, Modal, Segmented, Switch, message } from "antd";
import { useContext, useState } from "react";
import { MButton } from "../../Common/buttons";
import { makePostRequest } from "../../../utils";
import { useParams } from "react-router-dom";

const TeamInfoModal = ({ openModal, onToggleModal }) => {
  const [loading, setLoading] = useState(false);
  const [memberInfoInput, setMemberInfoInput] = useState({});
  const [backendPermissions, setBackendPermissions] = useState({});
  const [segment, setSegment] = useState("Backend permissions");
  const [dashboardPermissions, setDashboardPermissions] = useState([]);


  const params = useParams();

  const isBackendPermissionsSegment = segment === "Backend permissions";

  const onChangeInput = (key, value) => {
    const getInputsState = { ...memberInfoInput };
    getInputsState[key] = value;
    setMemberInfoInput(getInputsState);
  };

  const onSubmitButtonClicked = async () => {
    if (isBackendPermissionsSegment) {
      setSegment("Dashboard permissions");
    } else {
      if (!memberInfoInput.email) {
        message.error("Email is required");
      } else if (!memberInfoInput.fullname) {
        message.error("Full name is required");
      } else if (!memberInfoInput.user_role_description) {
        message.error("User role description is required");
      } else {
        await submitInvite();
      }
    }
  };

  const onCloseModal = (refresh = false) => {
    setLoading(false);
    onToggleModal(false, refresh);
  };

  const submitInvite = async () => {
    setLoading(true);
    try {
      const response = await makePostRequest(
        `/api/datasource/${params?.id}/team/invite`,
        {
          ...memberInfoInput,
          dashboardPermissions: dashboardPermissions ?? [],
          backendPermissions: backendPermissions ?? {},
        }
      );

      if (response.status === "success") {
        message.success("Member invite sent");
        onCloseModal(true);
      } else {
        setLoading(false);
        message.error(response.reason);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={openModal}
      onCancel={() => onCloseModal(false)}
      footer={null}
      title={"Invite team member"}
      width={700}
      destroyOnClose={true}
    >
      <div className="py-5 flex flex-col gap-x-2 mx-auto">
        <div className="flex flex-wrap gap-y-3 gap-x-4">
          <div className="w-5/12">
            <span className="block py-2 text-xs">Fullname</span>
            <Input
              status={!memberInfoInput.fullname ? "error" : ""}
              required={true}
              value={memberInfoInput["fullname"]}
              onChange={(input) =>
                onChangeInput("fullname", input.target.value)
              }
              className={``}
              placeholder={`Enter member full name`}
            />
          </div>

          <div className="w-5/12">
            <span className="block py-2 text-xs">Email address</span>
            <Input
              status={!memberInfoInput.email ? "error" : ""}
              required={true}
              value={memberInfoInput["email"]}
              onChange={(input) => onChangeInput("email", input.target.value)}
              className={``}
              placeholder={`Enter member email address `}
            />
          </div>

          <div className="w-5/12">
            <span className="block py-2 text-xs">User role description</span>
            <Input
              status={!memberInfoInput.user_role_description ? "error" : ""}
              required={true}
              value={memberInfoInput["user_role_description"]}
              onChange={(input) =>
                onChangeInput("user_role_description", input.target.value)
              }
              className={``}
              placeholder={`E.g Customer service rep, Engineering`}
            />
          </div>
        </div>

        <div className="flex justify-center mt-3">
          <MButton
            disabled={loading}
            loading={loading}
            onClick={onSubmitButtonClicked}
            label={
              isBackendPermissionsSegment ? "Continue invite" : "Submit invite"
            }
            className={"py-2"}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TeamInfoModal;
