
import React, { useEffect } from "react";
import { IPersona } from "../../interface/ipersona.js";
import { message } from "antd";
import { onLoadPageInfo } from "../../services/load_page_data.ts";
import { IPostBatch } from "../../interface/ipostbatch.ts";


export interface PostsContextType {
  posts: Array<IPostBatch> | undefined;
  activePosts: Array<IPostBatch> | undefined;
  loadingPosts: boolean,
  getPosts: (isSuccess: (data: any) => void | undefined, isFail: (msg: any) => void | undefined) => void
  getActivePosts: (isSuccess: (data: any) => void | undefined, isFail: (msg: any) => void | undefined) => void
}


export let PostsContext = React.createContext<PostsContextType>(null!);

export function PostsProvider({ children }: { children: React.ReactNode }) {

  let [posts, setPosts] = React.useState<Array<IPostBatch> | undefined>(undefined);
  let [activePosts, setActivePosts] = React.useState<Array<IPostBatch> | undefined>(undefined);

  let [loadingPosts, setLoadingPosts] = React.useState(false);


  useEffect(() => {
    getPosts(undefined, undefined)
    getActivePosts(undefined, undefined)
  }, [])

  const getActivePosts = async (isSuccess: (data: any) => void, isFail: (msg: any) => void) => {
    onLoadPageInfo({
      path: `/api/ai/activePosts`,
      onStartLoad: function (): void {
        setLoadingPosts(true);
      },
      onSuccess: function (data: any): void {
        setLoadingPosts(false);
        setActivePosts(data);
        if (isSuccess) isSuccess(data.data)
      },
      onFail: function (msg: string): void {
        setLoadingPosts(false);
        if (isFail) isFail(msg)
        message.error(msg);
      }
    })
  }

  const getPosts = async (isSuccess: (data: any) => void, isFail: (msg: any) => void) => {
    onLoadPageInfo({
      path: `/api/ai/posts`,
      onStartLoad: function (): void {
        setLoadingPosts(true);
      },
      onSuccess: function (data: any): void {
        console.log(data);

        setLoadingPosts(false);
        setPosts(data);
        if (isSuccess) isSuccess(data.data)
      },
      onFail: function (msg: string): void {
        setLoadingPosts(false);
        if (isFail) isFail(msg)
        message.error(msg);
      }
    })
  }

  const value = { posts, activePosts, getActivePosts, getPosts, loadingPosts }

  return <PostsContext.Provider value={value}>{children}</PostsContext.Provider>;
}
