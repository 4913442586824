import { Spin } from "antd"
import React from "react"
import { Line } from "react-chartjs-2"
import { CampaignAnalyticsNav } from "./CampaignAnalyticsNav.tsx"


export const CampaignAnalytics = ({ selectedCampaign, chartLabels, chartValues, selectedChartValue, loadingDashboard, recent_campaign_analytics, getChartData, options, onUpdateChartDisplay }) => {
    return <div className="lg:flex-1 w-full bg-gray-50 rounded-lg h-full lg:mx-2 py-1 px-6">
        <Spin size="small" tip={!selectedCampaign ? 'No data to display' : ''} spinning={loadingDashboard || !selectedCampaign} style={{ width: '100%' }}>
            <CampaignAnalyticsNav
                campaignList={recent_campaign_analytics}
                selectedCampaign={selectedCampaign}
                selectedChartValue={{
                    title: selectedChartValue?.title,
                    chartLabels,
                    chartValues
                }}
                updateChartDisplay={onUpdateChartDisplay}
            />

            <div className="my-4">
                <Line options={options} data={getChartData()} />
            </div>
        </Spin>
    </div>
}