import { Icon } from "@iconify/react"
import { Avatar, DatePicker, Dropdown, Form, Input, Radio, Select, Spin, Tag, message } from "antd"
import React, { useEffect, useState } from "react"
import { kFormatter, ngnMoneyFormatter, numberFormatter } from "../../utils/index.js"
import dayjs from "dayjs"



export const PostFinishSetup = ({
    selectedNiches,
    scheduledDate,
    disabledDate,
    onChangeSchedule,
    isPostEditingDisabled,
    timezone,
    totalCampaignCost,
    selectedInfluencers,
    totalReach,
}) => {

    return <div className=" lg:mb-0 w-full  h-[600px] px-4  py-2">

        <div className="pb-5 flex lg:flex-row flex-col justify-between gap-x-5">
            <span className="font-semibold text-lg">Review Campaign</span>
        </div>

        <div className="flex justify-between w-10/12 items-center">
            <div className="flex flex-col justify-start">
                <span className="text-xs text-gray-400">Total amount</span>
                <span className="text-3xl">{ngnMoneyFormatter.format(totalCampaignCost)}</span>
            </div>

            <div className="flex flex-col justify-start">
                <span className="text-xs text-gray-400">Influencers</span>
                <div className="flex gap-x-2 items-center">
                    <img src="/avatars.svg" className="text-2xl" />
                    <span className="text-sm">+ {selectedInfluencers?.length}</span>
                </div>
            </div>
        </div>


        <div className="flex justify-between w-10/12 items-center mt-8">
            <div className="flex flex-col justify-start">
                <span className="text-xs text-gray-400">Estimated reach</span>
                <span className="text-3xl">{numberFormatter.format(totalReach)}</span>
            </div>
            {/* 
            <div className="flex flex-col justify-start">
                <span className="text-xs text-gray-400">Influencers</span>
                <div className="flex gap-x-2 items-center">
                    <img src="/avatars.svg" className="text-2xl" />
                    <span className="text-sm">+1</span>
                </div>
            </div> */}
        </div>

        <div className="flex justify-between w-10/12 items-center mt-8">
            <div className="flex flex-col justify-start pt-2">
                <span className="text-xs text-gray-400 mb-2">Targeted Niches</span>
                <div className="flex flex-row justify-start gap-x-2 items-center">
                    {selectedNiches?.slice(0, 10).map((niche) =>
                        <div className="flex gap-x-2 flex-wrap gap-y-2 items-center">
                            <span className="text-sm p-1 px-3 rounded-3xl bg-purple-200">{niche}</span>
                        </div>
                    )}
                    {selectedNiches?.length > 10 && <span className="text-sm">+ {selectedInfluencers?.length - 9}</span>}
                </div>
            </div>
        </div>


        <div className="flex justify-between lg:w-10/12 w-full items-center mt-8">
            <div className="flex flex-col justify-start pt-2 w-full">
                <span className="text-xs text-gray-400 mb-2">Schedule Campaign</span>
                <div className="flex flex-row justify-between gap-x-2 items-center w-full ">

                    <div className="flex gap-x-5 justify-between  px-0 items-center w-fit">
                        <Icon className="text-lg" icon={'file-icons:moment-timezone'} />
                        <span className="text-gray-600 text-sm">{timezone}</span>
                    </div>

                    <div className="flex gap-x-2 cursor-pointer items-center relative justify-start  px-0 w-60">
                        <Icon className="text-lg" icon={'bxs:calendar'} />
                        <DatePicker
                            disabledDate={disabledDate}
                            clearIcon={null}
                            suffixIcon={<Icon className="cursor-pointer text-xs transition-all duration-200 hover:scale-105"
                                icon={'streamline:pencil-solid'} />}
                            format={'DD MMM YY HH:mm:ss'}
                            defaultValue={dayjs(scheduledDate)}
                            disabled={isPostEditingDisabled}
                            onChange={onChangeSchedule}
                            showTime={true}
                            status={scheduledDate ? '' : 'error'}
                            className="w-60"
                        />
                    </div>

                </div>
            </div>

        </div>





    </div >

}