import React from "react"


const MainLayout = ({ children, title, customHeader, extra = undefined }) => {

    return <div className=" justify-between w-full h-full pb-30 lg:px-11 py-4 ">
        {customHeader && customHeader}
        {!customHeader &&
            <div className="flex items-center justify-between lg:mx-4 mx-3 h-10">
                <span className="text-2xl font-bold"> {title} </span>
                {extra && extra}
            </div>
        }

        {children}
    </div>
}

export default MainLayout
