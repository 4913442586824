
import React, { useEffect, useRef } from "react";

type RefList = {
  'Personas': any,
  'Channels': any,
  'Posts': any
}

export interface TourContextType {
  open: boolean
  setOpen: any,
  refs: RefList,
  steps: any,
  triggerFirsOpenTour: any
}

export let TourContext = React.createContext<TourContextType>(null!);

export function TourProvider({ children }: { children: React.ReactNode }) {

  const oneRef = useRef(null);
  const twoRef = useRef(null);
  const threeRef = useRef(null);

  const refs: RefList = {
    'Personas': oneRef,
    'Channels': twoRef,
    'Posts': threeRef
  }
  let [open, setOpen] = React.useState(false);

  const triggerFirsOpenTour = () => {
    if (localStorage.getItem('toured') === null) {
      localStorage.setItem('toured', 'true')
      setOpen(true)
    }
  }


  const steps = [
    {
      title: 'Start tour',
      description: 'Welcome, Take 5 seconds to learn how about important functionalities',
      target: null,
    },
    {
      title: 'AI Assistant',
      description: 'Create one or more personas. This activates our AI assistant, and assist you when creating content.',
      target: () => refs['Personas'].current,
    },
    {
      title: 'Channel',
      description: 'Connect all your social media accounts so that you can manage messages, send posts and view analytics from a single dashboard ',
      placement: 'right',
      target: () => refs['Channels'].current,
    },
    {
      title: 'Send Posts',
      description: 'Post to all your channels immediately or schedule them to automatically be posted at a  specific time',
      placement: 'top',
      target: () => refs['Posts'].current,
    },
  ];


  const value = { open, setOpen, refs, steps, triggerFirsOpenTour }
  return <TourContext.Provider value={value}>{children}</TourContext.Provider>;
}
