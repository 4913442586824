import { Icon } from "@iconify/react";
import { Avatar, Tour, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { MobileSubNav } from "../Nav/MobileSubNav.tsx";
import { SideNav } from "../Nav/SideNav.tsx";
import { TopNav } from "../Nav/topNav.tsx";
import { logEvent } from "firebase/analytics";
import { isMobile } from "react-device-detect";
import usePersonaContext from "../../contexts/PersonaContext/UsePersonaHook.tsx";
import usePostsContext from "../../contexts/PostContext/UsePostHook.tsx";
import { analytics } from "../../utils/firebaseutil.ts";
import useTourContext from "../../contexts/TourContext/TourContextHook.tsx";
import useAuth from "../Auth/UseAuthHook.tsx";

export const BrandApp = ({ navItems }) => {
    const location = useLocation();

    const tourContext = useTourContext();
    const postsContext = usePostsContext()
    const authContext = useAuth()
    // const postsQueueContext = usePostsQueueStatusContext();
    const [isMobileNavOpen, setIsMobileNavOpened] = useState(false);
    const navigate = useNavigate()


    const onClickNav = (location) => {
        if (location) navigate(location)
        setIsMobileNavOpened(false)
    }


    const [loading, setLoading] = useState(false)


    useEffect(() => {
        setupAppData()
        tourContext.triggerFirsOpenTour()
    }, []);

    const setupAppData = async () => {
        logEvent(analytics!, "page_visited", {
            content_type: "app_page",
            content_id: "app_home",
        });

        /*         personaContext.getPersonas((data) => {
                    // console.log(data);
                }, () => { });
                postsContext.getPosts((data) => { }, () => { }); */

        // this needs to be pooled, more logic coming
        //   postsQueueContext.getQueueStatuses((data) => { }, () => { });
    }

    return (
        <main className="flex flex-1 items-start justify-start h-screen lg:w-full w-full mx-auto">
            {/*  <Tour open={tourContext.open}
                onClose={() => tourContext.setOpen(false)}
                steps={tourContext.steps}
            /> */}
            {/*    <TopNav currentPath={location.pathname} />
            <MobileSubNav items={navItems} currentPath={""} title={"Products"} actionBtn={{
                onClick: function (): void {
                    message.info("Function not implemented.");
                },
                label: "New product",
                icon: "mdi:plus-circle"
            }} /> */}
            {/*    <BottomNav currentPath={location.pathname} /> */}
            <SideNav
                navItems={navItems}
            />
            <MobileSubNav
                items={navItems}
                title={""}
                actionBtn={(to) => {
                    navigate(to);
                    setIsMobileNavOpened(false)
                }}
                currentPath={""}
                openDrawer={isMobileNavOpen}
                closeDrawer={() => setIsMobileNavOpened(false)}
            />

            <section className="flex-1 flex flex-col bg-white overflow-y-scroll h-full w-full ">
                <div className={`w-full relative  flex justify-center ${postsContext.loadingPosts ? 'pt-0' : 'pt-0'} transition-all duration-200 `}>
                    <LargeScreenHeader authContext={authContext} />
                    <SmallScreenHeader onOpen={() => setIsMobileNavOpened(true)} />
                </div>
                <div className="flex-1 h-full w-full">
                    <Outlet />
                </div>

            </section>
        </main>
    );
};



const LargeScreenHeader = ({ authContext }) => {
    return <div className="hidden lg:flex h-14 border-b border-b-gray-100 w-full  justify-end items-center px-16 gap-x-10">
        <Icon icon={'basil:notification-outline'} />
        <div className="flex items-center gap-x-2">
            <Avatar className="bg-primary" size={'small'}> {authContext?.user?.fullname?.substring(0, 2)}</Avatar>
            <div className="flex flex-col items-start text-start">
                <span className="text-gray-600 text-xs">{authContext?.user?.fullname} </span>
                <span className="text-gray-600 text-xs">{authContext?.user?.email}</span>
            </div>
        </div>
        <Icon icon={'iconamoon:arrow-down-2-thin'} />
    </div>
}

const SmallScreenHeader = ({ onOpen }) => {
    return <>

        <div className="flex items-center justify-between lg:hidden w-full pr-3 py-2 border-b border-gray-100">
            <img src="/logo.svg" />

            <Icon onClick={onOpen} icon={'pajamas:hamburger'} className="text-2xl text-gray-600" />
        </div>
    </>
}