import { Avatar, Card, Input, List, Select } from "antd"
import React from "react"
import { ITransaction } from "../../interface/itransaction";
import { formatMoney, ngnMoneyFormatter } from "../../utils";
import { Icon } from "@iconify/react";
import dayjs from "dayjs";

const TransactionHistory = ({ item }) => {
    const type = item.type;
    const icon = type === 'credit' ? 'gridicons:plus' : 'ph:minus-bold'
    const color = type === 'credit' ? 'bg-[#28806F]' : 'bg-[#DF1C41]'

    return <div className="flex items-center gap-x-4 py-4 w-full">
        <div className={`rounded-full ${color}  p-2 `}>
            <Icon className="text-xl text-white" icon={icon} />
        </div>
        <div className="flex flex-col items-start gap-y-1 w-full">
            <div className="flex justify-between items-center w-full">
                <span className="text-gray-600">{item?.description}</span>
                <span>{ngnMoneyFormatter.format(item?.amount)}</span>
            </div>
            <span className="text-gray-600">{dayjs(item.created_at).format('YYYY MMM DD')}</span>
        </div>
    </div>
}

export default TransactionHistory;