
import React, { useEffect } from "react";
import { IPersona } from "../../interface/ipersona.js";
import { message } from "antd";
import { onLoadPageInfo } from "../../services/load_page_data.ts";
import { IInfluencer } from "../../interface/influencer.ts";


export interface InfluencerContextType {
  influencers: Array<IInfluencer> | undefined;
  loading: boolean,
  getInfluencers: (isSuccess: (data: any) => void | undefined, isFail: (msg: any) => void | undefined) => void
}

export let InfluencerContext = React.createContext<InfluencerContextType>(null!);

export function InfluencerProvider({ children }: { children: React.ReactNode }) {

  let [influencers, setInfluencers] = React.useState<Array<IPersona> | undefined>(undefined);
  let [loading, setLoading] = React.useState(false);


  useEffect(() => {
    getInfluencers(undefined, undefined)
  }, [])

  const _transFormData = (data) => {
    console.log(data);
    
    const transformedData = data.map((influencer) => {
      const allNiches = (influencer.channel_niche ?? []).map((nc) => nc.niche)
      const channel = influencer.channel.find((ch) => ch.platform_id == 1);
      const channelPrice = influencer.channel_price.find((ch) => ch.platform_id == 1);
      
      return {
        niches: allNiches,
        username: channel?.channel_name,
        name: influencer.company_name,
        company_id: influencer.id,
        price_per_post: channelPrice?.post_price,

        avatar: 'https://xsgames.co/randomusers/avatar.php?g=pixel&index=',
        bio: 'Nothing dies in my hands. I love to code and talk about startups.',
        followers: 440000,
      }
    })
    
    setInfluencers(transformedData);
    return transformedData;
  }


  const getInfluencers = async (isSuccess: (data: any) => void, isFail: (msg: any) => void) => {
    onLoadPageInfo({
      path: `/api/ai/listInfluencers`,
      onStartLoad: function (): void {
        setLoading(true);
      },
      onSuccess: function (data: any): void {
        setLoading(false);
        _transFormData(data.data)
        if (isSuccess) isSuccess(data.data)
      },
      onFail: function (msg: string): void {
        setLoading(false);
        if (isFail) isFail(msg)
        message.error(msg);
      }
    })
  }

  const value = { influencers, getInfluencers, loading }

  return <InfluencerContext.Provider value={value}>{children}</InfluencerContext.Provider>;
}
