import { useLinkedIn } from "react-linkedin-login-oauth2";
import { makePostRequest } from "../../utils"


const ChannelService = () => {

    async function initiateTwitter() {
        const response = await makePostRequest('/api/ai/initiateChannelIntegration', { platform_id: 1 })
        window.location.href = response.data?.redirect
    }


    async function initiateLinkedInProfile() {
        const state = new Date().getTime()
        window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78wnlzr94iwwz8&redirect_uri=http://localhost:3000/channels?action=addChannel&platform_id=2&scope=w_member_social,email,profile,openid&state=${state}`
    }

    async function initiateTikTokProfile() {
        const response = await makePostRequest('/api/ai/initiateChannelIntegration', { platform_id: 5 })
        window.location.href = response.data?.redirect
    }


    async function initiateSMS() {
        console.log('not imlemented');
    }


    async function initiateEmail() {
        console.log('not imlemented');
    }


    async function initiateLinkedInPage() {
        console.log('not imlemented');
    }

    async function completeLinkedIn() {
        console.log('not imlemented');
    }

    return {
        initiateTwitter,
        initiateLinkedInProfile,
        initiateTikTokProfile,
        initiateLinkedInPage,
        initiateEmail,
        initiateSMS,
        completeLinkedIn,
    }
}

export default ChannelService