import { Icon } from "@iconify/react"
import { Modal, Input, message, Select } from "antd"
import { MButton } from "../Common/buttons"
import React, { useState } from "react"
import { makePostRequest } from "../../utils"
import ChannelService from "./channelsService.ts"
import { isMobile } from "react-device-detect"
import ChannelsToggle from "../Common/ChannelsToggle.tsx"
import { IPostChannel } from "../../interface/ipostchannel"
import PlatformsToggle from "../Common/PlatformsToggle.tsx"
import useAuth from "../Auth/UseAuthHook.tsx"

export const WaitListModal = ({ isActivateSocial }) => {

    const authContext = useAuth();

    return <Modal
        open={isActivateSocial}
        /*  onCancel={() => setIsActiveSocial(false)} */
        footer={null}
        closeIcon={null}
        className="shadow-none border-none"
        maskClosable={false}
        maskStyle={{ color: 'white' }}

        closable={false}
        //  width={isMobile ? undefined : 1100}
        destroyOnClose={true}
    >

        <div className="flex flex-col gap-y-5 bg-white py-8 rounded px-4 ">
            <div className="flex flex-col justify-between items-start gap-y-2">
                <span>You're on the waitlist !</span>
                <p className="text-xs text-gray-600 block mt-5">

                    We are onboarding brands and creators gradually to ensure the best experience and excellent customer service. Your account will be on-boarded as soon as possible.
                </p>
            </div>


            <div className="flex justify-center items-center">
                <MButton iconColor={'text-primary'}
                    onClick={() => authContext.signOut(() => { })}
                    icon={'solar:logout-3-bold'}
                    label={'Logout '}

                    inverted={true}
                    loading={false}
                    className={undefined}
                    disabled={undefined} ref={undefined} />
            </div>
        </div>

    </Modal>
}