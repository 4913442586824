import { fetchUserOrDie, makePostRequest, setUserInfo } from "../utils";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MButton } from "../components/Common/buttons";
import { Helmet } from "react-helmet";
import useAuth from "../components/Auth/UseAuthHook.tsx";
import { Str } from "../utils/constants";
import AuthPagesCopy from "../components/Common/AuthPagesCopy.tsx";
import { logEvent } from "firebase/analytics";
import { analytics } from "../utils/firebaseutil.ts";
import { SocialAuth } from "../components/Common/SoaicalAuth.tsx";
import AuthHalf from "../components/Common/AuthPagesCopy.tsx";

export default function Register() {
  const [loading, setLoading] = useState(false);
  const authContext = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


  useEffect(() => {
    logEvent(analytics, "page_visited", {
      content_type: "app_page",
      content_id: "register_page",
    });
  }, []);

  const [data, setData] = useState({
    email: undefined,
    password: undefined,

    phone: undefined,
    company_name: undefined,
    fullname: undefined,
  });

  const onSignUp = async () => {
    setLoading(true);
    let from = location.state?.from?.pathname || Str.paths.home;

    try {
      const response = await makePostRequest("/api/auth/register", {
        ...data,
        plan_id: 1,
        timezone
      });

      if (response.status == "success") {
        setLoading(false);
        message.success("Login success");
        let expires = new Date();
        expires.setTime(expires.getTime() + 36000 * 100000);
        authContext.signIn(response.data, () => {
          setUserInfo(response.data);
          navigate('/posts');
        });
      } else {
        message.error(response.reason);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const onSubmitSignup = async () => {
    console.log(data);
    if (!data.email) {
      message.error("Enter a valid email address");
      return
    }

    if (!data.phone || data.phone.length < 1) {
      message.error("Enter a phone number (Whatsapp)");
      return
    }

    if (!data.fullname || data.fullname.length < 1) {
      message.error("Enter fullname");
      return
    }

    if (!data.password) {
      message.error("Enter a valid password");
      return
    } else {
      await onSignUp();
    }
  };

  const onInputData = (type, text) => {
    switch (type) {
      case "fullname":
        data.fullname = text;
        data.company_name = text;
        break;
      case "email":
        data.email = text;
        break;
      case "phone":
        data.phone = text;
        break;
      case "password":
        data.password = text;
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CreatorBase | Create CreatorBase account</title>
        <meta name="description" content="CreatorBase" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        <link rel="icon" href="/quill-pen.png" />
        <link rel="canonical" href="https://app.sequelbase.com/connect" />
      </Helmet>
      <div className="bg-white">
        <main className="h-screen bg-white  mx-auto  w-full flex   lg:flex-row flex-col-reverse ">
          <AuthHalf />
          <div className="flex flex-col gap-y-4 w-full lg:w-6/12 items-center justify-start py-3  lg:mb-0 mb-20">
            <div className="w-10/12 mx-auto">
              <div>
                <div className="flex items-center mt-4">
                  <h1
                    style={{ fontFamily: "mainFontBold" }}
                    className="font-black text-2xl my-0"
                  >
                    Create a new account
                  </h1>
                </div>
                <span className="text-gray-600 text-sm">
                  Already have an account? ?{" "}
                  <span className="text-blue-600">
                    <Link to={"/login"}>Login</Link>{" "}
                  </span>
                </span>
              </div>
              <div className="flex flex-col justify-start mt-4 lg:mt-10">
                <div className="my-0"></div>


                <div className="my-0">
                  <span className="text-black text-sm">Fullname</span>
                  <Input
                    name={"fullname"}
                    onChange={(val) =>
                      onInputData("fullname", val.target.value)
                    }
                    placeholder="John Bench"
                    className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                  />
                </div>

                <div className="my-0">
                  <span className="text-black text-sm">Email</span>
                  <Input
                    name={"email_add"}
                    onChange={(val) => onInputData("email", val.target.value)}
                    placeholder="email@example.com"
                    className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                  />
                </div>

                <div className="my-0">
                  <span className="text-black text-sm">Phone (Whatsapp)</span>
                  <Input
                    name={"phone"}
                    onChange={(val) => onInputData("phone", val.target.value)}
                    placeholder="090388838872"
                    type="tel"
                    className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                  />
                </div>

                <div className="my-0">
                  <span className="text-black text-sm">Password</span>
                  <Input
                    name={"email_add"}
                    type="password"
                    onChange={(val) =>
                      onInputData("password", val.target.value)
                    }
                    placeholder="********"
                    className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                  />
                </div>
                <div className="w-full flex mt-4">
                  <MButton
                    onClick={onSubmitSignup}
                    loading={loading}
                    className={"w-full text-center"}
                    label={"Create account"}
                  />
                </div>

                {/*   <SocialAuth /> */}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
