import React, { useEffect, useState } from "react"

import { Icon } from "@iconify/react";
import dayjs from "dayjs";
import { IPostBatch } from "../../interface/ipostbatch.tsx";
import { formatNum, ngnMoneyFormatter } from "../../utils";
import MainLayout from "../Common/Layout.tsx";
import { Line } from "react-chartjs-2";
import { LineChartOutlined } from '@ant-design/icons';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import useBrandDashboardContext from "../../contexts/BrandContext/BrandHook.tsx";
import { Dropdown, Spin } from "antd";
import { Link } from "react-router-dom";
import { ManagePostModal } from "../ManagePostModal/ManagePostModal.tsx";
import { AnalyticsCard } from "../Common/Campaign/AnalyticsCard.tsx";
import { CampaignAnalyticsNav } from "../Common/CampaignChart/CampaignAnalyticsNav.tsx";
import { CampaignAnalytics } from "../Common/CampaignChart/CampaignAnalytics.tsx";
import CreatorOverview from "./creator.tsx";
import BrandOverview from "./brand.tsx";
import CreatorCampaigns from "./creator.tsx";
import BrandCampaigns from "./brand.tsx";
import useAuth from "./Auth/UseAuthHook.tsx";

ChartJS.register(CategoryScale, LinearScale, Filler, PointElement, LineElement, Tooltip, Title, Legend);

const postViews = [
    { key: 'list', label: 'List view', icon: <Icon icon={'ic:round-list'} /> },
    { key: 'calendar', label: 'Calendar view', icon: <Icon icon={'solar:calendar-bold'} /> },
]

const ComponentSelector = ({ creatorCampaign, brandCampaign }) => {
    const userContext = useAuth()
    const { account_type } = userContext.user;
    const isCreator = account_type === 'creator';

    console.log(account_type);


    return isCreator ? creatorCampaign : brandCampaign
}

export default ComponentSelector




