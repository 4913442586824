import React, { useEffect, useState } from "react"
import { MButton } from "../Common/buttons"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Button, Input, Modal, Select, Spin, Tabs, Tag, Tooltip, message } from "antd"
import { IUser } from "../../interface/iuser";
import usePersonaContext from "../../contexts/PersonaContext/UsePersonaHook.tsx";
import { Avatar } from "antd";
import { Icon } from "@iconify/react";
import { ManagePersonaModal } from "../ManagePersonaModal/ManagePersonaModal.tsx";
import usePostsContext from "../../contexts/PostContext/UsePostHook.tsx";
import { IPersona } from "../../interface/ipersona.ts";
import { LoadingOutlined } from "@ant-design/icons";
import { PageHeader } from "../Common/PageHeader.tsx";


const layoutViews = [
    { key: 'list', label: 'List view', icon: <Icon icon={'ic:round-list'} /> },
    { key: 'grid', label: 'Grid view', icon: <Icon icon={'solar:calendar-bold'} /> },
]

const Persona = () => {
    const personaContext = usePersonaContext()

    const [openNewModal, setOpenNewModal] = useState<boolean>(false)
    const [selectedPersona, setSelectedPersona] = useState<IPersona | undefined>()
    const [selectedLayoutView, setSelectedLayoutView] = useState()

    const params = useParams()
    const postsContext = usePostsContext()
    const nav = useNavigate()

    const onSelectPersona = (selectedPersona: IPersona) => {
        setSelectedPersona(selectedPersona)
    }

    const onAddNewPersona = () => {
        setOpenNewModal(true)
    }

    const onCloseModal = (isRefresh: boolean) => {
        onSelectPersona(undefined)
        setOpenNewModal(false)
        if (isRefresh) {
            personaContext.getPersonas(() => { }, () => { })
        }
    }



    const handleMenuClick = (e) => {

    };


    useEffect(() => {
        setSelectedLayoutView(layoutViews[0])
    }, [])

    const menuProps = {
        items: layoutViews,
        onClick: handleMenuClick,
    };


    return <>
        <div className="flex">
            <ManagePersonaModal
                onCloseModal={onCloseModal}
                openModal={selectedPersona || openNewModal}
                existingPersona={selectedPersona}
            />
            <div className={`w-full relative  flex justify-center ${postsContext.loadingPosts ? 'pt-20' : 'pt-0'} transition-all duration-200 px-14 pb-4`}>
                <PageHeader ctaTitle={'Create Persona'} selectedLayoutView={selectedLayoutView} handleCTA={onAddNewPersona} menuProps={menuProps} />

                <Spin spinning={personaContext.loadingPersonas}>
                    <div className="mt-16 h-scren overflow-y-scroll w-full pb-20 flex flex-wrap" >
                        {personaContext.personas?.map((persona, index) =>
                            <PersonaItem
                                onSelect={() => onSelectPersona(persona)}
                                persona={persona}
                                index={index}
                            />
                        )}
                    </div>
                </Spin>
            </div>

        </div>
    </>
}



const PersonaItem = ({ persona, onSelect, index }) => {
    return <div onClick={onSelect} className={`w-4/12 pt-2  px-3 cursor-pointer  h-40 my-6`}>
        <div className="px-4 border border-gray-200 bg-gray-50 relative h-40 ">
            <div className="flex flex-col gap-y-2 px-4 py-3">
                <Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />
                <span className="font">{persona.name}</span>
            </div>

            <div className="absolute bottom-2 right-1"><Tag color="cyan" className="rounded-lg">Active </Tag></div>
        </div>
    </div>


}

const PersonaColumnItem = ({ title, description, color }) => {
    return <div className="block text-start mt-0 p-3  border-b my-5">
        <span className={`bg-gray-100 rounded-2xl p-1 ${color}`}>{title}</span>
        <div className="mt-3 px-1">
            {description}
        </div>
    </div>
}
export default Persona
