import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import ErrorBoundary from "./components/Common/ErrorBoundary";
import Login from "./containers/login";
import AppContainer from "./containers/app.tsx";
import CompleteInvite from "./containers/complete_invite";
import Register from "./containers/register";
import { RequireAuth } from "./components/Auth/RequireAuth.tsx";
import { AuthProvider } from "./components/Auth/AuthContext.tsx";
import { IsAlreadyAuth } from "./components/Auth/IsAlreadyAuth.tsx";
import TeamManagement from "./components/Team";

import Settings from "./components/Settings/index.tsx";
import Finance from "./components/Finance/index.tsx";
import ResetPage from "./containers/reset";
import NotFound from "./components/NotFound/index.tsx";
import Personas from "./components/Personas/index.tsx";
import AddTwitter from "./containers/standalones/addtwtter.tsx";
import Channels from "./components/Channels/index.tsx";
import { SelectAccount } from "./containers/onboarding/select_account";
import { Onboarding } from "./containers/onboarding";
import { IsAlreadyOnboarded } from "./components/Auth/IsAlreadyOnboarded.tsx";

import ComponentSelector from "./components/ComponentSelector.tsx";
import CreatorCampaigns from "./components/Campaigns/creator.tsx";
import BrandCampaigns from "./components/Campaigns/brand.tsx";
import CreatorOverview from "./components/Overview/creator.tsx";
import BrandOverview from "./components/Overview/brand.tsx";

const router = createBrowserRouter([
  {
    path: "/register",
    element: (
      <AuthProvider>
        <IsAlreadyAuth>
          <Register />
        </IsAlreadyAuth>
      </AuthProvider>
    ),
  },
  {
    path: "/login",
    element: (
      <AuthProvider>
        <IsAlreadyAuth>
          <Login />
        </IsAlreadyAuth>
      </AuthProvider>
    ),
  },
  {
    path: "/reset",
    element: (
      <AuthProvider>
        <ResetPage />
      </AuthProvider>
    ),
  },
  {
    path: "/complete_invite/:invite_code",
    element: <CompleteInvite />,
  },
  {
    path: "/addchannel",
    children: [
      {
        path: "twitter",
        element: <AddTwitter />,
      },
    ]
  },
  {
    path: "/onboarding",
    element: (
      <AuthProvider>
        <RequireAuth>
          <IsAlreadyOnboarded>
            <ErrorBoundary isFullScreen>
              <Onboarding />
            </ErrorBoundary>
          </IsAlreadyOnboarded>
        </RequireAuth>
      </AuthProvider>
    ),
    children: [
      {
        path: "",
        element: <Navigate replace to="select_account" />,
      },
      {
        path: "select_account",
        element: <SelectAccount />
      },
    ]
  },
  {
    path: "/",
    element: (
      <AuthProvider>
        <RequireAuth>
          <ErrorBoundary isFullScreen>
            <AppContainer />
          </ErrorBoundary>
        </RequireAuth>
      </AuthProvider>
    ),
    children: [
      {
        path: "",
        element: <Navigate replace to="overview" />,
      },
      {
        path: "overview",
        element: <ComponentSelector
          brandCampaign={<BrandOverview />}
          creatorCampaign={<CreatorOverview />}
        />

      },
      {
        path: "campaigns",
        element: <ComponentSelector
          brandCampaign={<BrandCampaigns />}
          creatorCampaign={<CreatorCampaigns />}
        />
      },
      {
        path: "personas",
        element: <Personas />
      },
      {
        path: "channels",
        element: <Channels />
      },

      {
        path: "finances",
        element: <Finance />,
      },
      {
        path: "usersettings",
        element: (
          <div className="w-full">
            <Settings />
          </div>
        ),
      },
      {
        path: "communications",
        element: <NotFound title="Coming soon"
          description="Communicate with  your fans and customers instantly through email and text"
        />,
      },
      {
        path: "analytics",
        element: <NotFound title="Coming soon"
          description="Get product insights, understand where your customers are coming from and the products your customer are looking for  "
        />,
      },
      {
        path: "team",
        element: (
          <div className="w-full">
            <TeamManagement />
          </div>
        ),
      },
      {
        path: "*",
        element: <NotFound title="404"
          description="Hi there, there is nothing to see on this page, click on overview to get back on your dashboard"
        />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound title="404"
      description="Hi there, there is nothing to see on this page, click on overview to get back on your dashboard"
    />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
