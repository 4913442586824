import { Icon } from "@iconify/react"
import { Avatar, Dropdown, Form, Input, Radio, Select, Spin, Tag, message } from "antd"
import React, { useEffect, useState } from "react"
import { MButton } from "../Common/buttons.js"
import { IPersona } from "../../interface/ipersona.ts"
import usePersonaContext from "../../contexts/PersonaContext/UsePersonaHook.tsx"
import { LoadingOutlined } from "@ant-design/icons";
import { kFormatter } from "../../utils/index.js"
import { Str } from "../../utils/constants.js"
import useInfleuncerContext from "../../contexts/InfluencerContext/InfluencerListHook.tsx"
import { IInfluencer } from "../../interface/influencer.js"



export const ContentPreview = ({ campaignType = 'twitter_post' }) => {

    return <div className=" lg:mb-0 w-full  h-[600px]  py-2">
        <div>
            <span>Preview</span>
        </div>

        <div className="mt-8 flex items-start gap-x-1">
            <div className="flex gap-x-3 items-center">
                <Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel`} />
            </div>
            <div className="flex flex-col gap-y-2">
                <div className="flex gap-x-3 items-center">
                    <span className="text-sm">Ayobami</span>
                    <span className="text-gray-400 text-xs">@ayobamiplexbay</span>
                    <span className="text-gray-400 text-xs">119m</span>
                </div>
                <span>
                    This is a preview to show how your campaign post will
                    look when the influencers post the campaign.
                </span>

                <div className="flex gap-x-2 items-center h-20">
                    <img src="/preview_img_one.svg" className="h-20" />
                    <img src="/preview_img_one.svg" className="h-20" />
                </div>
            </div>

        </div>
    </div >

}



