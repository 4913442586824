import React, { useEffect, useState } from "react"
import { MButton } from "../Common/buttons"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Button, Input, List, Modal, Select, Spin, Tabs, message } from "antd"
import { ArrowLeftOutlined, ShoppingFilled, ShopFilled } from "@ant-design/icons";
import { makeClientSideApiRequest, makePostRequest, makePutRequest, ngnMoneyFormatter } from "../../utils";
import { onLoadPageInfo } from "../../services/load_page_data.ts";
import { IStoryInfo, IStoryStore } from "../../interface/istoryinfo";
import TransactionHistory from "./TransactionHistory.tsx";
import { IUser } from "../../interface/iuser";
import { ITransaction } from "../../interface/itransaction";
import { isMobile } from "react-device-detect";
import { IPostBatch } from "../../interface/ipostbatch";
import MainLayout from "../Common/Layout.tsx";
import { Icon } from "@iconify/react";
import dayjs from "dayjs";
import useAuth from "../Auth/UseAuthHook.tsx";
import { WithdrawMoneyDrawer } from "./WithdrawMoneyDrawer.tsx";



const Finance = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const user = useAuth()
  const [transactions, setTransaction] = useState<IUser | undefined>([]);

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);


  console.log(user.user);

  useEffect(() => {
    loadPageData()
  }, [])


  const loadPageData = () => {
    onLoadPageInfo({
      path: `/api/ai/transactions`,
      onStartLoad: function (): void {
        setLoading(true);
      },
      onSuccess: function (data: any): void {
        setLoading(false);
        console.log(data.data);

        setTransaction(data.data);
      },
      onFail: function (msg: string): void {
        setLoading(false);
        message.error(msg);
      }
    })
  }

  /*   const onUpdateSettings = async () => {
      setLoading(true);
      try {
        const response = await makePutRequest(`/api/writestack/auth/updateUserInfo`, {
          ...data,
          story_id: params?.id
        });
        if (response.status === "success") {
          setLoading(false);
          message.success('Store name updated')
        } else {
          message.error(response.reason);
          setLoading(false);
        }
      } catch (error) {
        message.error(error.message);
        setLoading(false);
      }
    }; */

  const onWithdrawMoney = () => {
    // message.info(`Minimum withdrawal amount is ${ngnMoneyFormatter.format(2000)}`)
    setOpenDrawer(true)
  }

  const closeWithdrawMoney = (needsRefresh) => {
    if (needsRefresh) {
      loadPageData()
    }
    setOpenDrawer(false)
  }

  function onInputData(arg0: string, value: string): void {
    throw new Error("Function not implemented.");
  }

  return <>
    <WithdrawMoneyDrawer
      account_details={user.user?.account_details}
      openDrawer={openDrawer}
      onCloseDrawer={closeWithdrawMoney}
      balance={user.user?.finance?.balance}
      fullname={user.user?.fullname}
    />
    <MainLayout title="Finance" customHeader={undefined} >
      <div className="flex lg:flex-row flex-col items-start gap-x-6 my-7 px-4 w-full gap-y-6">

        <div className="lg:w-4/12 w-full">
          <div className=" bg-gray-100 flex flex-col rounded-md px-4 pt-4 h-44">
            <span className="text-sm">Available balance</span>
            <span className="text-2xl pt-4 font-semibold">{ngnMoneyFormatter.format(user.user?.finance?.balance)}</span>

            <div className="mt-6">
              <MButton
                label={'Withdraw  money'}
                onClick={onWithdrawMoney}
                loading={undefined}
                icon={undefined}
                iconColor={undefined}
                className={undefined}
                disabled={undefined}
                ref={undefined}
              />
            </div>

          </div>
        </div>


        <div className="lg:w-8/12 w-full flex  flex-col justify-between   rounded-md h-28">

          <div className="w-full flex justify-between gap-x-3">
            <div className="bg-green-100 w-6/12  flex flex-col  h-28 p-4 rounded-md">
              <span className="text-sm"> Amount In </span>
              <span className="text-2xl pt-4 font-semibold">{ngnMoneyFormatter.format(user.user?.finance?.totalDeposits)}</span>
            </div>
            <div className="bg-red-100 w-6/12 flex flex-col   h-28 p-4 rounded-md">
              <span className="text-sm"> Amount Out  </span>
              <span className="text-2xl pt-4 font-semibold">{ngnMoneyFormatter.format(user.user?.finance?.totalSpends)}</span>

            </div>

          </div>


          <div className="w-full  overflow-scrol bg-gray-50 p-2 px-3 rounded-lg mt-6 ">
            <Spin className="w-full  " style={{ width: '100%' }} spinning={loading}>
              <div className="flex justify-between p-2">
                <span>History</span>
{/*                 <span className="text-blue-800 font-semibold cursor-pointer">View all</span>
 */}              </div>
              <List
                grid={{ column: isMobile ? 1 : 2, gutter: 16 }}
                pagination={{ pageSize: isMobile ? 3 : 6 }}
                dataSource={transactions ?? []}
                className="w-full lg:h-[400px] lg:pb-0 pb-14 px-4"
                renderItem={(item, index) => {
                  return (
                    <TransactionHistory key={index} item={item} />
                  )
                }}
              />
            </Spin>
          </div>
        </div>


      </div>
    </MainLayout>
  </>
}

export default Finance






