
import { Helmet } from "react-helmet";


import React, { useCallback, useEffect, useState } from "react";

import useAuth from "../components/Auth/UseAuthHook.tsx";
import { Str } from "../utils/constants.js";
import { PersonaProvider } from "../contexts/PersonaContext/PersonaContext.tsx";
import { PostsProvider } from "../contexts/PostContext/PostContext.tsx";
import { AppBody } from "../components/App/AppBody.tsx";
import { PostChannelsProvider } from "../contexts/PostChannelsContext/PostChannelsContext.tsx";
import { PlatformsProvider } from "../contexts/PlatformContext/PlatformsContext.tsx";
import { TourProvider } from "../contexts/TourContext/TourContext.tsx";
import { makeClientSideApiRequest } from "../utils/index.js";
import { Spin, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { SocialChannelModal } from "../components/Channels/SocialChannelModal.tsx";
import { InfluencerProvider } from "../contexts/InfluencerContext/InfluencerContext.tsx";
import { WaitListModal } from "../components/Common/WaitlistModal.tsx";
import { BrandProvider } from "../contexts/BrandContext/BrandContext.tsx";
import { BrandApp } from "../components/App/BrandApp.tsx";
import { CreatorApp } from "../components/App/CreatorApp.tsx";
import ComponentSelector from "../components/ComponentSelector.tsx";



export default function AppContainer() {
  const nav = useNavigate()
  const authContext = useAuth();
  const [openConnectModal, setOpenConnectModal] = useState(false);
  const [openWaitlistModal, setOpenWaitlistModal] = useState(false);
  const location = useLocation();


  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (authContext.user) {
      const { account_type } = authContext.user
      console.log(account_type);
      if (!account_type) {
        nav('/onboarding')
      }
    }
  }, [authContext.loading, authContext.user, nav])


  useEffect(() => {
    console.log(location.pathname);

    checkOnboarding()
  }, [authContext.user])


  let checkOnboarding = async () => {

    if (authContext.user) {
      const { account_access = undefined, onboarding_status = undefined, account_type = undefined } = authContext.user || {}
      if (account_access === 0) {
        setOpenWaitlistModal(true)
      } else if (account_type === 'creator') {
        if (onboarding_status === 'connect_social') {
          setOpenConnectModal(true)
        }
      } else if (account_type === 'brand') {
        if (onboarding_status === 'connect_social') {
          setOpenConnectModal(true)
        }
      }
    }
  }


  const navItems = [
    {
      title: "Overview",
      icon: 'material-symbols-light:overview-outline',
      to: Str.paths.overview,
      bottom: false,
    },
    {
      title: "Campaigns",
      icon: 'material-symbols:campaign',
      to: Str.paths.campaigns,
      bottom: false,
    },
    {
      title: "Finance",
      icon: 'circum:dollar',
      bottom: false,
      to: Str.paths.finance,
    },
    {
      title: "Settings",
      icon: 'solar:settings-outline',
      bottom: false,
      to: Str.paths.settings,
    },
    /*     {
          title: "Awareness",
          icon: 'fluent-mdl2:mail-schedule',
          to: Str.paths.settings,
        },
        {
          title: "Analytics",
          icon: 'mdi:analytics',
          to: Str.paths.settings,
        },
    
        {
          title: "Billing",
          icon: 'majesticons:creditcard',
          to: Str.paths.settings,
        },
        {
          title: "Support",
          icon: 'mdi:support',
          to: Str.paths.settings,
        }, */
    {
      title: "Logout",
      icon: 'solar:logout-3-bold',
      bottom: true,
      onClick: () => authContext?.signOut(() => { })
    },

  ];

  const closeSocialConnectModal = () => {
    setOpenConnectModal(false)
  }

  return (
    <>

      <PersonaProvider>
        <PostsProvider>
          {/*         <PostsQueueProvider> */}
          <PostChannelsProvider>
            <PlatformsProvider>
              <TourProvider>
                <InfluencerProvider>
                  <>
                    {/*     <SocialChannelModal
                    platform_id={'1'}
                    isActivateSocial={openConnectModal}
                    setIsActiveSocial={closeSocialConnectModal}
                  /> */}

                    <WaitListModal
                      isActivateSocial={openWaitlistModal}
                    />
                    <Helmet>
                      <meta charSet="utf-8" />
                      <title>CreatorBase</title>
                      <meta name="description" content="CreatorBase" />
                      <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                      />


                      <link rel="icon" href="/quill-pen.png" />
                      <link rel="canonical" href="https://app.creatorbase.co/connect" />
                    </Helmet>
                    { }
                    {!authContext.user && <div className="flex items-center justify-center">  <Spin className="h-screen flex flex-col items-center justify-center" spinning={true} /> </div>}
                    {authContext.user && authContext.user.account_type &&
                      <BrandProvider>
                        <ComponentSelector creatorCampaign={<CreatorApp navItems={navItems} />} brandCampaign={<BrandApp navItems={navItems} />} />
                      </BrandProvider>
                    }


                  </>
                </InfluencerProvider>
              </TourProvider>
            </PlatformsProvider>
          </PostChannelsProvider>
          {/*         </PostsQueueProvider> */}
        </PostsProvider>
      </PersonaProvider>
    </>
  );
}
