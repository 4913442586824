import { Avatar } from "antd";
import React from "react";

export const StatusChip = ({ text, edge = false }) => {
    return (
        <div className={`cursor-pointer`}>
            <span className={`text-xs px-2 p-1 ${edge ? 'rounded-md' : 'rounded-3xl'} ${text?.toLowerCase() === 'queued' ? 'bg-green-200' : 'bg-purple-200'} p-1`}>{text}</span>
        </div>
    );
};
