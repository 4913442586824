// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCajfDo2xDYTRMAuyZQ-K8sN4t1pI0xCgA",
    authDomain: "writestack-18130.firebaseapp.com",
    projectId: "writestack-18130",
    storageBucket: "writestack-18130.appspot.com",
    messagingSenderId: "442418756074",
    appId: "1:442418756074:web:7f01f10115f237d001da93",
    measurementId: "G-CC4BVM8Q7P"
};

const app = initializeApp(firebaseConfig);

const initAnalytics = (args: any) => {
    if (typeof window !== "undefined") {
        return getAnalytics(args);
    } else {
        return null;
    }
};
const analytics = initAnalytics(app);

export { analytics, app };
