import React, { useEffect, useState } from "react"
import { Avatar, Button, Drawer, Dropdown, Input, Space, Steps, message } from "antd";

import { SocialStep } from "./SocialAccounts.tsx";
import { SetPriceAnNiche } from "./SetPriceAnNiche.tsx";
import { SetPaymentInfo } from "./SetPaymentInfo.tsx";
import useAuth from "../../Auth/UseAuthHook.tsx";
import useBrandDashboardContext from "../../../contexts/BrandContext/BrandHook.tsx";


export const CreatorOnboardingDrawer = (props: { openDrawer, closeDrawer }) => {
    const [current, setCurrent] = useState(1);
    const authContext = useAuth()
    const brandDashboardContext = useBrandDashboardContext()
    
    const onChangeStep = (step: number) => {
        if (step === 4) {
            onboardingComplete()
        } else {
            refreshAndSetNext(step)
        }
    }

    useEffect(() => {
        const { onboarding_status = undefined } = authContext.user!
        if (onboarding_status === 'connect_social' && current !== 1) {
            setCurrent(1)
        } else if (onboarding_status === 'set_niche' && current !== 2) {
            setCurrent(2)
        } else if (onboarding_status === 'set_payment_info' && current !== 3) {
            setCurrent(3)
        }

    }, [authContext.user, current])

    const onboardingComplete = async () => {
        await authContext.refreshUser()
        
        message.info('Onboarding complete')
    }

    const refreshAndSetNext = async (step) => {
        await authContext.refreshUser()
        await brandDashboardContext.getDashboard()
        setCurrent(step);
    }

    return <Drawer
        placement={'top'}
        onClose={() => props.closeDrawer()}
        closeIcon={null}
        height={600}
        open={props.openDrawer}
        closable={false}
        maskClosable={false}
    >
        <div className="w-full lg:w-6/12 mx-auto">
            <div className="flex justify-between items-center w-full mt-1 mb-8">
                <span className="text-center block w-full">Complete your onboarding</span>
            </div>
            <Step current={current} />
            {current === 1 && <SocialStep onChangeStep={onChangeStep} />}
            {current === 2 && <SetPriceAnNiche onChangeStep={onChangeStep} />}
            {current === 3 && <SetPaymentInfo onChangeStep={onChangeStep} />}
        </div>

    </Drawer>
}


const Step = ({ current }) => {
    return <div className="flex justify-center items-center w-full">

        <StepItem isActive={current === 1} title={'Social Accounts'} />
        <StepItem isActive={current === 2} title={'Set Niches'} />
        <StepItem isActive={current === 3} title={'Payment'} />

    </div>
}


const StepItem = ({ title, isActive }) => {
    return <div className={`flex-shrink-0 w-4/12 flex justify-center rounded px-3 py-1 ${isActive ? 'bg-purple-100 rounded-2xl font-semibold' : ''}`}>
        <span className="text-center text-xs lg:text-sm">{title}</span>
    </div>
}

