import { fetchUserOrDie, makePostRequest, setUserInfo } from "../utils";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { MButton } from "../components/Common/buttons";
import { logEvent } from "firebase/analytics";
import { analytics } from "../utils/firebaseutil.ts";

export default function ResetPage() {
  const [loading, setLoading] = useState(false);
  const [isResetRequested, setResetRequested] = useState(false);
  const [done, setDone] = useState(false);

  const [data, setData] = useState({
    email: undefined,
    password: undefined,
    otp: undefined,
  });

  useEffect(() => {
    logEvent(analytics, "page_visited", {
      content_type: "app_page",
      content_id: "reset_page",
    });
  }, []);

  const onDoReset = async () => {
    setLoading(true);
    try {
      const response = await makePostRequest("/api/auth/doResetPassword", {
        email: data.email,
        otp: data.otp,
        password: data.password,
        is_write_stack: true
      });

      if (response.status == "success") {
        setLoading(false);
        message.success("Reset password success");
        window.location = "/login";
      } else {
        message.error(response.reason);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const onRequestResetCode = async () => {
    setLoading(true);
    try {
      const response = await makePostRequest("/api/auth/requestResetOtp", {
        email: data.email,
      });

      if (response.status == "success") {
        setLoading(false);
        setResetRequested(true);
        setLoading(false);
        message.success("Reset code requested");
      } else {
        message.error(response.reason);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const onSubmitReq = async () => {
    if (!data.email) {
      message.error("Enter a valid email address");
      return;
    } else if (isResetRequested) {
      if (!data.otp) {
        message.error("Enter email OTP");
      } else {
        await onDoReset();
      }
      return;
    } else {
      await onRequestResetCode();
    }
  };

  const onInputData = (type, text) => {
    switch (type) {
      case "email":
        data.email = text;
        break;
      case "password":
        data.password = text;
        break;
      case "otp":
        data.otp = text;
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CreatorBase | Reset Password</title>
        <meta name="description" content="CreatorBase" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        <link rel="icon" href="/quill-pen.png" />
        <link rel="canonical" href="https://app.sequelbase.com/connect" />
      </Helmet>
      <div className="bg-white">
        <main className="h-screen bg-[#f3f3f1] p-3 mx-auto p-3 w-full lg:w-6/12">
          <div className="">
            <a href="/" className="h-16 block  bg-white pt-1 pl-1 ">
              <img
                src={"/quill-pen.png"}
                alt="Flight"
                className="inline w-8"
                width={150}
                height={10}
              />{" "}
            </a>
          </div>
          <div className="	z-10 mt-4 w-full p-4 ">
            <div className="p-3 font-black text-black">
              <span className="text-2xl">Reset password</span>
            </div>

            {/* <div className="p-4 mt-6">
            <Checkbox
              value={isAlreadyHaveAccount}
              onChange={toggleAccountSignup}
            >
              I already have an account
            </Checkbox>
          </div> */}
            {done && (
              <div className="px-4 relative mt-4">
                <div className="flex justify-center items-center">
                  <img
                    src={"/check.png"}
                    alt="Flight"
                    className="inline"
                    width={40}
                    height={10}
                  />{" "}
                </div>
                <h1 className=" mt-8 text-gray-800 text-center font-bold text-2xl">
                  One time login link sent!
                </h1>

                <h1 className="mt-4 text-gray-600 text-center font-thin text-sm">
                  If this email exists, we have sent you a one time login link.
                  Tap on the link sent to your email to login.
                </h1>
              </div>
            )}

            <div className="px-4 relative mt-4">
              <h1 className=" text-gray-800 font-bold text-xs">
                Email address
              </h1>
              <Input
                name={"email_add"}
                onChange={(val) => onInputData("email", val.target.value)}
                placeholder="email@example.com"
                className={`h-12 text-cyan-800 font-bold bg-white border-cyan-500 border-2 my-3`}
              />
            </div>

            {isResetRequested && (
              <div className="px-4 relative mt-4">
                <h1 className=" text-gray-800 font-bold text-xs">
                  Enter OTP sent to your email
                </h1>
                <Input
                  name={"otp"}
                  type="number"
                  onChange={(val) => onInputData("otp", val.target.value)}
                  placeholder="********"
                  className={`h-12 text-cyan-800 font-bold bg-white border-cyan-500 border-2 my-3`}
                />
              </div>
            )}

            {isResetRequested && (
              <div className="px-4 relative mt-4">
                <h1 className=" text-gray-800 font-bold text-xs">Password</h1>
                <Input
                  name={"email_add"}
                  type="password"
                  onChange={(val) => onInputData("password", val.target.value)}
                  placeholder="********"
                  className={`h-12 text-cyan-800 font-bold bg-white border-cyan-500 border-2 my-3`}
                />
              </div>
            )}

            {!done && (
              <MButton
                onClick={onSubmitReq}
                loading={loading}
                title={"Continue"}
              />
            )}
            {done && (
              <Link to="/">
                <MButton
                  onClick={() => (window.location = "/")}
                  loading={false}
                  title={"Go home"}
                />
              </Link>
            )}
          </div>
        </main>
      </div>
    </>
  );
}
