import { useEffect, useState } from "react";

import { Radio, Space, Spin, Steps, message } from "antd";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { HTMLHead } from "../../components/Common/HTMLHead";
import { makeClientSideApiRequest, makePostRequest } from "../../utils";
import { MButton } from "../../components/Common/buttons";
import useAuth from "../../components/Auth/UseAuthHook.tsx";
import { LoadingOutlined } from "@ant-design/icons";

const steps = [
    {
        title: <span className="text-white text-xs lg:text-lg">Select account</span>,
        type: ['creator', 'brand']
    },
    {
        title: <span className="text-white text-xs lg:text-lg">Connect Social</span>,
        type: ['creator', 'brand']
    },
    {
        title: <span className="text-white text-xs lg:text-lg"> Onboard</span>,
        type: ['creator', 'brand']
    },
];


export const SelectAccount = () => {
    const [accountType, setAccountType] = useState('creator');
    const [loading, setLoading] = useState(false);
    const authContext = useAuth()

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setAccountType(e.target.value);
    };

    const nav = useNavigate()

    useEffect(() => {
        if (authContext.user?.account_type ) {
            console.log('df');
            window.location.href = '/'
        }
    }, [authContext.user, nav])

    const onAccountSelected = async () => {
        setLoading(true)
        try {
            const response = await makePostRequest("/api/onboarding/updateAccountType", {
                account_type: accountType
            });
            if (response && response.status === "success") {
                await authContext.refreshUser()
                console.log(authContext.user);
            } else {
                message.info(response.reason)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            message.error(error.message);
        }
    };

    return (
        <>
            <div className="lg:w-4/12 w-full px-4 mx-auto ">
                <div>
                    <span className="text-2xl text-black font-bold">Select account type</span>
                </div>
                <Space direction="vertical">
                    <div className="flex flex-col items-start gap-y-10 py-10">
                        <Radio.Group onChange={onChange} value={accountType}>
                            <div className="flex flex-col gap-y-12">
                                <Radio.Button style={{ height: 'auto' }} className="rounded" value="creator">
                                    <div className="flex flex-col">
                                        <span className="font-bold text-black">Creator</span>
                                        <span className="font-normal text-black">
                                            Account for creator and influencer that want to connect with brands, help brands to boost product awareness using social media, and get paid for it.
                                        </span>
                                    </div>
                                </Radio.Button>
                                <Radio.Button style={{ height: 'auto' }} className="rounded transition-all duration-100" value="brand">
                                    <div className="flex flex-col">
                                        <span className="font-bold text-black">Brand</span>
                                        <span className="font-normal text-black">Account for businesses and brands that would like to advertise their products using influencer marketing campaigns </span>
                                    </div>
                                </Radio.Button>

                            </div>
                        </Radio.Group>
                        <MButton
                            iconColor={'text-white'}
                            className={"w-full"}
                            loading={loading}
                            onClick={onAccountSelected}
                            label={'Continue'}
                        />
                    </div>

                </Space>
            </div>
        </>
    );
}
