import React, { useEffect, useState } from "react";
import { MButton } from "../Common/buttons";
import { CaretRightOutlined } from "@ant-design/icons";
import { makeClientSideApiRequest } from "../../utils";
import { Avatar, Button, Card, Spin, Tag, message } from "antd";
import TeamInfoModal from "./TeamInfoModal/team_info_modal";
import ViewTeamInfoModal from "./TeamInfoModal/view_team_info_modal";
import { useNavigate } from "react-router-dom";
import { Str } from "../../utils/constants";

const SavedTeamMembers = ({ teamMembers, setTeamMembers }) => {
  const [loading, setLoading] = useState(true);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [selectedMemberInfo, setSelectedMemberInfo] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    loadTeamMembers();
  }, []);

  const loadTeamMembers = async () => {
    try {
      setLoading(true);
      const response = await makeClientSideApiRequest(`/api/teams`);
      if (!response.data) {
        setTeamMembers(undefined);
        message.warning(response.reason);
        return;
      } else if (response.data.data?.length > 0) {
        setTeamMembers(response.data.data);
      } else {
        setTeamMembers(undefined);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setTeamMembers(undefined);
    }
  };

  const toggleInvite = async (status, refresh) => {
    setOpenInviteModal(status);
    if (refresh) await loadTeamMembers();
  };

  return (
    <>
      {selectedMemberInfo && (
        <ViewTeamInfoModal
          onToggleModal={(refresh) => {
            setSelectedMemberInfo(undefined);
            if (refresh) loadTeamMembers();
          }}
          openModal={selectedMemberInfo}
          memberInfo={selectedMemberInfo}
        />
      )}

      <TeamInfoModal onToggleModal={toggleInvite} openModal={openInviteModal} />

      <div className="flex justify-between  px-16 mt-10 items-center border-b pb-6 border-b-gray-100">
        <div className="flex flex-col">
          <span className=" font-bold text-2xl"> Team members</span>
          <span className="text-gray-500">
            Add, update and remove team members
          </span>
        </div>

        <div className="flex items-center gap-x-[0.9px]">
          <MButton
            loading={false}
            onClick={() => { navigate(`${Str.paths.store}/story_info`) }}
            label={"Invite team members"}
            icon={undefined}
            className={undefined}
            disabled={undefined}
          />
        </div>
      </div>

      {(!teamMembers || teamMembers?.length < 1) && (
        <div className="flex justify-start flex-wrap w-full px-12 pt-9">
          {[1, 2, 3, 4, 5].map((overviewItems) => {
            return (
              <div className="w-4/12 px-4 py-6">
                <Card loading={true} className=" border shadow-sm rounded-2xl h-40">
                  <div className="flex justify-between"></div>
                </Card>
              </div>
            );
          })}
        </div>
      )}

      {teamMembers && (
        <div className="w-full mx-auto ">

          {teamMembers && <div className="flex  flex-wrap	gap-y-4 px-11  text-sm mt-14">
            {teamMembers?.slice(0, 12).map((teamMemberItem, index) => (
              <div className="h-40 px-4 w-4/12">


                <Card onClick={() => setSelectedMemberInfo(teamMemberItem)}
                  key={teamMemberItem.id} loading={false} className=" h-full border shadow-sm rounded-2xl">
                  <div className="flex flex-col relative">
                    <div className="flex items-center">
                      <Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />
                      <div className="flex flex-col">
                        <span className="font-bold">{teamMemberItem.fullname}</span>
                      </div>
                    </div>
                    <div className="px-8 py-1 h-16">
                      <span className="text-gray-600">{teamMemberItem?.email} </span>
                    </div>
                    <div className="pl-6 flex items-center justify-between">
                      <Tag className={`rounded-2xl h-6 max-w-6/12 text-center ${teamMemberItem?.is_activated === 1 ? 'bg-green-100 border-green-400' : '  border-orange-400 bg-orange-100 '}  border-2`}>{teamMemberItem?.is_activated === 1 ? 'Activated' : 'Invite sent'} </Tag>
                      <Button
                        loading={loading}
                        disabled={false}
                        onClick={() => setSelectedMemberInfo(teamMemberItem)}
                        type='text'
                        className={'py-2 flex items-center text-xs'}
                      >
                        <span>Manage user</span>
                        <CaretRightOutlined className="text-sm" />
                      </Button>
                    </div>
                  </div>

                </Card>
              </div>
            ))}
          </div>
          }
        </div>

      )}
    </>
  );
};

export default SavedTeamMembers;
