import { useEffect, useState } from "react";

import { Radio, Space, Spin, Steps, message } from "antd";
import { Helmet } from "react-helmet";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { HTMLHead } from "../../components/Common/HTMLHead";
import { makeClientSideApiRequest } from "../../utils";
import { MButton } from "../../components/Common/buttons";
import { Icon } from "@iconify/react";
import useAuth from "../../components/Auth/UseAuthHook.tsx";

const steps = [
    {
        title: <span className="text-white text-xs lg:text-lg">Select account</span>,
        type: ['creator', 'brand']
    },
    {
        title: <span className="text-white text-xs lg:text-lg">Connect Social</span>,
        type: ['creator', 'brand']
    },
    {
        title: <span className="text-white text-xs lg:text-lg"> Onboard</span>,
        type: ['creator', 'brand']
    },
];


export const Onboarding = () => {
    const [accountType, setAccountType] = useState('creator');
    const authContext = useAuth()
    const [loading, setLoading] = useState(false);

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setAccountType(e.target.value);
    };

    useEffect(() => {
        //   loadPage();
    }, []);

    const nav = useNavigate()



    const [current, setCurrent] = useState(0);
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const items = steps.filter((st) => st.type.includes(accountType)).map((item) => ({
        key: item.title,
        title: item.title,
    }));

    return (
        <>
            <HTMLHead title={'Select account type'} />
            <main className="h-screen bg-white">
                <div className=" h-16 bg-[#4131F7] mb-4 relative">
                    <div className="mx-auto lg:w-6/12 py-3 lg:px-5 px-2 flex flex-col ">
                        <div className="lg:px-14  w-full  text-center mt-0 flex h-10  gap-x-3 justify-between items-center">
                            <div className="flex items-center gap-x-2">
                                <img className="w-6 h-6" src="/logo.png" />
                                <span className="font-bold text-2xl text-white">CreatorBase</span>
                            </div>

                            <Icon onClick={() => authContext.signOut(() => { nav('/login') })} className="text-white text-2xl" icon={'humbleicons:logout'} />
                        </div>
                        <div className="absolute bottom-1 left-0 right-0 lg:px-20 ">
                            <div className="mx-auto  w-full lg:w-5/12 py-3 lg:px-0 px-3">
{/*                                 <Steps style={{ color: 'white' }} size="small" responsive={false} labelPlacement="horizontal" className="text-white" current={current} items={items} />
 */}                            </div>
                        </div>
                    </div>
                </div>
                <Outlet />
            </main >
        </>
    );
}
