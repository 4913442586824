import { Helmet } from "react-helmet"

export const HTMLHead = ({ title }) => {
    return <Helmet>
        <meta charSet="utf-8" />
        <title>CreatorBase | {title}</title>
        <meta name="description" content="Sequelbase" />
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        <link rel="icon" href="/database_logo_smaller.png" />
        <link rel="canonical" href="https://app.sequelbase.com/connect" />
    </Helmet>
}