import { Icon } from "@iconify/react"
import { Modal, Input, message } from "antd"
import { MButton } from "../Common/buttons"
import React, { useState } from "react"
import { makePostRequest } from "../../utils"

export const EmailChannelModal = ({ isActivateEmail, setIsActivateEmail }) => {
    const [loading, setLoading] = useState(false);
    const [senderName, setSenderName] = useState<string | undefined>(undefined);
    const [senderEmail, setSenderEmail] = useState<string | undefined>(undefined);

    const onAddChannel = async () => {
        if (!senderName || !senderEmail) message.warning('Sender email and name is required')
        setLoading(true);
        const response = await makePostRequest("/api/ai/completeChannelIntegration", {
            platform_id: 3,
            sender_name: senderName,
            sender_email: senderEmail,
        });

        if (response.status === "success") {
            message.success("Added channel successfully");
            setLoading(false);
            window.location.href = '/channels'
        } else {
            setLoading(false);
            message.error(response.reason);
        }
    }




    return <Modal
        open={isActivateEmail}
        onCancel={() => setIsActivateEmail(false)}
        footer={null}
        closeIcon={null}
        className="shadow-none border-none"
        destroyOnClose={true}
    >

        <div className="flex flex-col gap-y-5 bg-white py-4 rounded px-4">

            <div className="flex justify-between items-center">
                <span>Setup email channel</span>
                <Icon onClick={() => setIsActivateEmail(false)} className="text-lg text-gray-800 cursor-pointer" icon={'zondicons:close-solid'} />
            </div>
            <div>
                <div className="flex flex-col py-3">
                    <span className="text-gray-500 text-sm">Sender name</span>
                    <span className="text-xs text-gray-400">Sender name that your customers will see when they receive email from you </span>
                </div>
                <Input onChange={(ev) => setSenderName(ev.target.value)} placeholder="Business name" />
            </div>

            <div>
                <div className="flex flex-col py-3">
                    <span className="text-gray-500 text-sm">Sender email</span>
                    <span className="text-xs text-gray-400">Sender email that your customers will see when they receive email from you </span>
                </div>
                <Input onChange={(ev) => setSenderEmail(ev.target.value)} placeholder="help@businessname.com" />
            </div>

            <div className="flex justify-center items-center">
                <MButton iconColor={'text-white'}
                    onClick={onAddChannel}
                    icon={'icon-park-solid:setting'}
                    label={'Setup email  channel'}
                    loading={loading}
                    className={undefined}
                    disabled={undefined} ref={undefined} />
            </div>
        </div>

    </Modal>
}

