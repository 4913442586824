import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";

export const MButton = ({
  onClick,
  loading,
  label,
  icon,
  iconColor,
  className,
  disabled,
  type = "button",
  inverted = false,
  ref
}) => {
  return (
    <button
      type={type}
      disabled={loading || disabled}
      onClick={onClick}
      ref={ref}
      className={` transition-all duration-300 ${inverted ? 'bg-transparent text-primary lg:px-1' : 'bg-primary lg:px-3 text-white '} p-3  px-3 rounded-3xl ${className} ${disabled ? 'bg-gray-500' : ''}  hover:scale-105 transition-all duration-200`}
    >
      <div className="relative flex gap-x-2 text-center justify-center items-center">
        {loading && <LoadingOutlined color="white" />}
        {icon && !loading && <span> <Icon className={` ${iconColor ? iconColor : 'text-primary'} text-lg`} icon={icon} /></span>}
        <span className={`text-center  lg:text-sm text-xs ${iconColor ? iconColor : 'text-white'}`}> {label}</span>
        <span className="  bottom-[1px] right-0 ">

        </span>
      </div>
    </button>
  );
};

export const MButtonWhite = ({
  onClick,
  loading,
  label,
  icon,
  className,
  type = "text",
}) => {
  return (
    <button
      className={`text-black p-3 px-5 rounded-full ${className}  bg-white`}
    >
      {icon && icon}
      {label}
    </button>
  );
};
