
import React, { useEffect } from "react";
import { message } from "antd";
import { onLoadPageInfo } from "../../services/load_page_data.ts";
import { IInfluencer } from "../../interface/influencer.ts";
import useAuth from "../../components/Auth/UseAuthHook.tsx";
import { ICreatorDashboard } from "../../interface/creator_dashboard.ts";


export interface BrandContextType {
  brandDashboardInfo: Array<IInfluencer> | undefined;
  creatorDashboardInfo: ICreatorDashboard | undefined;
  loadingDashboard: boolean,
  getDashboard: () => Promise<void>,
}

export let BrandContext = React.createContext<BrandContextType>(null!);

export function BrandProvider({ children }: { children: React.ReactNode }) {

  const authContext = useAuth()
  let [brandDashboardInfo, setBrandDashboardInfo] = React.useState(undefined);
  let [creatorDashboardInfo, setCreatorDashboardInfo] = React.useState(undefined);
  let [loadingDashboard, setLoadingDashboard] = React.useState(false);


  useEffect(() => {
    getDashboard()
  }, [])

  /* 
    const _transFormData = (data) => {
      console.log(data);
  
      const transformedData = data.map((influencer) => {
        const allNiches = (influencer.channel_niche ?? []).map((nc) => nc.niche)
        const channel = influencer.channel.find((ch) => ch.platform_id == 1);
        const channelPrice = influencer.channel_price.find((ch) => ch.platform_id == 1);
  
        return {
          niches: allNiches,
          username: channel?.channel_name,
          name: influencer.company_name,
          company_id: influencer.id,
          price_per_post: channelPrice?.post_price,
  
          avatar: 'https://xsgames.co/randomusers/avatar.php?g=pixel&index=',
          bio: 'Nothing dies in my hands. I love to code and talk about startups.',
          followers: 440000,
        }
      })
  
      setInfluencers(transformedData);
      return transformedData;
    }
   */

  const getDashboard = async () => {
    onLoadPageInfo({
      path: `/api/ai/${authContext.user!.account_type}/dashboard`,
      onStartLoad: function (): void {
        setLoadingDashboard(true);
      },
      onSuccess: function (data: any): void {
        setLoadingDashboard(false);
        if (authContext.user!.account_type === 'creator') {
          setCreatorDashboardInfo(data)
        } else {
          setBrandDashboardInfo(data)
        }
      },
      onFail: function (msg: string): void {
        setLoadingDashboard(false);
        message.error(msg);
      }
    })
  }

  const value = { brandDashboardInfo, creatorDashboardInfo, getDashboard, loadingDashboard }

  return <BrandContext.Provider value={value}>{children}</BrandContext.Provider>;
}
