import React, { useEffect, useState } from "react";
import { MButton } from "../Common/buttons";
import { message } from "antd";


const NotFound = (props: { title: string, description: '', cta: (() => void) | undefined }) => {

  return (
    <div className="overflow-y-scroll h-full flex flex-col justify-center items-center  gap-y-6">
      <h1 className="text-black font-black text-3xl w-full text-center">{props.title}</h1>
      <img src="/404.gif" className="h-96" />
      <h1 className="text-black text-center  w-full lg:w-4/12 mx-auto lg:text-md text-sm">{props.description}</h1>
      {props.cta && <MButton
        loading={false}
        onClick={() => { message.warning('Insufficient balance') }}
        label={"Withdraw money"}
        icon={undefined}
        className={undefined}
        disabled={undefined}
      />}
    </div>
  );
};

export default NotFound;
