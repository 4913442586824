import React, { useEffect, useState } from "react"
import { Avatar, Button, Drawer, Dropdown, Input, Space, Steps, message } from "antd";


import useAuth from "../../Auth/UseAuthHook.tsx";
import { Icon } from "@iconify/react";
import { MButton } from "../../Common/buttons.js";
import { usePaystackPayment } from "react-paystack";
import { makePostRequest } from "../../../utils/index.js";

export const ProfileVerificationProductDrawer = (props: { openDrawer, closeDrawer }) => {
    const authContext = useAuth()
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<string | undefined>("123none");
    const [paymentRef, setPaymentRef] = useState<string | undefined>()


    useEffect(() => {
        if (paymentRef) {
            try {
                initializePayment(onSuccess, onClose)
            } catch (error) {
                message.error('Unable to initialize payment')
            }

        }
    }, [paymentRef])

    const config = {
        reference: paymentRef,
        email: authContext.user!.email,
        amount: 10000! * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: "pk_test_0bd51a9b53a2c80ead3d84d11b27e4f51659e5f5"
        //  publicKey: 'pk_live_9e5eb617e571c17f13bb79edec147f2dbe40bfe7',
    };

    const initializePayment = usePaystackPayment(config);

    // you can call this function anything
    const onSuccess = () => {
        completePayment()
    };

    // you can call this function anything
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log('closed')
    }

    const initiatePayment = () => {
        if (!data || data.length < 1) {
            message.error('Enter NIN to continue')
        } else {
            setPaymentRef((new Date()).getTime().toString())
        }
    }

    const completePayment = async () => {
        setLoading(true);
        try {
            const response = await makePostRequest(`/api/ai/complete_payment`, {
                reference: paymentRef,
            });
            await authContext.refreshUser()
            if (response.status === "success") {
                setLoading(false);
                props.closeDrawer()
                message.success('Verification complete ')
            } else {
                message.error(response.reason);
                setLoading(false);
            }
        } catch (error) {
            message.error(error.message);
            setLoading(false);
        }
    };

    return <Drawer
        placement={'top'}
        onClose={() => props.closeDrawer()}
        closeIcon={null}
        height={600}
        open={props.openDrawer}
        closable={false}
        maskClosable={false}
    >
        <div className="w-full lg:w-6/12 mx-auto relative">
            <div onClick={() => props.closeDrawer()} className="absolute right-2 -top-0">
                <Icon icon={'zondicons:close-solid'} className="text-lg" />
            </div>

            <div className="flex justify-between items-center w-full mt-1 mb-8">
                <span className="text-center block w-full">Verify your account</span>
            </div>

            <div className="flex my-4 px-3">
                <span className="font-bold">Hi there 👋, you need to verify you account to get full access to creatorBase features</span>
            </div>

            <div className="flex flex-col">
                <CheckListItem title="Auto update your followers counts" />
                <CheckListItem title="Get priority invite to new brand campaigns" />
                <CheckListItem title="Free organic account followers boost  (2k)" />
                <CheckListItem title="Apply for IPhone device on installment" />
                <CheckListItem title="Realtime  social pages analytics monitoring" />
                <CheckListItem title="Less fees on payout" />
                {/*<CheckListItem title="Boost your account organically using our AI SMM (Free)" />*/}
            </div>
            <div className="px-4 mt-8 flex flex-col items-center justify-center">
                <div className=" flex flex-col items-start my-3 w-full">
                    {/*  
                    <div className="flex gap-x-2 items-center mb-1">
                        <span>NIN number</span>
                    </div> 
                    <Input
                        name={"account_name"}
                        type="text"
                        onChange={(val) => setData(val.target.value)}
                        placeholder="NIN number"
                        className={``}
                    />
                    */}
                </div>

                <div className="flex my-2">
                    <span className="text-xs text-red-500">One time fee of 10,000 Naira will be required</span>
                </div>
                <MButton
                    label={'Continue with verification'}
                    onClick={initiatePayment}
                    loading={loading}
                    icon={undefined}
                    iconColor={undefined}
                    className={undefined}
                    disabled={undefined}
                    ref={undefined}
                />
            </div>
        </div>

    </Drawer>
}

const CheckListItem = ({ title }) => {
    return <div className="flex items-center gap-x-2 px-2 my-1">
        <Icon icon={'ic:baseline-check'} className="text-lg" />
        <span className="text-gray-600"> {title} </span>
    </div>
}