import { Icon } from "@iconify/react"
import { Input, Select, message } from "antd"
import React, { useState } from "react"
import { MButton } from "../../Common/buttons"
import { Str } from "../../../utils/constants"
import { makePostRequest } from "../../../utils"

export const SetPriceAnNiche = ({ onChangeStep, onInputData }) => {
    const [niches, setNiches] = useState([])
    const [loading, setLoading] = useState<boolean>(false)


    const onSetNiches = (nichesSelected) => {
        if (nichesSelected.length > 4) {
            message.error('Select up to 4 niches')
        } else {
            setNiches(nichesSelected)
        }
    }

    const validated = () => {
        if (niches.length < 2) {
            message.error('Select at least 2 Niches')
            return false
        }

        return true;
    }

    const onUpdateNiches = async () => {
        if (validated()) {
            setLoading(true);
            try {
                const formatted = niches.includes('general') ? niches : [...niches, 'general']
                const response = await makePostRequest(`/api/ai/setChannelNiches`, {
                    niches: formatted,
                });
                if (response.status === "success") {
                    setLoading(false);
                    onChangeStep(3)
                    message.success('Niches updated')
                } else {
                    message.error(response.reason);
                    setLoading(false);
                }
            } catch (error) {
                message.error(error.message);
                setLoading(false);
            }
        }
    };

    return <div className="flex flex-col pt-6 px-2">

        <div className=" flex flex-col items-start my-3 gap-y-4">
            <div className="flex flex-col gap-x-2 items-start my-3 -mb-0">
                <span>Select your niche</span>
                <span className="text-xs text-gray-400">
                    A Niche is refers to the kind of content you post,
                    that people follow your account to view
                </span>
            </div>
            <Select
                mode="tags"
                className={`w-full h-20 text-xs  `}
                style={{ height: 40 }}
                placeholder="Select an option "
                value={niches}
                suffixIcon={
                    <Icon className="cursor-pointer text-xs transition-all duration-200 mb-3 hover:scale-105"
                        icon={'streamline:pencil-solid'}
                    />
                }
                options={Str.niches.map((opt) => {
                    return { value: opt, label: opt };
                })}
                onChange={(ev) => onSetNiches(ev)}
            />
        </div>

        <div className="flex ite justify-center w-full mt-4">
            <MButton
                onClick={onUpdateNiches}
                loading={loading}
                label={'Set Niches'}
                icon={undefined}
                iconColor={undefined}
                className={undefined}
                disabled={undefined}
                ref={undefined}
            />
        </div>
    </div>
}