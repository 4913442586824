import { Icon } from "@iconify/react"
import { Drawer, Dropdown, Form, Input, Spin, Tag, Tooltip, message } from "antd"
import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"

import { MButton } from "../Common/buttons.js"
import { makePostRequest, ngnMoneyFormatter } from "../../utils/index.js"
import useAuth from "../Auth/UseAuthHook.tsx"


export const WithdrawMoneyDrawer = ({ balance, account_details, fullname, openDrawer, onCloseDrawer }) => {

    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState<any>(0);
    const auth = useAuth();

    async function joinCampaign() {

        try {
            setLoading(true)
            const response = await makePostRequest('/api/ai/withdrawBalance', { amount })
            if (response.status === "success") {
                message.success('Withdrawal requested successfully')
                await auth.refreshUser();
                onCloseDrawer(true)
            } else {
                message.error(response.reason);
                setLoading(false);
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            message.error(`${error}`)
        }
    }

    const onWithdrawMoney = async () => {
        await joinCampaign()
    }

    return <Drawer
        placement={isMobile ? 'top' : 'right'}
        closable={false}
        onClose={onCloseDrawer}
        open={openDrawer}
        width={isMobile ? window.screen.width : 800}
        height={400}
        className="w-full p-0"
        style={{ padding: '0 !important' }}
    >
        <div className="flex  flex-col  gap-y-4 items-center  mx-auto w-full px-4">
            <div onClick={() => onCloseDrawer()} className="absolute right-4 top-8 lg:top-4 lg:right-6">
                <Icon icon={'zondicons:close-solid'} className="text-lg" />
            </div>
            <div className="flex flex-col justify-center items-center w-full text-center px-2">
                <span className="text-2xl font-medium block text-center w-full">{ngnMoneyFormatter.format(balance)}</span>
                <span className="text-gray-500 w-full text-center ">Available balance</span>
            </div>

            <div className="flex flex-col w-full gap-y-3 pb-3 border-b">
                <div className="flex gap-x-2 items-center mb-1">
                    <div className="flex items-center gap-x-2">
                        <Icon icon={'mdi:bank'} />
                        <span>Account details</span>
                    </div>

                </div>
                <div className="flex justify-between w-full">
                    <span>{account_details?.bank_name}</span>
                    <span>{account_details?.account_name}</span>
                </div>

                <div className="flex justify-between w-full">
                    <span>{account_details?.account_number}</span>
                </div>
            </div>


            <div className="w-full">
                <div className=" flex flex-col items-start my-3">
                    <div className="flex gap-x-2 items-center mb-1">
                        <span>Amount to withdraw</span>
                    </div>
                    <Input
                        name={"account_number"}
                        type="number"
                        onChange={(val) => setAmount(val.target.value)}
                        placeholder="Amount to withdraw"
                        className={`shadow-sm h-12 border-gray-300`}
                    />
                </div>
            </div>

            <div className="my-3 w-full px-3 absolute bottom-1 right-0 left-0">
                <MButton
                    onClick={onWithdrawMoney}
                    loading={loading}
                    label={'Request withdrawal'}
                    icon={undefined}
                    iconColor={undefined}
                    className={"w-full"}
                    disabled={undefined}
                    ref={undefined}
                />
            </div>

        </div>
    </Drawer>
}
