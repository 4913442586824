import { Icon } from "@iconify/react"
import { Tooltip } from "antd"
import React from "react"
import { ngnMoneyFormatter } from "../../utils"


export const RewardItem = ({ icon, reward, title }) => {
    return <Tooltip title={title}>
        <span className="bg-gray-200 rounded-2xl px-2 p-1 flex items-center gap-x-2 cursor-pointer hover:opacity-80">
            <Icon className="text-gray-700" icon={icon} />
            <span className="text-gray-700"> {ngnMoneyFormatter.format(reward)} </span>
        </span>
    </Tooltip>
}