
import { Avatar, DatePicker, Dropdown, Input, Modal, Steps, Tag, Tooltip, message } from "antd"
import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { Icon } from "@iconify/react"

import { makePostRequest, makePutRequest } from "../../utils/index.js"
import { PostContent } from "./PostContent.tsx"
import { MButton } from "../Common/buttons.js"
import usePersonaContext from "../../contexts/PersonaContext/UsePersonaHook.tsx"
import { IPersona } from "../../interface/ipersona.ts"
import dayjs, { Dayjs } from "dayjs"
import { IPost } from "../../interface/ipost.js"
import usePostChannelContext from "../../contexts/PostChannelsContext/usePostChannelHook.tsx"
import useAuth from "../Auth/UseAuthHook.tsx"
import { PostInfluencers } from "./PostInfluencers.tsx"
import useInfleuncerContext from "../../contexts/InfluencerContext/InfluencerListHook.tsx"
import { ContentPreview } from "./Preview/ContentPreview.tsx"
import { InfluencerHighlighItem } from "./Preview/InfluencerPreview.tsx"
import { PostFinishSetup } from "./PostFinishSetup.tsx"


export const ManagePostModal = ({ onCloseModal, openModal, existingPost }) => {
    const [selectedPersona, setSelectedPersona] = useState<IPersona | undefined>()
    const [current, setCurrent] = useState(0);

    const [posts, setPosts] = useState<Array<IPost>>([]);
    const [selectedNiches, setSelectedNiches] = useState<Array<string> | undefined>([]);
    const [totalReach, setTotalReach] = useState<number>(0);
    const [totalCampaignCost, setTotalCampaignCost] = useState(0);
    const [selectedPostIndex, setSelectedPostIndex] = useState<number | undefined>(undefined);

    const authContext = useAuth();
    const [selectedInfluencers, setSelectedInfluencers] = useState<Array<IPost>>([]);
    const [highlightedInfluencer, setHighlightedInfluencer] = useState<IPost | undefined>(undefined);
    const [selectedPost, setSelectedPost] = useState()

    // batch states
    const [batchName, setBatchName] = useState<string | undefined>();
    const [hashtag, setHashTag] = useState<string | undefined>();
    // batch states

    const [loading, setLoading] = useState(false)
    const personaContext = usePersonaContext()
    const postChannels = usePostChannelContext()

    const [scheduledDate, setScheduledDate] = useState<string | undefined>();
    const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

    const isPostEditingDisabled = existingPost?.queue_status && existingPost.queue_status != 'pending';
    const influencerContext = useInfleuncerContext()

    const resetModal = () => {
        setCurrent(0)
        setBatchName(undefined)
        setPosts([{ content: '...', attachments: [] }])
        setSelectedInfluencers([])
        setHighlightedInfluencer(undefined)
        setSelectedPostIndex(undefined)
        onCloseModal(true)
    }


    useEffect(() => {
        if (selectedInfluencers && selectedInfluencers?.length > 0) {
            const niches = selectedInfluencers.map((inf) => inf.niches)
            const totalReach = selectedInfluencers.reduce((n, { followers }) => {
                return n + followers
            }, 0)

            setTotalReach(totalReach)
            setSelectedNiches([...new Set([...niches?.flat()])])
        }
    }, [selectedInfluencers])

    useEffect(() => {
        if (!selectedPost && posts?.length > 0) {
            setSelectedPostIndex(0)
        }
    }, [posts])

    useEffect(() => {
        setHashTag(batchName?.replaceAll(' ', ''))
    }, [batchName])

    useEffect(() => {
        if (!selectedInfluencers) return
        const total = selectedInfluencers.reduce((n, { price_per_post }) => {
            return n + price_per_post
        }, 0)
        setTotalCampaignCost(total)
    }, [selectedInfluencers])

    useEffect(() => {
        if (existingPost) {
            const pst = existingPost.post;
            pst.attachments = pst?.attachments?.map((attachment) => attachment.resource ? attachment.resource : attachment)

            /*             const existingInfluencers = existingPost?.influencers ?? [];
                        const formattedInfluencers = existingInfluencers.map((inf) => {
                            const findInfluencer = influencerContext.influencers?.find((inf) => inf.company_id === inf.company_id)
                            console.log(findInfluencer);
            
                            return findInfluencer
                        }) */

            //  setSelectedInfluencers(formattedInfluencers)
            setPosts([pst])
            setScheduledDate(existingPost?.schedule)
            setBatchName(existingPost?.name)
            // setScheduleStatus(existingPost?.status)
        } else {
            setPosts([{ content: '...', attachments: [] }])
            const newDate = dayjs().add(1, 'day').format(dateTimeFormat).toString();
            setScheduledDate(newDate)
            // setBatchName(`Post - ${newDate}`)
            // setScheduleStatus(Str.postScheduleStatus.draft as 'draft')
        }
    }, [existingPost, postChannels.channels, influencerContext.influencers])

    useEffect(() => {
        if (personaContext.personas && personaContext.personas?.length > 0) {
            setSelectedPersona(personaContext.personas[0])
        }
    }, [personaContext.personas])


    useEffect(() => {
        console.log(posts);

        if (posts && posts.length > 0) {
            setSelectedPost(posts[0])
        }

    }, [posts])


    const onChangeStep = (step: number) => {
        setCurrent(step);
        onSetHighlightedInfluencer(undefined)
    }


    const validateBatch = () => {
        /*  for (const index in batch) {
             if (batch[index]?.content) {
                 const channel = postChannels.channels?.find((ch) => ch.id === batch[index]?.channel_id)
 
                 switch (channel?.platform?.id) {
                     case 1:
                         // twitter validations
                         if (batch[index]!.content!.length > 270) {
                             message.error('Twitter channels can only accept up to 270 characters')
                             return false
                         }
                         break;
 
                     default:
                         break;
                 }
             }
  
         } */


        return true
    }

    const onSubmitInfo = async (status: 'draft' | 'scheduled') => {
        setLoading(true);
        try {
            let response
            if (existingPost?.id) {
                response = await makePutRequest(`/api/ai/posts/${existingPost?.id}`, {
                    platform_id: 1,
                    batch_id: existingPost?.id,
                    batch: posts,
                    persona_id: selectedPersona?.id,
                    name: batchName,
                    niches: ['general'],
                    status: status,
                    hashtag,
                    schedule: scheduledDate,
                    total_costs: totalCampaignCost,
                    estimated_reach: totalReach,
                    selected_niches: selectedNiches,
                    selected_influencers: selectedInfluencers
                });
            } else {
                response = await makePostRequest("/api/ai/posts", {
                    ///  batch,
                    platform_id: 1,
                    persona_id: selectedPersona?.id,
                    batch: posts,
                    status: status,
                    hashtag,
                    schedule: scheduledDate,
                    name: batchName,
                    niches: ['general'],
                    total_costs: totalCampaignCost,
                    estimated_reach: totalReach,
                    selected_niches: selectedNiches,
                    selected_influencers: selectedInfluencers
                });
            }
            if (response.status === "success") {
                setLoading(false);
                message.success('Post updated')

                return response.data
            } else {
                message.error(response.reason);
                setLoading(false);
                return false
            }
        } catch (error) {
            message.error(error.message);
            setLoading(false);
            return false
        }
    };

    const updatePost = (section: 'resource' | 'campaign_name' | 'content' | 'hashtag', data) => {
        if (section === 'campaign_name') {
            return setBatchName(data)
        }
        else if (section === 'content' && (!data || data?.length < 1)) return
        else {
            const copyBatch = [...posts];
            copyBatch[selectedPostIndex!]![section] = data
            setPosts([...copyBatch])
        }
    }

    const onSavePost = async (status: 'draft' | 'scheduled' = 'draft') => {
        if (validateBatch() === false) return
        const response = await onSubmitInfo(status)
        if (response) resetModal()
    }

    const onChangeSchedule = async (e: Dayjs, dtStr) => {
        setScheduledDate(e.format(dateTimeFormat).toString())
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current.valueOf() < dayjs().subtract(1, 'day')
    }

    const onSetHighlightedInfluencer = (influencer) => {
        setHighlightedInfluencer(influencer)
    }

    const isSelected = (influencer) => {
        return selectedInfluencers.find((sl) => sl.company_id == influencer.company_id)
    }

    console.log(highlightedInfluencer);

    const onToggleOnfluencerForCampaign = (influencer) => {
        if (isSelected(influencer)) {
            const removedInfluencer = selectedInfluencers.filter((sl) => sl.company_id != influencer.company_id)
            setSelectedInfluencers([...removedInfluencer])
        } else {
            setSelectedInfluencers([...selectedInfluencers, influencer])
        }
    }


    return <Modal
        open={openModal}
        onCancel={() => resetModal(true)}
        footer={null}
        width={isMobile ? undefined : 1500}
        closeIcon={null}
        closable={false}
        maskClosable={false}
        className="shadow-none border-none relative"
        destroyOnClose={true}
    >
        <div className={`${current !== 2 ? 'right-1  top-2' : 'right-[13%]  top-4'} absolute  z-10 cursor-pointer bg-white rounded-full p-3`} onClick={() => resetModal(true)}>
            <Icon className="text-lg text-gray-800" icon={'zondicons:close-solid'} />
        </div>

        <div className="flex justify-center gap-x-3">
            <div className="lg:w-3/12 relative hidden lg:flex flex-col gap-y-6 bg-white rounded p-3 pb-2">
                <div className=" items-center flex justify-start gap-x-4">
                    <Icon icon={'material-symbols-light:business-center'} className="text-xl" />
                    <p className="font-bold text-lg">Create campaign</p>
                </div>
                <Steps
                    direction="vertical"
                    current={current}
                    className="text-sm"
                    size="small"
                    onChange={onChangeStep}
                    items={[
                        {
                            title: 'Campaign Content ',
                            description: <span className="text-xs">Add Campaign post and media</span>,
                        },
                        /*    {
                               title: 'Campaign Influencers',
                               description: <span className="text-xs">Select influencers for campaign</span>,
                           }, */
                        {
                            title: 'Finish Setup',
                            description: <span className="text-xs">Complete setup and launch campaign</span>,
                        },
                    ]}
                />
            </div>

            <div className={`lg:w-6/12 transition-all duration-200 w-full bg-white rounded px-3 pt-0`}>
                <div className="w-full mt-4 h-full relative ">
                    <div className="absolute bottom-6 lg:block hidden left-0 right-0 z-40">
                        <div className="px-4 justify-end flex  gap-x-4">
                            {/*                           <MButton
                                onClick={() => onSavePost('draft')}
                                label={'Save campaign as  draft'}
                                loading={loading}
                                inverted={true}
                                icon={undefined}
                                iconColor={'text-primary'}
                                className={'w-fit border-2 p-0'}
                                disabled={isPostEditingDisabled}
                                ref={undefined}
                            /> */}

                            <MButton
                                onClick={() => onSavePost('scheduled')}
                                label={'Save campaign'}
                                loading={loading}
                                icon={undefined}
                                iconColor={undefined}
                                className={'w-fit'}
                                disabled={isPostEditingDisabled}
                                ref={undefined}
                            />
                        </div>
                    </div>
                    {current === 0 && <PostContent
                        hashTag={hashtag}
                        post={selectedPost}
                        campaignName={batchName}
                        updatePost={updatePost}
                    />}

                    {/*      {current === 1 && <PostInfluencers
                        post={selectedPost}
                        ///  posts={posts}
                        allSelected={selectedInfluencers}
                        onSetHighlightedInfluencer={onSetHighlightedInfluencer}
                        highlightedInfluencer={highlightedInfluencer}
                        isSelected={isSelected}
                    />
                    } */}
                    {current === 1 && <PostFinishSetup
                        scheduledDate={scheduledDate}
                        disabledDate={disabledDate}
                        onChangeSchedule={onChangeSchedule}
                        isPostEditingDisabled={isPostEditingDisabled}
                        timezone={authContext.user?.timezone}
                        totalCampaignCost={totalCampaignCost}
                        totalReach={totalReach}
                        selectedNiches={selectedNiches}
                        selectedInfluencers={selectedInfluencers} />
                    }

                </div>
            </div>
            {current !== 2 && <div className={`${'lg:w-3/12 lg:flex hidden'} transition-all duration-200 relative flex-col gap-y-6 bg-white rounded p-3 pb-2`}>
                <div>
                    {current === 0 && <ContentPreview />}
                    {current === 1 && <InfluencerHighlighItem
                        item={highlightedInfluencer}
                        isSelected={selectedInfluencers.find((sl) => sl.company_id == highlightedInfluencer?.company_id)}
                        onAddToCampaign={() => onToggleOnfluencerForCampaign(highlightedInfluencer)}
                    />}
                </div>
            </div>
            }
        </div>
    </Modal>
}
