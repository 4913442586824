import React, { useEffect, useState } from "react"
import { MButton } from "../Common/buttons"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Button, Input, List, Modal, Select, Spin, Tabs, message } from "antd"
import { ArrowLeftOutlined, ShoppingFilled, ShopFilled } from "@ant-design/icons";
import { makeClientSideApiRequest, makePostRequest, makePutRequest } from "../../utils";
import { onLoadPageInfo } from "../../services/load_page_data.ts";
import { IStoryInfo, IStoryStore } from "../../interface/istoryinfo";
import UserSettings from "./UserSettings.tsx";
import { IUser } from "../../interface/iuser";
import MainLayout from "../Common/Layout.tsx";
import { isMobile } from "react-device-detect";
import { Icon } from "@iconify/react";
import { StatusChip } from "../Common/Campaign/StatusChip.tsx";
import useAuth from "../Auth/UseAuthHook.tsx";



const Settings = () => {
  const navigate = useNavigate()
  const [data, setData] = useState<IUser>()
  const authContext = useAuth()
  // console.log(key, text);


  return <>
    <MainLayout title="Settings" customHeader={undefined} >
      <div className="flex  flex-col items-start gap-x-6 my-10 px-4 lg:w-9/12 w-full gap-y-6">

        <div className="bg-gray-50 p-3 w-full">
          <span className="font-semibold">Basic Profile</span>

          <div className="flex justify-between items-center mt-10 mb-8">
            <span className="text-sm"> Brand name</span>
            <span className="text-sm"> {authContext?.user?.company_name}</span>
          </div>

          <div className="flex justify-between items-center my-4 mb-8">
            <span className="text-sm"> Email address</span>
            <span className="text-sm"> {authContext?.user?.email}</span>
          </div>

          <div className="flex justify-between items-center my-4">
            <span className="text-sm"> Phone</span>
            <span className="text-sm"> {authContext?.user?.phone}</span>
          </div>
        </div>


        <div className="bg-gray-50 p-3 w-full">
          <span className="font-semibold">Security</span>

          <div className="flex justify-between items-center mt-10 mb-8">
            <span className="text-sm"> Password</span>
            <span className="text-sm text-blue-700 font-semibold"> Change password</span>
          </div>
        </div>


        <div className="bg-gray-50 p-3 w-full">
          <div className="flex flex-col gap-y-1">
            <span className="font-semibold">Social Profile</span>
            <span className="text-sm text-gray-500">Connect your social profiles</span>
          </div>


          <div className="flex  pt-10 w-full flex-wrap gap-y-4">
            {authContext?.user?.channels.map((ch) => {
              return <div className="lg:w-6/12 w-full lg:pr-6">
                <div className="flex  border p-2 rounded-md  justify-between items-start">
                  <div className="flex gap-x-1 items-center justify-between w-full">
                    <div className="flex items-center gap-x-2">
                      <Icon icon={ch?.platform?.platform_logo} />
                      <span className="text-sm"> {ch?.platform?.platform_name}</span>
                    </div>

                    <div className="lg:inline hidden">
                      <StatusChip text={ch?.channel_name} />
                    </div>
                  </div>

                  <div className="lg:hidden inline">
                    <StatusChip text={ch?.channel_name} />
                  </div>
                </div>
              </div>
            })}
          </div>

        </div>


      </div>
    </MainLayout>
  </>
}

export default Settings






