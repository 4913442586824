import { Form, Input, Spin, Upload, message } from "antd"
import React, { useEffect, useState } from "react"
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { baseRoute } from "../../utils/index.js";
import useAuth from "../Auth/UseAuthHook.tsx";
import { IPostImage } from "../../interface/ipostimage.js";
import { Str } from "../../utils/constants.js";

export const PostImage = ({ post, updatePost }) => {
    const { attachments = [] } = post || {}
    const [innerAttachments, setAttachments] = useState<Array<IPostImage>>([]);


    useEffect(() => {
        console.log('Posts from image', post);
        
        setAttachments([...attachments, { path: null, id: 0 }])
    }, [post])



    const onAttachmentSaved = (image: IPostImage) => {
        const updated = [...innerAttachments, image].filter((im) => im.id !== 0)
        updatePost('attachments', updated)
        setAttachments([...updated, { path: '', id: 0 }])
    }

    return <Form className="flex-col   overflow-y-hidden items-center  py-2 h-full">
        <div className="lg:mt-0 lg:mb-6 w-full flex gap-x-0 mt-12 flex-wrap gap-y-8">
            {innerAttachments.map((img) => {
                return <div className=" flex flex-col ">
                    <div className="px-0">
                        <ImgItem existingImage={img} onImageUploaded={onAttachmentSaved} />
                    </div>
                </div>
            })}

        </div>
    </Form>
}

const ImgItem = ({ existingImage, onImageUploaded }) => {
    const authContext = useAuth();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();

    useEffect(() => {        
        if (existingImage) {
            setImageUrl(existingImage?.path)
        }
    }, [existingImage])

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const getBase64 = (img, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };


    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'error') {
            message.error('Unable to complete upload')
            setLoading(false);
            return;
        }
        if (info.file.status === 'done') {
            if (info.file.response.status === 'success') {
                // console.log(info.file.response.data);
                onImageUploaded(info.file.response.data)
                setLoading(false);
                setImageUrl(info.file.response.data.path);
                // Get this url from response in real world.
                /*                 getBase64(info.file.originFileObj, (url) => {
                                   
                                    setLoading(false);
                                    setImageUrl(url);
                                }); */
            } else {
                message.error(info.file.response.reason)
            }
        }
    };

    return <Upload
        name='resource'
        multiple={true}
        action={`${baseRoute}/api/ai/uploadResource`}
        headers={{ 'Authorization': `Bearer ${authContext?.getUser()?.token}` }}
        data={{ story_id: 11 }}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        style={{ width: 40 }}
    >
        {imageUrl ? <img src={`${Str.spacesBaseUrl}/${imageUrl}`} alt="avatar" style={{ width: '100%' }} className="" /> : uploadButton}
    </Upload >
}