import { Icon } from "@iconify/react"
import { Modal, Input, message, Select } from "antd"
import { MButton } from "../Common/buttons"
import React, { useState } from "react"
import { makePostRequest } from "../../utils"
import ChannelService from "./channelsService.ts"
import { isMobile } from "react-device-detect"
import ChannelsToggle from "../Common/ChannelsToggle.tsx"
import { IPostChannel } from "../../interface/ipostchannel"
import PlatformsToggle from "../Common/PlatformsToggle.tsx"
import { Str } from "../../utils/constants"

export const SocialChannelModal = ({ platform_id, isActivateSocial, setIsActiveSocial }) => {
    const [loading, setLoading] = useState(false);
    const [postPricing, setPostPricing] = useState({});
    const channelsService = ChannelService()


    const handleAddChannel = async () => {
        try {

            switch (platform_id) {
                case '1':
                    setLoading(true)
                    await channelsService.initiateTwitter()
                    setLoading(false)
                    break;
                case '2':
                    setLoading(true)
                    await channelsService.initiateLinkedInProfile()
                    setLoading(false)
                    break;
                case '5':
                    setLoading(true)
                    await channelsService.initiateTikTokProfile()
                    setLoading(false)
                    break;
                default:
                    break;
            }
        } catch (error) {
            setLoading(false)
            message.error(error.message)
            // console.log(error);
        }
    };

    const onAddChannel = async () => {
        try {
            if (!postPricing) message.warning('Pricing required')
            setLoading(true);
            const response = await makePostRequest("/api/ai/setChannelPricing", {
                platform_id: platform_id,
                ...postPricing
            });
            if (response.status === "success") {
                await handleAddChannel()
            } else {
                setLoading(false);
                message.error(response.reason);
            }
        } catch (error) {
            message.error(error.message)
            setLoading(false);
        }

    }


    const onInput = (type, value) => {
        setPostPricing({ ...postPricing, [type]: value })
    }


    return <Modal
        open={isActivateSocial}
        onCancel={() => setIsActiveSocial(false)}
        footer={null}
        closeIcon={null}
        className="shadow-none border-none"
        maskClosable={false}
        maskStyle={{ color: 'white' }}

        closable={false}
        //  width={isMobile ? undefined : 1100}
        destroyOnClose={true}
    >

        <div className="flex flex-col gap-y-5 bg-white py-8 rounded px-4 ">
            <div className="flex flex-col justify-between items-start gap-y-2">
                <span>Connect social account</span>
                <PlatformsToggle type="list" />
                <span className="text-xs text-gray-400 block mt-2">Connect your Twitter account. Other platforms are coming soon.</span>
            </div>


            <PricingItem
                description={'Set how much brands will be required to pay to post to your channel (Naira)'}
                setPostPricing={(ev) => onInput('post_price', ev)}
                title={'Post '}
                placeholder={'Enter amount'}
            />
            <PricingItem
                description={'Set how much brands will be required to pay to re-post a sponsored post (Naira)'}
                setPostPricing={(ev) => onInput('repost_price', ev)}
                title={'Re-post'}
                placeholder={'Enter amount'}
            />

            <PricingItem
                description={'Set how much brands will be required to pay  for a re-tweet (Naira)'}
                setPostPricing={(ev) => onInput('forward_price', ev)}
                title={'Re-tweet'}
                placeholder={'Enter amount'}
            />

            <div className="flex flex-col items-start justify-start mt-1">
                <div className="flex flex-col py-1">
                    <span className="text-gray-500 text-sm">{'Select Niche'} </span>
                    <span className="text-xs text-gray-400">{'Select up to 4 niches that your channel posts about'}</span>
                </div>
                <Select
                    mode="tags"
                    className={`w-full h-12 text-xs  `}
                    placeholder="Select an option "

                    options={Str.niches.map((opt) => {
                        return { value: opt, label: opt };
                    })}
                    onChange={(ev) => onInput('niches', ev)}
                />
            </div>



            <div className="flex justify-center items-center">
                <MButton iconColor={'text-white'}
                    onClick={onAddChannel}
                    icon={'icon-park-solid:setting'}
                    label={'Setup  account'}
                    loading={loading}
                    className={undefined}
                    disabled={undefined} ref={undefined} />
            </div>
        </div>

    </Modal>
}



const PricingItem = ({ setPostPricing, placeholder, title, description }) => {
    return <div>
        <div className="flex flex-col py-1">
            <span className="text-gray-500 text-sm">{title} </span>
            <span className="text-xs text-gray-400">{description}</span>
        </div>
        <Input type="number" onChange={(ev) => setPostPricing(ev.target.value)} placeholder={placeholder} />
    </div>
}