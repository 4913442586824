import {
    makeClientSideApiRequest,
    makePostRequest,
    setUserInfo,
} from "../../utils";
import {
    Input,
    message,
    Spin,
} from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import React from "react";

export default function AddTwitter() {
    const [loading, setLoading] = useState(false);
    const [isAuthComplete, setIsAuthComplete] = useState(false);
    const [error, setError] = useState(undefined);
    const location = useLocation()
    const nav = useNavigate();

    useEffect(() => {
        if (!isAuthComplete && !loading) {
            onSubmitTwitterCred();
        }

    }, []);

    const onSubmitTwitterCred = async () => {
        setLoading(true);
        try {
            const query = new URLSearchParams(location.search);
            const action = query.get('action');
            const channel = query.get('channel');
            const user_oauth_token = query.get('oauth_token');
            const user_oauth_verifier = query.get('oauth_verifier');

            if (action && action === 'addChannel') {
                // console.log(user_oauth_token, user_oauth_verifier);

                const response = await makePostRequest("/api/ai/addTwitterChannel", {
                    user_oauth_token,
                    user_oauth_verifier,
                    channel
                });

                if (response.status === "success") {
                    setIsAuthComplete(true)
                    setLoading(false);
                    message.success("Added channel successfully");
                  window.opener.focus(); window.close();
                    // console.log(response);
                    // redirect back to app
                } else {
                    setError(response.reason)
                    message.error(response.reason);
                    setLoading(false);
                }
            }

        } catch (error) {
            message.error(error.message);
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>CreatorBase | Add twitter account</title>
                <meta name="description" content="CreatorBase add twitter account" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                />
                <link rel="icon" href="/quill-pen.png" />
                <link rel="canonical" href="https://app.creatorbase.co" />
            </Helmet>

            <div className="w-full">
                <main className="h-screen w-4/12  mx-auto  flex flex-col justify-cente items-center mt-20">
                    {loading && <div className="flex flex-col gap-y-4">
                        <span className="block text-center text-xl font-bold">Adding twitter account..</span>
                        <div className="flex items-center justify-center">
                            <Spin size="large" className="text-center" spinning={true} />
                        </div>
                    </div>
                    }

                </main >
            </div >
        </>
    );
}
