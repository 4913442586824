import React, { useEffect, useState } from "react"
import { MButton } from "../Common/buttons"
import { Avatar, Button, Dropdown, Input, List, Modal, Select, Space, Spin, Tabs, Tag, message } from "antd"
import usePostsContext from "../../contexts/PostContext/UsePostHook.tsx";
import { useLinkedIn } from 'react-linkedin-login-oauth2';

import { IPost } from "../../interface/ipost";
import { Icon } from "@iconify/react";
import { ManagePostModal } from "../ManagePostModal/ManagePostModal.tsx";
import dayjs from "dayjs";
import usePostChannelContext from "../../contexts/PostChannelsContext/usePostChannelHook.tsx";
import { makePostRequest } from "../../utils";
import { useLocation } from "react-router-dom";
import usePlatformsContext from "../../contexts/PlatformContext/usePlatformsHook.tsx";
import ChannelService from "./channelsService.ts";
import { EmailChannelModal } from "./EmailChannelModal.tsx";
import { SocialChannelModal } from "./SocialChannelModal.tsx";

const postViews = [
    { key: 'list', label: 'List view', icon: <Icon icon={'ic:round-list'} /> },
    { key: 'calendar', label: 'Grid view', icon: <Icon icon={'solar:calendar-bold'} /> },
]


const Channels = () => {
    const [selectedLayoutView, setSelectedLayoutView] = useState()
    const [loading, setLoading] = useState(false)

    const [isActivateEmail, setIsActivateEmail] = useState(false)
    const [isActiveSocial, setIsActiveSocial] = useState(false)
    const [selectedPlatformId, setSelectedPlatformId] = useState(undefined)
    const [isActivateSMS, setIsActivateSMS] = useState(false)

    const location = useLocation()
    const postChannelContext = usePostChannelContext()
    const platformsContext = usePlatformsContext()
    const channelsService = ChannelService()

    const handleMenuClick = (e) => {
        if (e.key === 'all') {
            setSelectedLayoutView(undefined)
        } else {
            const selected = postViews.find((p) => p.key === e.key)
            setSelectedLayoutView(selected)
        }
    };


    const linkedInLogin = () => {
    }

    const menuProps = {
        items: postViews,
        onClick: handleMenuClick,
    };

    useEffect(() => {
        onCompleteChannelIntegration();
    }, []);


    const handleAddChannel = async (e) => {
        try {
            switch (e.key) {
                case '1':
                case '2':
                case '5':
                    setLoading(true)
                    openSocialChannel(e.key)
                    setLoading(false)
                    break;
                case '3':
                    setIsActivateEmail(true)
                    break;
                case '4':
                    setIsActivateSMS(true)
                    break;
                default:
                    break;
            }
        } catch (error) {
            setLoading(false)
            message.error('Unable to complete request')
            // console.log(error);
        }
    };


    const addChannelProps = {
        items: platformsContext.platforms?.map((pl) => { return { key: pl.id, disabled: pl.is_active !== 1, label: pl.platform_name, icon: <Icon icon={pl.platform_logo} /> } }) ?? [],
        onClick: handleAddChannel,
    };


    useEffect(() => {
        setSelectedLayoutView(postViews[0])
    }, [])


    const onCompleteChannelIntegration = async () => {
        setLoading(true);
        try {
            const query = new URLSearchParams(location.search);
            const action = query.get('action');
            const platform_id = query.get('platform_id');
            let params = {}

            if (query.get('oauth_token') || query.get('oauth_verifier')) { // twitter
                params = {
                    user_oauth_token: query.get('oauth_token'),
                    user_oauth_verifier: query.get('oauth_verifier'),
                    platform_id: 1
                }
            } else if (query.get('code')) { // linkedin
                params = {
                    user_openid_code: query.get('code'),
                    platform_id: 2
                }
            }

            if (action && action === 'addChannel') {
                const response = await makePostRequest("/api/ai/completeChannelIntegration", {
                    platform_id,
                    ...params
                });

                if (response.status === "success") {
                    message.success("Added channel successfully");
                    window.location.href = '/'
                } else {
                    message.error(response.reason);
                }
            }
            setLoading(false);
        } catch (error) {
            message.error(error.message);
            setLoading(false);
        }
    };

    // console.log(postChannelContext.channels);

    const openSocialChannel = (platformId) => {
        setSelectedPlatformId(platformId)
        setIsActiveSocial(true)
    }

    const closeSocialChannel = () => {
        setSelectedPlatformId(undefined)
        setIsActiveSocial(false)
        setLoading(false)
    }

    return <>
        <SocialChannelModal platform_id={selectedPlatformId} isActivateSocial={isActiveSocial} setIsActiveSocial={closeSocialChannel} />
        <EmailChannelModal isActivateEmail={isActivateEmail} setIsActivateEmail={setIsActivateEmail} />
        <div className="flex justify-evenly">
            <div className={`w-full relative  flex justify-center ${postChannelContext.loadingChannels ? 'pt-20' : 'pt-0'} transition-all duration-200 px-14 pb-4`}>
                <div className="h-16 z-40 bg-white  border-b absolute  border-0 left-0  top-0 right-0  w-full  border-b-gray-100 items-center flex justify-between px-14 ">
                    <div className="w-[150px]">
                        <Dropdown menu={menuProps}>
                            <div className="flex gap-x-2 items-center cursor-pointer hover:bg-gray-200 p-2 rounded">
                                {selectedLayoutView?.icon}
                                <span> {selectedLayoutView?.label}</span>
                            </div>
                        </Dropdown>
                    </div>

                    <div className="flex gap-x-20 items-center">
                        <Dropdown menu={addChannelProps}>
                            <div className="flex gap-x-2 items-center cursor-pointer rounded">
                                <MButton iconColor={'text-white'}
                                    onClick={(e) => e.preventDefault()}
                                    icon={'icons8:plus'}
                                    label={'Add channel'}
                                    loading={loading}
                                    className={undefined}
                                    disabled={undefined}
                                />
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <Spin spinning={postChannelContext.loadingChannels}>
                    <section className="mt-16 h-scren overflow-y-scroll w-full pb-20 flex flex-wrap" >
                        {postChannelContext.channels?.map((channel) => <PostItem channel={channel} onHighlight={undefined} highlighted={undefined} />)}
                    </section>
                </Spin>

            </div>
        </div >
    </>
}

export default Channels

const PostItem = ({ onHighlight, channel, highlighted }) => {
    return <div onClick={onHighlight} className={`my-8 py-4 mx-3 relative rounded w-4/12 border border-gray-200  cursor-pointer h-40 bg-gray-50 flex flex-col items-start justify-start   pt-2  px-3  transition-all duration-300}`}>

        <div className="flex flex-col gap-y-2 px-4 mx-0">
            <Icon icon={channel?.platform?.platform_logo} className="text-2xl" />
            <span className="font">{channel?.channel_name}</span>
        </div>
        {/* 
        <div className="flex gap-x-2 mt-2 flex-wrap">
            <span className="text-xs text-gray-500">Analytics,</span>
            <span className="text-xs text-gray-500">Posts,</span>
            <span className="text-xs text-gray-500">Messages.</span>
        </div> */}

        <div className="absolute bottom-2 right-1"><Tag color="cyan" className="rounded-lg">Active </Tag></div>
    </div>
}