import { Checkbox, Input, Modal, Segmented, Switch, message } from "antd";
import { useContext, useState } from "react";

import {
  makeDeleteRequest,
  makePostRequest,
  makePutRequest,
} from "../../../utils";
import { useParams } from "react-router-dom";

const ViewTeamInfoModal = ({ openModal, onToggleModal, memberInfo }) => {
  const [loading, setLoading] = useState(false);
  const [backendPermissions, setBackendPermissions] = useState({});
  const [dashboardPermissions, setDashboardPermissions] = useState([]);
  const params = useParams();

  const onSubmitButtonClicked = async () => {
    await updateInvite();
  };

  const onCloseModal = (refresh = false) => {
    setLoading(false);
    onToggleModal(refresh);
  };

  const updateInvite = async () => {
    setLoading(true);
    try {
      const response = await makePutRequest(
        `/api/datasource/${params?.id}/team/updateMemberPermission/${memberInfo?.id}`,
        {
          dashboardPermissions: dashboardPermissions ?? [],
          backendPermissions: backendPermissions ?? {},
        }
      );

      if (response.status === "success") {
        message.success("Member permissions updated");
        onCloseModal(true);
      } else {
        setLoading(false);
        message.error(response.reason);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const removeInvite = async () => {
    setLoading(true);
    try {
      const response = await makeDeleteRequest(
        `/api/datasource/${params?.id}/team/remove?member_id=${memberInfo?.id}`
      );

      if (response.status === "success") {
        message.success("Member removed");
        onCloseModal(true);
      } else {
        setLoading(false);
        message.error(response.reason);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={openModal}
      onCancel={() => onCloseModal(false)}
      footer={null}
      title={"Manage team member"}
      width={700}
      destroyOnClose={true}
    >
      <div>jnewdsmkcx</div>
      <div>jnewdsmkcx</div>
      <div>jnewdsmkcx</div>
    </Modal>
  );
};

export default ViewTeamInfoModal;
