import { Icon } from "@iconify/react"
import { Form, Input, Spin, message } from "antd"
import React, { useEffect, useState } from "react"
import { MButton } from "../Common/buttons.js"

export const PersonaTone = ({ persona, loading, onAfterSave }) => {
    const { tone } = persona || {}


    const onSave = async (e) => {
        // console.log(e);
        onAfterSave(e)
    }

    return <Form onFinish={onSave} className="flex-col  h-full items-center px-10 py-2">
        <div className="lg:mt-8 lg:mb-6 w-full ">
            <span className="text-gray-600 font-bold text-lg">Persona tone</span>
            <Form.Item name={'tone'}>
                <Input.TextArea
                    defaultValue={tone}
                    value={tone}
                    rows={10}
                    placeholder="Select a tone"
                    className={`h-40 rounded-lg  text-gray-600 font-semibold bg-white border-gray-200 border my-3`}
                />
            </Form.Item>

        </div>
        <div className="text-center">
            <MButton
                loading={loading}
                label={'Update Persona'}
                icon={undefined}
                iconColor={undefined}
                className={undefined}
                disabled={undefined}
                type="submit" onClick={undefined} />
        </div>

    </Form>
}