import { useLocation, useNavigate } from "react-router-dom";
import { clearUserInfo, makeClientSideApiRequest } from "../../utils/index.js";
import React, { useEffect, useState } from "react";
import { message } from "antd";

export interface UserObj {
  token: string;
  fullname: string;
  timezone: string;
  email: string;
  account_type: string | undefined
  account_access: number | undefined
  onboarding_status: string | undefined
  is_account_verified: 1 | 0 | undefined,
  active_products: Array<number> | []
}

export interface AuthContextType {
  user: UserObj | null;
  refreshUser: () => Promise<void>;
  signIn: (user: string, callback: VoidFunction) => void;
  signOut: (callback: VoidFunction) => void;
  getUser: () => UserObj | undefined;
  loading: boolean
}

export let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<UserObj | null>(null);
  const userStr = localStorage.getItem("user");
  const parsed = userStr ? JSON.parse(userStr) : null;
  const navigate = useNavigate();
  const location = useLocation()
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const isAuthRoute = location.pathname === '/login' || location.pathname === '/register'
    if (parsed && !user) {
      refreshUser()
    } else if (!isAuthRoute && !localStorage.getItem("user") && !user) {
      navigate("/login");
    }
  }, [user, parsed]);

  let signIn = (newUser: UserObj, callback: VoidFunction) => {
    localStorage.setItem("user", JSON.stringify(newUser));
    localStorage.setItem("token", newUser.token);
    setUser(newUser);
    callback();
  };

  let signOut = (callback: VoidFunction) => {
    clearUserInfo();
    setUser(null);
    callback();
  };

  let getUser = (): UserObj | undefined => {
    const userStr = localStorage.getItem("user");
    const parsed = userStr ? JSON.parse(userStr) : null;
    return parsed as UserObj | undefined;
  };

  let refreshUser = async () => {
    setLoading(true);
    try {
      const response = await makeClientSideApiRequest("/api/user");
      if (response && response.status === "success") {
        const user = response.data;
        localStorage.setItem("user", JSON.stringify(user));
        console.log(user);

        setUser(user);
      } else {
        message.info('Unable to load user, refresh page');
      }
      setLoading(false);
    } catch (error) {
      message.info('Unable to load user, refresh page');
      setLoading(false);
    }
  }


  let value = { user, signIn, signOut, getUser, loading, refreshUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
