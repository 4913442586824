
import React, { useEffect } from "react";
import { IPersona } from "../../interface/ipersona.js";
import { message } from "antd";
import { onLoadPageInfo } from "../../services/load_page_data.ts";
import { IPlatform } from "../../interface/iplatform.ts";


export interface PlatformsContextType {
  platforms: Array<IPlatform> | undefined;
  loadingPlatforms: boolean,
  getPlatforms: () => void
}

export let PlatformsContext = React.createContext<PlatformsContextType>(null!);

export function PlatformsProvider({ children }: { children: React.ReactNode }) {

  let [platforms, setPlatforms] = React.useState<Array<IPlatform> | undefined>(undefined);
  let [loadingPlatforms, setLoadingPlatform] = React.useState(false);


  useEffect(() => {
    getPlatforms()
  }, [])

  const getPlatforms = async () => {
    onLoadPageInfo({
      path: `/api/ai/platforms`,
      onStartLoad: function (): void {
        setLoadingPlatform(true);
      },
      onSuccess: function (data: any): void {
        setLoadingPlatform(false);
        setPlatforms(data);
      },
      onFail: function (msg: string): void {
        setLoadingPlatform(false);
        message.error(msg);
      }
    })
  }

  const value = { platforms, getPlatforms, loadingPlatforms }

  return <PlatformsContext.Provider value={value}>{children}</PlatformsContext.Provider>;
}
