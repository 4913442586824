import { makePostRequest, setUserInfo } from "../utils";
import {
  Avatar,
  Input, message,
} from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MButton } from "../components/Common/buttons";
import { Helmet } from "react-helmet";
import useAuth from "../components/Auth/UseAuthHook.tsx";
import { Str } from "../utils/constants";
import AuthPagesCopy from "../components/Common/AuthPagesCopy.tsx";
import { logEvent } from "firebase/analytics";
import { analytics } from "../utils/firebaseutil.ts";
import { Icon } from "@iconify/react";
import AuthHalf from "../components/Common/AuthPagesCopy.tsx";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const authContext = useAuth();
  const location = useLocation();
  let navigate = useNavigate();


  useEffect(() => {
    logEvent(analytics, "page_visited", {
      content_type: "app_page",
      content_id: "login_page",
    });
  }, []);

  const [data, setData] = useState({
    email: undefined,
    password: undefined,
  });

  const onSignIn = async () => {
    setLoading(true);
    try {
      let from = location.state?.from?.pathname || Str.paths.home;

      const response = await makePostRequest("/api/auth/login", {
        email: data.email,
        password: data.password,
      });

      if (response.status == "success") {
        setLoading(false);
        message.success("Login success");
        authContext.signIn(response.data, () => {
          setUserInfo(response.data);
          navigate(from);
        });
      } else {
        message.error(response.reason);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const onSubmitLogin = async () => {
    if (!data.email) {
      message.error("Enter a valid email address");
    }
    if (!data.password) {
      message.error("Enter a valid password");
    } else {
      await onSignIn();
    }
  };

  const onInputData = (type, text) => {
    switch (type) {
      case "email":
        data.email = text;
        break;
      case "password":
        data.password = text;
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CreatorBase | Sign in to account</title>
        <meta name="description" content="CreatorBase" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        <link rel="icon" href="/quill-pen.png" />
        <link rel="canonical" href="https://app.sequelbase.com/connect" />
      </Helmet>
      <div className="bg-white">
        <main className="h-screen bg-white  mx-auto  w-full flex lg:flex-row flex-col-reverse">
         <AuthHalf />
          <div className="flex flex-col gap-y-4 w-full lg:w-6/12 items-center h-full justify-start py-10">
            <div className="w-10/12 mx-auto">
              <div>
                <div className="flex items-center my-0">
                  <h1
                    style={{ fontFamily: "mainFontBold" }}
                    className="font-black text-2xl my-3"
                  >
                    Welcome back
                  </h1>
                </div>
                <span className="text-gray-600 text-sm">
                  Need to create a new account?{" "}
                  <span className="text-blue-600">
                    <Link to={"/register"}>Signup</Link>{" "}
                  </span>
                </span>
              </div>
              <div className="flex flex-col justify-start mt-4 lg:mt-10">
                <div className="my-0"></div>

                <Input
                  name={"email_add"}
                  onChange={(val) => onInputData("email", val.target.value)}
                  placeholder="email@example.com"
                  className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                />

                <Input
                  name={"email_add"}
                  type="password"
                  onChange={(val) => onInputData("password", val.target.value)}
                  placeholder="********"
                  className={`h-10 text-gray-800 font-bold bg-white border-gray-200 border my-3`}
                />

                <div className="w-full flex mt-4">
                  <MButton
                    onClick={onSubmitLogin}
                    loading={loading}
                    className={"w-full"}
                    label={"Login"}
                  />
                </div>
                {/* <SocialAuth /> */}
              </div>
            </div>
          </div>

        </main>
      </div>
    </>
  );
}
