import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CollapsNavBtn } from "../Common/CollapseNavBtn";
import React from "react";
import { Avatar, Tooltip } from "antd";

import { AuthContextType } from "../Auth/AuthContext";
import useAuth from "../Auth/UseAuthHook.tsx";
import { isMobile } from "react-device-detect";
import { Icon } from "@iconify/react";
import useTourContext from "../../contexts/TourContext/TourContextHook.tsx";



export const SideNav = (props: { navItems: Array<any> }) => {
  const [isCollapsed] = useState(false);
  const navigate = useNavigate();
  const authContext = useAuth();

  const tourContext = useTourContext();
  const location = useLocation()

  const stripped = location.pathname.replace('/', '');
  const currentNav = props.navItems.find((nv) => nv.to === stripped)


  const onNavClicked = (nav) => {
    nav.onClick ? nav.onClick() : navigate(nav.to)
  }
  return (
    <section
      className={`lg:relative flex justify-center pl-6 relative  z-40 lg:z-0 hidden lg:block duration-300 transition-all bg-white border-r border-r-gray-100  h-full   ${isCollapsed ? "w-0 lg:w-[49px]" : "w-full lg:w-[280px]"
        }`}
    >
      <div className="flex flex-col ">
        <div className="px-1 flex w-40 items-center gap-x-2 mt-3 py-1 mb-8 rounded-2xl relative">

          <img src="/logo.svg" className="text-xl" alt="" />
        </div>
        {props.navItems.map((nav) => {
          const isCurrent = currentNav?.title.includes(nav.title);
          return (
            <Tooltip
              open={isCollapsed ? undefined : false}
              title={isCollapsed ? nav.title : ""}
            >
              <div
                ref={tourContext.refs[nav.title] ?? undefined}
                onClick={() => onNavClicked(nav)}
                className={` ${isCollapsed ? 'hidden lg:flex' : ''} ${nav.title === 'Logout' ? 'absolute bottom-10' : ''} justify-between overflow-hidden cursor-pointer px-2 py-2 my-1 flex gap-x-3 items-center  rounded  mx-2 transition-all duration-400 `}
              >
                <div className="flex items-center gap-x-4">
                  <Icon className={`text-lg ${isCurrent ? 'text-primary' : 'text-gray-700'}`} icon={nav.icon} />
                  <span className={`${isCurrent ? 'text-primary' : 'text-gray-700'} text-sm  mt-0`}> {nav.title} </span>
                </div>

                <Icon className={`text-lg text-gray-500 ${isCurrent ? 'inline' : 'hidden'} transition-all duration-150`} icon={'material-symbols-light:check'} />

              </div>
            </Tooltip>
          );
        })}
      </div>
    </section >
  );
}; 
