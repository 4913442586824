import { mentionItemToKeyword } from "./schema_utils";

export const Str = {
  postScheduleStatus: {
    draft: 'draft',
    scheduled: 'scheduled'
  },
  niches: ['general', 'technology', 'pets', 'parenting', 'Health & Fitness', 'Decor', 'Personal coaching', 'Beauty', 'Software', 'Fashion', 'Travel', 'Startup', 'Automobile', 'Food', 'Others'],
  apiBasePath: 'http://127.0.0.1:8000',
  paths: {
    home: "/",
    posts: 'posts',
    campaigns: 'campaigns',
    overview: 'overview',
    persona: 'personas',
    channels: "channels",


    store: "storefronts",
    storeSettings: 'storefronts/settings',
    communication: "communications",
    settings: "usersettings",
    finance: "finances",
    analytics: "analytics",
    team: "team",
  },
  loginPath: "/login",
  paystackLinks: {
    Hobby: "https://paystack.com/pay/3auqdxuyay",
    Business: "https://paystack.com/pay/x74pp2u0lh",
    Startup: "https://paystack.com/pay/3644wqq6f3",
  },
  spacesBaseUrl: 'https://writestack-assets.fra1.digitaloceanspaces.com',
};
