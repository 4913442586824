import { Icon } from "@iconify/react"
import { Dropdown } from "antd"
import { MButton } from "./buttons"
import React from "react"

export const PageHeader = ({ ctaRef, ctaTitle, menuProps, selectedLayoutView, handleCTA, extra = <div></div> }) => {
    return <div className="h-16 z-40 bg-white  border-b absolute  border-0 left-0  top-0 right-0  w-full  border-b-gray-100 items-center flex justify-between px-14 ">
        <div className="w-[150px]">
            <Dropdown menu={menuProps}>
                <div className="flex gap-x-2 items-center cursor-pointer hover:bg-gray-200 p-2 rounded">
                    {selectedLayoutView?.icon}
                    <span> {selectedLayoutView?.label}</span>
                </div>
            </Dropdown>
        </div>

        <div className="flex gap-x-20 items-center">
            {extra}
            <MButton
                ref={ctaRef}
                iconColor={'text-white'}
                onClick={handleCTA}
                icon={'icons8:plus'}
                label={ctaTitle}
                loading={undefined}
                className={undefined}
                disabled={undefined}
            />
        </div>
    </div>
}