import { Icon } from "@iconify/react"
import dayjs from "dayjs"
import React from "react"

export const CampaignItem = ({ campaign, onSelect, index }) => {
    return <div className=" rounded-lg p-2 my-2 h-28 lg:w-full md:w-full w-full">
        <div onClick={onSelect} className="w-full flex  gap-x-1 items-center h-full  p-2 bg-white rounded-lg  border mb-4 cursor-pointer hover:bg-gray-50 hover:opacity-75 ">
            <div className="h-full bg-gray-0 rounded-lg w-2/12 flex items-center justify-center">
                <Icon icon={'bxl:twitter'} className="text-2xl" />
            </div>
            <div className=" flex-1 h-full pt-2 flex flex-col gap-y-2 p-2" >
                <div className="flex justify-between items-center">
                    <span className="text-xs" >{campaign.name}</span>
                    <Icon icon={'iconamoon:arrow-right-2-light'} className="text-sm" />
                </div>
                <div className="flex gap-x-2 flex-wrap gap-y-2">
                    <span className="text-xs px-2 rounded-3xl bg-purple-200">{campaign?.status}</span>
                </div>
                <div className="flex justify-between w-full items-center">
                    <div className="flex gap-x-2 items-center">
                        <Icon icon={'carbon:calendar'} className="text-lg" />
                        <span className="text-xs">{dayjs(campaign.schedule).format('YYYY MMM DD')}</span>
                    </div>
                    <div className="flex gap-x-2 items-center">
                        <img src="/avatars.svg" />
                        <span className="text-xs">+1</span>
                    </div>

                </div>

            </div>
        </div>
    </div>
}

