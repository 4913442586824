import { Icon } from "@iconify/react"
import { Form, Input, Spin, message } from "antd"
import React, { useEffect, useState } from "react"
import { onLoadPageInfo } from "../../../services/load_page_data.ts"
import { MButton } from "../Common/buttons.js"

export const PersonaBackground = ({ persona, loading, onAfterSave }) => {
    const { background_description, name } = persona || {}
    const [form] = Form.useForm();


    useEffect(() => {
        // console.log(background_description, name);
        form.setFieldValue('name', name)
        form.setFieldValue('background_description', background_description)
    }, [])

    const onSave = async (e) => {
        onAfterSave(e)
    }

    return <Form form={form} onFinish={onSave} className="flex-col  h-full items-center px-10 py-2">
        <div className="lg:mt-8 lg:mb-6 w-full ">
            <span className="text-gray-600 font-bold text-lg">Persona name</span>
            <Form.Item name={'name'} required={true}>
                <Input
                    required
                    placeholder="Name this persona"
                    className={`rounded-lg  text-gray-600 font-semibold bg-white border-gray-200 border my-3`}
                />
            </Form.Item>
            <span className="text-gray-600 font-bold text-lg">Background description</span>
            <Form.Item name={'background_description'}>
                <Input.TextArea
                    rows={10}
                    placeholder="Describe your background"
                    className={`h-40 rounded-lg  text-gray-600 font-semibold bg-white border-gray-200 border my-3`}
                />
            </Form.Item>

        </div>
        <div className="text-center">
            <MButton
                loading={loading}
                label={'Save description'}
                icon={undefined}
                iconColor={undefined}
                className={undefined}
                disabled={undefined}
                type="submit" onClick={undefined} />
        </div>

    </Form>
}