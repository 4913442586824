import { Icon } from "@iconify/react"
import { Avatar } from "antd"
import React from "react"
import { kFormatter, ngnMoneyFormatter } from "../../../utils"
import { MButton } from "../../Common/buttons"

export const InfluencerHighlighItem = ({ item, isSelected, onAddToCampaign }) => {
    return <div className="flex flex-col  w-full cursor-pointer lg:mb-0  h-[600px]  py-2" >
        <div className="flex items-center">
            Preview
        </div>

        {!item && <div className="h-full ">
            <div className="h-full flex flex-col items-center justify-center">
                <img src="/influencer_preview.svg" />
            </div>
        </div>
        }
        {item && <div className="mx-2 mt-4  p-3 rounded h-40">

            <div className="flex flex-col justify-center items-center gap-x-3 mb-4">
                <Avatar size={'large'} className="border" src={`https://xsgames.co/randomusers/avatar.php?g=pixel`} />
                <span className="text-gray-900 font-semibold">{item.name}</span>
            </div>

            <div className="flex flex-col justify-center items-center">
                <div className="flex gap-x-2 flex-wrap gap-y-2 items-center">
                    <span className="text-sm p-1 px-3 rounded-3xl bg-purple-200">{item.niches[0]}</span>
                    {item.niches.length > 1 && <span className="text-sm">+ {item.niches.length - 1}</span>}
                </div>
            </div>
            <div className="my-3 mx-auto lg:w-8/12">
                <span className="text-xs text-center block text-gray-600">{item.bio} </span>
            </div>
            <div className="flex flex-col   justify-start items-start mt-6 bg-[#A19DF1]  p-3 rounded-lg">
                <div className="flex items-center justify-start gap-x-2 w-full px-2">
                    <Icon className="text-white" icon={'fluent:people-20-filled'} />
                    <span className="text-xs text-white"> {kFormatter(item.followers)} Followers (Total)</span>
                </div>


                <div className="flex flex-col items-center  rounded-lg w-full my-2 gap-y-[0.9px]">
                    <SocialSummary social={item} icon={'logos:twitter'} round="rounded-t-lg" />
                    <SocialSummary social={{ username: 'XX Instagram', followers: 0 }} icon={'skill-icons:instagram'} round="" />
                    <SocialSummary social={{ username: 'XX TikTok', followers: 0 }} icon={'logos:tiktok-icon'} round="rounded-b-lg" />
                </div>
            </div>

            <div className="flex flex-col   justify-start items-start mt-6 gap-y-4">
                <BottomItem icon={'vaadin:money'} value={ngnMoneyFormatter.format(item.price_per_post)} />
                <BottomItem icon={'ph:gift-light'} value={' 2 free re-posts'} />
            </div>

            <div className="flex   justify-center items-center mt-6">
                <MButton onClick={onAddToCampaign}
                    loading={false}
                    inverted={true}
                    label={isSelected ? 'Remove from campaign' : 'Add to campaign'}
                    icon={isSelected ? 'pajamas:remove' : 'uil:0-plus'}
                    iconColor={isSelected ? 'text-red-600' : 'text-primary'}
                    className={undefined}
                    disabled={undefined}
                    ref={undefined}
                />
            </div>
        </div>
        }
    </div>
}


const BottomItem = ({ icon, value }) => {
    return <div className="flex  items-center justify-start gap-x-2 w-full px-2 rounded bg-gray-50 p-2">
        <Icon className="text-gray-700 text-lg" icon={icon} />
        <span className="text-sm text-gray-700"> {value}</span>
    </div>
}


const SocialSummary = ({ social, icon, round }) => {
    return <div className={`flex items-center justify-between gap-x-2 w-full px-2 bg-white bg-opacity-70 p-2  ${round} `}>
        <div className="flex justify-start items-center gap-x-2">
            <Icon className="text-gray-500" icon={icon} />
            <span className="text-xs text-gray-600"> @{social.username}</span>
        </div>
        <div>
            <span className="text-xs text-gray-600"> {kFormatter(social.followers)}</span>
        </div>
    </div>
}